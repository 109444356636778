export const toggleAccordionGroup = (
  prevState: {
    courtGraphics: number[];
    advancedCourtGraphics: number[];
    stats: number[];
    advancedStats: number[];
  },
  accordionKey: keyof typeof prevState,
  index: number
): {
  courtGraphics: number[];
  advancedCourtGraphics: number[];
  stats: number[];
  advancedStats: number[];
} => {
  return {
    ...prevState,
    [accordionKey]: prevState[accordionKey].includes(index)
      ? prevState[accordionKey].filter((i) => i !== index)
      : [...prevState[accordionKey], index]
  };
};
