import React, { CSSProperties, useMemo } from 'react';

import { courtGraphicTypes } from 'match/_shared/matchConstants';
import {
  getCourtGraphic3d2d,
  getBallDotCoordinates3d,
  getBallDotCoordinates2dReturn,
  getBallDotCoordinates2dRally
} from 'match/utils/courtGraphicsUtil';
import { useRecoilValue } from 'recoil';
import { getReduceBallSizeAmount } from 'scout/util/scoutCourtGraphicsUtil';

import colors from '_shared/designSystem/theme/colors';
import { isMatchReportState } from '_shared/globalState/atoms';

import { sortShotsByDisplayType } from './utils';

export const displayTypePriority = {
  lost: 1,
  won: 2,
  winner: 3,
  error: 4
};

const CourtBallDotsSvg = React.memo(
  ({
    shots,
    width,
    height,
    graphicType,
    surface
  }: {
    shots: any[];
    width: number;
    height: number;
    graphicType: string;
    surface: string;
  }) => {
    const isMatchReport = useRecoilValue(isMatchReportState);

    const sortedShots = useMemo(() => sortShotsByDisplayType(shots), [shots]);

    const { ballDiameter, coordinates } = useMemo(() => {
      const ballSizeReductionObject = getReduceBallSizeAmount(sortedShots, graphicType, width);
      let diameter = Math.round(ballSizeReductionObject.diameterRatio * width);
      if (diameter < ballSizeReductionObject.minDiameter) {
        diameter = ballSizeReductionObject.minDiameter;
      }

      const is3d = getCourtGraphic3d2d(graphicType) === '3d';
      const colorMap = {
        won: is3d && !isMatchReport ? colors.warning[300] : colors.warning[200],
        lost: surface === 'clay' ? colors.rose[800] : colors.rose[500],
        winner:
          surface === 'grass'
            ? is3d && !isMatchReport
              ? colors.success[600]
              : colors.success[700]
            : colors.success[500],
        error: colors.white,
        default: colors.white
      };

      const borderColorMap = {
        won: colors.warning[300],
        lost: surface === 'clay' ? colors.rose[900] : colors.rose[600],
        winner: surface === 'grass' ? colors.success[700] : '#0EB868',
        error: colors.black,
        default: ''
      };

      const validCoordinates = sortedShots
        .map((item) => {
          if (item.x === null || item.y === null) return null;

          let coords;
          if (is3d) {
            coords = getBallDotCoordinates3d(item, width, height, diameter);
          } else if (graphicType === courtGraphicTypes.RETURN_CONTACT_POINT) {
            coords = getBallDotCoordinates2dReturn(item, width, height, diameter);
          } else if (graphicType === courtGraphicTypes.RALLY_CONTACT_POINT) {
            coords = getBallDotCoordinates2dRally(item, width, height, diameter);
          }

          if (!coords || coords.left > width || coords.top > height) return null;

          return {
            ...coords,
            displayType: item.display_type,
            color: colorMap[item.display_type] || colorMap.default,
            borderColor:
              (isMatchReport && is3d) || !is3d ? borderColorMap[item.display_type] || borderColorMap.default : undefined
          };
        })
        .filter(Boolean);

      return {
        ballDiameter: diameter,
        coordinates: validCoordinates
      };
    }, [sortedShots, width, height, graphicType, surface, isMatchReport]);

    const svgStyle: CSSProperties = {
      position: 'absolute',
      top: 0,
      left: 0
    };

    return (
      <svg width={width} height={height} style={svgStyle} className="ball-dots-layer">
        {coordinates.map((coord, index) => (
          <circle
            key={index}
            cx={coord.left + ballDiameter / 2}
            cy={coord.top + ballDiameter / 2}
            r={ballDiameter / 2}
            fill={coord.color}
            stroke={coord.borderColor}
            strokeWidth={coord.borderColor ? 0.5 : 0}
          />
        ))}
      </svg>
    );
  }
);

CourtBallDotsSvg.displayName = 'CourtBallDotsSvg';

export default CourtBallDotsSvg;
