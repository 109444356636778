import { apiClient } from '_shared/dataFetching/apiConfig';
import { getSiteParam } from '_shared/dataFetching/urlConfig';

export const graphicsService = {
  async getFormOptions({ graphicType }: { graphicType: string }) {
    const url = `/graphics/form_options?graphic_type=${encodeURIComponent(graphicType)}&${getSiteParam()}`;
    const res = await apiClient.get(url);
    return res.data;
  },

  async generateGraphicData({ queryParams }: { queryParams: { [key: string]: any } }) {
    try {
      const siteParam = getSiteParam();

      const params: { [key: string]: any } = {
        graphic_type: queryParams.graphicType,
        match_id: queryParams.match_id
      };

      const queryString = Object.entries(params)
        .filter(([key, value]) => value !== 'all' && value !== '')
        .map(([key, value]) => {
          const paramValue = typeof value === 'boolean' ? (value ? 'yes' : 'no') : value;
          return `${encodeURIComponent(key)}=${encodeURIComponent(paramValue)}`;
        })
        .join('&');

      const url = `/graphics/data?${siteParam}&${queryString}`;
      const res = await apiClient.get(url);
      return res.data;
    } catch (error) {
      console.error('Error fetching graphic data:', error);
      throw error;
    }
  }
};
