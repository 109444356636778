import { BeatLoader } from 'react-spinners';

import { Flex, Box, Text } from '_shared/designSystem/components';

export const CustomBadge = (props) => {
  const { text, type, loading } = props;

  if (text === ' ') return <Box w="50px" h="20px"></Box>;

  const color = type === 'playerAverage' || type === 'tourAverage' ? 'grey.600' : 'white';

  return (
    <Flex bg={type} px={2} borderRadius="xl" mx={1} minWidth="42px" justify="center">
      {loading ? (
        <Flex h="18px" alignItems="center">
          <BeatLoader size={3} color="white" />
        </Flex>
      ) : (
        <Text fontSize="xs" fontWeight="medium" color={color}>
          {text}
        </Text>
      )}
    </Flex>
  );
};
