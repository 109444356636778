import wimbColors from '_customSites/wimb/theme/wimbColors';

import { getCrossComponentD3Colors } from '_shared/designSystem/theme/helpers';

export const wimbPieChartColors = [
  wimbColors.illustration.dustyPink,
  wimbColors.illustration.lightBlue,
  wimbColors.illustration.strawberries,
  wimbColors.illustration.apple,
  wimbColors.illustration.yellow
];

export const wimbPieChartLegend = [
  {
    label: 'Serve',
    color: 'illustration.dustyPink'
  },
  {
    label: 'Return',
    color: 'illustration.lightBlue'
  },
  {
    label: 'Forehand',
    color: 'illustration.strawberries'
  },
  {
    label: 'Backhand',
    color: 'illustration.apple'
  },
  {
    label: 'Volley',
    color: 'illustration.yellow'
  }
];

export const wimbPerformanceTrackerColors = {
  player1Line: wimbColors.grey[500],
  player2Line: wimbColors.primary[500],
  player1Fill: wimbColors.grey[500],
  player2Fill: wimbColors.primary[500],
  tourAverageLine: wimbColors.tourAverage
};

export const wimbD3ComponentColors = {
  shared: {
    ...getCrossComponentD3Colors(wimbColors)
  },
  radar: {
    score: wimbColors.primary[500],
    lightGrey: wimbColors.grey[200],
    darkGrey: wimbColors.grey[400],
    tourAvgOpacity: 0.6,
    playerAvgOpacity: 0.7,
    scoreOpacity: 0.6
  },
  performanceTracker: {
    tooltipBackground: wimbColors.grey[500],
    gridLines: wimbColors.grey[200],
    yAxisLabels: wimbColors.grey[400],
    tournamentName: wimbColors.grey[700],
    lightGrey: wimbColors.grey[700],
    graphLine: wimbColors.primary[500],
    trendLine: wimbColors.primary[500],
    grass: wimbColors.court.surface.grass.hex,
    clay: wimbColors.court.surface.clay.hex,
    hard: wimbColors.blue[700],
    indoor: wimbColors.blue[400]
  },
  situationProfile: {
    score: wimbColors.white,
    bars: [wimbColors.secondary[800], wimbColors.secondary[800], wimbColors.secondary[800]]
  },
  rallyLength: {
    score: wimbColors.white,
    bars: [wimbColors.primary[500], wimbColors.primary[500], wimbColors.primary[500]],
    grey: wimbColors.grey[200],
    black: wimbColors.grey[800]
  }
};

export const wimbStackedBarColors = {
  serveEffectiveness: [
    { bg: 'wimbGreen.700', text: 'secondary.800' },
    { bg: 'wimbGreen.500', text: 'secondary.800' },
    { bg: 'wimbGreen.300', text: 'secondary.800' },
    { bg: 'grey.200', text: 'secondary.800' },
    { bg: 'rose.50', text: 'secondary.800' },
    { bg: 'pink.100', text: 'secondary.800' },
    { bg: 'error.200', text: 'secondary.800' }
  ],
  shotVariation: [
    { bg: 'grey.200', text: 'secondary.800' },
    { bg: 'wimbGreen.100', text: 'secondary.800' },
    { bg: 'wimbGreen.200', text: 'secondary.800' },
    { bg: 'wimbGreen.400', text: 'secondary.800' },
    { bg: 'wimbGreen.500', text: 'secondary.800' },
    { bg: 'wimbGreen.700', text: 'secondary.800' },
    { bg: 'wimbGreen.900', text: 'secondary.800' }
  ]
};
