export const checkPermission = (permissionName, permissionList) => {
  if (import.meta.env.NODE_ENV === 'test' || import.meta.env.VITE_ENV === 'dev' || import.meta.env.STORYBOOK === 'on')
    return true;
  if (!permissionName || !permissionList) {
    return false;
  }

  if (permissionList.includes('*')) {
    return true;
  }

  return permissionList.includes(permissionName);
};
