import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '_shared/designSystem/components';

interface AccordionSingleProps {
  data: { title: string; content: string }[];
  openIndices: number[];
  onChange: (index: number) => void;
}

export const AccordionSingle = ({ data, onChange }: AccordionSingleProps) => (
  <Accordion allowMultiple>
    {data?.map((item, index) => (
      <AccordionItem key={index}>
        <h2>
          <AccordionButton bgColor="white" borderRadius={8} my={2} p={2} onClick={() => onChange(index)}>
            <Box flex="1" textAlign="left" color="secondary.500" fontWeight="bold">
              {item.title}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>{item.content}</AccordionPanel>
      </AccordionItem>
    ))}
  </Accordion>
);
