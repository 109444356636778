import React from 'react';

import { Box, Text } from '@chakra-ui/react';

interface RankingOrRaceWithPointsProps {
  rankingOrRace: number;
  label: string;
  points: number;
}

const RankingOrRaceWithPoints = ({ rankingOrRace, label, points }: RankingOrRaceWithPointsProps) => {
  return (
    <Box border="1px solid" borderColor="secondary.800" borderRadius="md" display="flex" flexDirection="row" bg="white">
      <Box w="70px">
        <Text fontSize="xl" color="grey.800" fontWeight="semibold" w="100%" textAlign="center">
          {rankingOrRace}
        </Text>
        <Box bg="secondary.800" textAlign="center" borderBottomLeftRadius="sm">
          <Text fontSize="xs" color="white">
            {label}
          </Text>
        </Box>
      </Box>
      <Box borderLeft="1px solid" borderColor="secondary.800" />
      <Box w="70px">
        <Box display="flex" justifyItems="center" alignItems="center" h="30px">
          <Text w="100%" textAlign="center" fontSize="sm" color="grey.800" fontWeight="semibold">
            {points}
          </Text>
        </Box>
        <Box bg="secondary.800" textAlign="center" borderBottomRightRadius="sm">
          <Text fontSize="xs" color="white">
            Points
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default RankingOrRaceWithPoints;
