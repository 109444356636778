export const getApiUrl = () => {
  const localApiUrl = 'http://localhost:3000/';
  const stagingApiRedirect = '/auth/redirect/staging-api.tennisviz.com/portal/';

  // we are running tests, so don't set the api url, otherwise MSW won't work
  if (!!import.meta.env.DEV === 'test') return undefined;

  // we are running storybook, so return the storybook url
  if (import.meta.env.STORYBOOK === 'on') return 'http://localhost:6006/';

  // we are running locally, using MSW
  if (!!import.meta.env.DEV && import.meta.env.VITE_MSW === 'on') return localApiUrl;

  // we are running locally, using the staging API
  if (!!import.meta.env.DEV && import.meta.env.VITE_STAGING === 'on') return stagingApiRedirect;

  // we are running remotely. get the url from env vars
  if (!!import.meta.env.PROD) {
    return import.meta.env.VITE_API_URL;
  }

  // return local by default
  return localApiUrl;
};

export const getAuthApiUrl = () => {
  const localApiUrl = 'http://localhost:3000/auth/';

  // we are running tests, so don't set the api url, otherwise MSW won't work
  if (!!import.meta.env.DEV === 'test') return undefined;

  // we are running locally, using MSW
  if (!!import.meta.env.DEV && import.meta.env.VITE_MSW === 'on') return localApiUrl;

  // we are running locally, using the staging API
  if (!!import.meta.env.DEV && import.meta.env.VITE_STAGING === 'on') return '/auth/auth/';

  // we are running remotely. get the url from env vars
  if (!!import.meta.env.PROD) {
    return import.meta.env.VITE_AUTH_API_URL;
  }

  // return local by default
  return localApiUrl;
};

export const getReportBucketUrl = (reportType) => {
  if (!!import.meta.env.DEV === 'test') return undefined;
  if (!!import.meta.env.DEV && import.meta.env.VITE_MSW === 'on') return 'dummy_match_report_bucket_url';
  if ((!!import.meta.env.DEV && import.meta.env.VITE_STAGING === 'on') || !!import.meta.env.PROD) {
    return reportType === 'scout'
      ? import.meta.env.VITE_SCOUT_REPORT_BUCKET_URL
      : import.meta.env.VITE_MATCH_REPORT_BUCKET_URL;
  }
};

export const getReportLambdaUrl = (reportType) => {
  if (!!import.meta.env.DEV === 'test') return undefined;
  if (!!import.meta.env.DEV && import.meta.env.VITE_MSW === 'on') return 'dummy_match_report_lambda_url/';
  if ((!!import.meta.env.DEV && import.meta.env.VITE_STAGING === 'on') || !!import.meta.env.PROD) {
    return reportType === 'scout'
      ? import.meta.env.VITE_SCOUT_REPORT_LAMBDA_URL
      : import.meta.env.VITE_MATCH_REPORT_LAMBDA_URL;
  }
};

export function getSiteParam() {
  return `site=${import.meta.env.VITE_SITE_NAME}`;
}
