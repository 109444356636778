import React, { useEffect, useRef, useState } from 'react';

import * as d3 from 'd3';
import { layoutTextLabel, layoutGreedy, layoutLabel } from 'd3fc-label-layout';
import { BeatLoader } from 'react-spinners';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Box, Flex, Text, useBreakpointValue, VStack, HStack, Switch } from '_shared/designSystem/components';
import { tvD3ComponentColors } from '_shared/designSystem/theme/chartColors';
import { addApostrophe } from '_shared/utils/stringUtil';

import { getDotColor, getMinYRating, processTournamentData } from './helpers';
import { DisplayData, PerformanceTrackerProps, TrendLineData } from './types';

const PerformanceTracker: React.FC<PerformanceTrackerProps> = ({
  tournamentsAndTrendLine,
  performanceRatingNoAverages,
  isLoadingPerformanceRating,
  performanceRatingWithAverages,
  showAverages,
  playerName
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [trendLine, setTrendLine] = useState(false);

  // Define margins at component level
  const CHART_MARGINS = { top: 10, right: 50, bottom: 20, left: 50 };

  const shrinkValue = useBreakpointValue({ base: 0.9, lg: 0.938 });

  const width = useBreakpointValue({
    base: Math.max(svgRef.current?.parentElement?.clientWidth || 700, 700),
    md: Math.max(svgRef.current?.parentElement?.clientWidth || 500, 500) * shrinkValue
  });
  const isMobile = useBreakpointValue({ base: true, md: false });

  const displayData = processTournamentData(tournamentsAndTrendLine);
  const trendLineData = tournamentsAndTrendLine?.trend_line || [];

  const colors = customSitesConfig?.d3ComponentColors || tvD3ComponentColors;
  const { shared, performanceTracker } = colors;

  const {
    performance_rating: { score: performanceRating }
  } = performanceRatingNoAverages || { performance_rating: { score: null } };

  const {
    performance_rating: { tour_average: tourAverage, player_average: playerAverage }
  } = performanceRatingWithAverages || { performance_rating: { tour_average: null, player_average: null } };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!svgRef.current || !tooltipRef.current || !displayData?.length) return;

    d3.select(svgRef.current)?.selectAll('*')?.remove();

    const height = 300 - CHART_MARGINS.top - CHART_MARGINS.bottom;

    const svg = d3
      .select(svgRef.current)
      ?.attr('width', width! + CHART_MARGINS.left + CHART_MARGINS.right)
      .attr('height', height + CHART_MARGINS.top + CHART_MARGINS.bottom)
      .append('g')
      .attr('transform', `translate(${CHART_MARGINS.left},${CHART_MARGINS.top})`);

    const xScale = d3
      .scaleTime()
      .domain(d3.extent(displayData, (d) => new Date(d.date)) as [Date, Date])
      .range([0, width!]);

    const yScale = d3
      .scaleLinear()
      .domain([getMinYRating(displayData), 10])
      .range([height, 0]);

    // horizontal grid lines
    svg
      ?.selectAll('line.horizontal-grid')

      .data(yScale.ticks(5))
      .enter()
      .append('line')
      .attr('class', 'horizontal-grid')
      .attr('x1', 0)
      .attr('x2', width!)
      .attr('y1', (d) => yScale(d))
      .attr('y2', (d) => yScale(d))
      .attr('stroke', performanceTracker.gridLines);

    // y-axis labels
    svg
      ?.append('g')
      .attr('class', 'y-axis')
      .call(d3.axisLeft(yScale).ticks(5).tickSize(0).tickFormat(d3.format('')))
      .selectAll('.tick text')
      .attr('font-size', '14px')
      .attr('fill', performanceTracker.yAxisLabels)
      .attr('transform', `translate(-6, 0)`);

    svg?.select('.y-axis').selectAll('path').attr('stroke', 'transparent');

    // Add y-axis label
    svg
      ?.append('text')
      .attr('class', 'y-axis-label')
      .attr('text-anchor', 'middle')
      .attr('transform', `translate(-40, ${height / 2}) rotate(-90)`)
      .attr('font-size', '14px')
      .attr('fill', performanceTracker.yAxisLabels)
      .text('Performance Rating');

    // tournament tooltips
    const tooltip = d3
      .select(tooltipRef.current)
      ?.style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('background-color', performanceTracker.tooltipBackground)
      .style('color', shared.white)
      .style('padding', '8px')
      .style('border-radius', '4px')
      .style('box-shadow', '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)')
      .style('font-size', '12px')
      .style('z-index', '2')
      .style('pointer-events', 'none');

    const showTooltip = (d: DisplayData, x: number, y: number) => {
      if (trendLine) return;

      const tooltipContent = `
       <div><strong>${d.tournament_name}</strong></div>
        <div>PR ${Number(d.performance_rating).toFixed(2)}</div>
        <div>${d.matches_played} ${d.matches_played === 1 ? 'Match' : 'Matches'}</div>
        <div>${d.round_reached}</div>
      `;

      const tooltipX = x - 25 < 0 ? 0 : x - 25;
      const tooltipY = y - 40;

      tooltip
        .style('visibility', 'visible')
        .html(tooltipContent)
        .style('left', `${tooltipX}px`)
        .style('top', `${tooltipY}px`);
    };

    const hideTooltip = () => {
      tooltip.style('visibility', 'hidden');
    };

    // Create the appropriate line based on trendLine state
    if (!trendLine) {
      // Performance rating line
      const performanceLine = d3
        .line<DisplayData>()
        .x((d) => xScale(new Date(d.date)))
        .y((d) => yScale(Number(d.performance_rating)))
        .curve(d3.curveMonotoneX);

      svg
        ?.append('path')
        .datum(displayData)
        .attr('fill', 'none')
        .attr('stroke', performanceTracker.graphLine)
        .attr('stroke-width', 2)
        .attr('d', performanceLine);
    } else {
      // Trend line
      const trendLinePath = d3
        .line<TrendLineData>()
        .x((d) => xScale(new Date(d.date)))
        .y((d) => yScale(Number(d.performance_rating)))
        .curve(d3.curveMonotoneX);

      svg
        ?.append('path')
        .datum(trendLineData)
        .attr('fill', 'none')
        .attr('stroke', performanceTracker.trendLine)
        .attr('stroke-width', 2)
        .attr('d', trendLinePath);
    }

    // create player average line
    if (playerAverage !== null && showAverages) {
      svg
        ?.append('line')
        .attr('x1', 0)
        .attr('x2', width!)
        .attr('y1', yScale(playerAverage))
        .attr('y2', yScale(playerAverage))
        .attr('stroke', shared.playerAverage)
        .attr('stroke-dasharray', '5,5');
    }

    // create tour average line
    if (tourAverage !== null) {
      svg
        ?.append('line')
        .attr('x1', 0)
        .attr('x2', width!)
        .attr('y1', yScale(tourAverage))
        .attr('y2', yScale(tourAverage))
        .attr('stroke', shared.tourAverage)
        .attr('stroke-dasharray', '5,5');
    }

    /**
     * This code is to avoid the clashes of the labels. We are using a package called d3fc-label-layout,
     * with the greedy strategy which goes in sequence from the start and puts labels in places with least
     * overlap to the ones before i.e. the first ones are greedy. Labels are kept in the bounds of the graph
     * by specific the bounding rectangle arond it.  More info can be found here
     * https://github.com/ColinEberhardt/d3fc-label-layout
     */
    const labels = displayData.map((d) => ({
      x: xScale(new Date(d.date)),
      y: yScale(Number(d.performance_rating)),
      tournament_name: d.tournament_name
    }));

    const labelPadding = 2;
    const labelFontSize = '11px';

    const textLabel = layoutTextLabel()
      .padding(labelPadding)
      .value((d) => d.tournament_name);

    const strategy = layoutGreedy().bounds({
      x: 0,
      y: 0,
      width: width!,
      height: height
    });

    const getTextSize = (i: number, g: any) => {
      const textElement = g[i].getElementsByTagName('text')[0];
      textElement.style.fontSize = labelFontSize;
      return textElement.getBoundingClientRect();
    };

    const labelLayout = layoutLabel(strategy)
      .decorate((selection) => {
        selection.select('rect').attr('fill', 'none');
        selection.select('circle').attr('fill', 'none');
        selection
          .select('text')
          .attr('font-size', labelFontSize)
          .attr('font-weight', 400)
          .attr('fill', performanceTracker.tournamentName);
      })
      .size((d, i: number, g: number) => {
        const textSize = getTextSize(i, g);
        return [textSize.width + labelPadding * 2, textSize.height + labelPadding * 2];
      })
      .position((d) => [d.x, d.y])
      .component(textLabel);

    svg.datum(labels).call(labelLayout);

    // Add dots with hover functionality
    const dots = svg?.append('g').attr('class', 'dots');

    dots
      ?.selectAll('.dot-group')
      .data(displayData)
      .enter()
      .append('g')
      .attr('class', 'dot-group')
      .each(function (d, i) {
        const g = d3.select(this);
        const x = xScale(new Date(d.date));
        const y = yScale(Number(d.performance_rating));

        // Add dot with color based on court type
        const dotColor = getDotColor(d.court_type, performanceTracker);

        g.append('circle')
          .attr('class', 'dot')
          .attr('cx', x)
          .attr('cy', y)
          .attr('r', 4)
          .attr('fill', dotColor)
          .style('cursor', 'pointer')
          .on('mouseover', () => showTooltip(d, x, y))
          .on('mouseout', hideTooltip);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayData, windowWidth, trendLine, trendLineData]); // Added trendLineData to dependencies

  return (
    <Box p={{ base: 1, md: 2 }} pb={{ base: 4, md: 0 }}>
      <Box>
        {isMobile ? (
          // Mobile Layout
          <VStack
            align="flex-start"
            spacing={1}
            width="full"
            pl={{ base: 2, md: 6 }}
            pt={{ base: 2, md: 0 }}
            pb={{ base: 4, md: 0 }}
          >
            <Text fontSize="md" fontWeight="semibold">
              Season Performance Tracker
            </Text>
            <VStack align="flex-start" spacing={1} width="full">
              <HStack spacing={2}>
                <Text fontSize="xs">{addApostrophe(playerName)} Performance Rating</Text>
                {isLoadingPerformanceRating ? (
                  <BeatLoader size={6} color="grey" />
                ) : (
                  <Text fontSize={isMobile ? 'md' : '2xl'} fontWeight="bold" textColor="primary.500">
                    {performanceRating?.toFixed(2)}
                  </Text>
                )}
              </HStack>
              {showAverages && (
                <HStack spacing={1}>
                  <Text fontSize="xs">Player Avg</Text>
                  <Text fontSize="md" fontWeight="bold" textColor={shared.playerAverage}>
                    {playerAverage?.toFixed(2)}
                  </Text>
                </HStack>
              )}
              <HStack spacing={2}>
                <Text fontSize="xs">Tour Avg</Text>
                <Text fontSize="md" fontWeight="bold" textColor={shared.tourAverage}>
                  {tourAverage?.toFixed(2)}
                </Text>
              </HStack>
              <HStack spacing={2}>
                <Text fontSize="xs">Trend Line</Text>
                <Switch isChecked={trendLine} onChange={() => setTrendLine(!trendLine)} colorScheme="green" />
              </HStack>
            </VStack>
          </VStack>
        ) : (
          // Desktop Layout
          <Box width="full" position="relative">
            <Flex justify="space-between" alignItems="center" width="full">
              <Text size="md" fontWeight="semibold">
                Season Performance Tracker
              </Text>
              <Flex justify="flex-end" gap={2} alignItems="center">
                <Text fontSize="xs">{addApostrophe(playerName)} Performance Rating</Text>
                {isLoadingPerformanceRating ? (
                  <BeatLoader size={6} color="grey" />
                ) : (
                  <Text fontSize="2xl" fontWeight="bold" textColor="primary.500">
                    {performanceRating?.toFixed(2)}
                  </Text>
                )}
                {showAverages && (
                  <>
                    <Text fontSize="xs">Player Avg</Text>
                    <Text fontSize="md" fontWeight="bold" textColor={shared.playerAverage}>
                      {playerAverage?.toFixed(2)}
                    </Text>
                  </>
                )}
                <>
                  <Text fontSize="xs" ml={2}>
                    Tour Avg
                  </Text>
                  <Text fontSize="md" fontWeight="bold" textColor="tourAverage">
                    {tourAverage?.toFixed(2)}
                  </Text>
                </>
              </Flex>
            </Flex>
            <Flex justify="flex-end" gap={2}>
              <Text fontSize="xs">Trend Line</Text>
              <Switch isChecked={trendLine} onChange={() => setTrendLine(!trendLine)} colorScheme="green" />
            </Flex>
          </Box>
        )}
      </Box>
      <Box pl={{ base: 1, sm: 2, lg: 0 }} width="full" overflowX={{ base: 'auto', md: 'hidden' }} overflowY="hidden">
        <Box position="relative" minWidth="320px">
          <svg ref={svgRef} />
          <Box ref={tooltipRef} position="absolute" pointerEvents="none" />
        </Box>
      </Box>
    </Box>
  );
};

export default PerformanceTracker;
