import React, { useRef, useEffect, useState, ReactNode } from 'react';

import debounce from 'lodash.debounce';

import { Box } from '_shared/designSystem/components';

interface ResponsiveContainerProps {
  children: ReactNode;
  originalWidth: number;
  originalHeight: number;
  maxWidth?: number;
  flex?: string | number;
  [key: string]: any;
}

const ResponsiveContainer: React.FC<ResponsiveContainerProps> = ({
  children,
  originalWidth,
  originalHeight,
  maxWidth,
  flex
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState<number>(1);

  useEffect(() => {
    // Debounced resize handler for performance optimization
    const handleResize = debounce(() => {
      if (wrapperRef.current) {
        const containerWidth = wrapperRef.current.offsetWidth;
        const newScale = containerWidth / originalWidth;
        setScale(newScale);
      }
    }, 100);

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      handleResize.cancel();
    };
  }, [originalWidth]);

  return (
    <Box
      ref={wrapperRef}
      position="relative"
      width="100%"
      maxWidth={maxWidth ? `${maxWidth}px` : `${originalWidth}px`}
      paddingBottom={`${(originalHeight / originalWidth) * 100}%`}
      flex={flex}
      data-testid="responsive-container"
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        width={`${originalWidth}px`}
        height={`${originalHeight}px`}
        transform={`scale(${scale})`}
        transformOrigin="top left"
        data-testid="responsive-content"
      >
        {children}
      </Box>
    </Box>
  );
};

export default ResponsiveContainer;
