import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex
} from '_shared/designSystem/components';

import { SubHeading } from './SubHeading';

export const AccordionGroup = ({ title, children, onToggle, accordionGroup }) => (
  <Box bg="white" p={4} borderRadius="lg" mb={4}>
    <Accordion defaultIndex={[0]} allowToggle>
      <AccordionItem border="none">
        <Flex align="center" justify="space-between">
          <Box flex="1" textAlign="center">
            <SubHeading>{title}</SubHeading>
          </Box>
          <AccordionButton
            onClick={() => onToggle(accordionGroup)}
            pb={4}
            w="auto"
            bg="transparent"
            _hover={{ bg: 'grey.50' }}
          >
            <AccordionIcon />
          </AccordionButton>
        </Flex>
        <AccordionPanel pt={0} px={0}>
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </Box>
);
