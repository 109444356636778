import { useState } from 'react';

import { Tooltip } from '@chakra-ui/react';
import { displayScore } from 'match/utils/transformations';

import { Box } from '_shared/designSystem/components';

import { HORIZONTAL_POSITION_VALUES, VERTICAL_POSITION_VALUES } from './constants';

interface TShapeIndicatorProps {
  score: number;
  average: number;
  color: string;
  textColor: string;
  type: 'tourAverage' | 'playerAverage';
  showAverages?: boolean;
}

const TShapeIndicator: React.FC<TShapeIndicatorProps> = ({
  score,
  average,
  color,
  textColor,
  type,
  showAverages = true
}) => {
  const [isLabelOpenVertical, setIsLabelOpenVertical] = useState(false);
  const [isLabelOpenHorizontal, setIsLabelOpenHorizontal] = useState(false);
  // Constants
  const LINE_WIDTH = 4;
  const VERTICAL_HEIGHT = '40%';

  // Calculate vertical line position
  const verticalTop =
    type === 'tourAverage'
      ? showAverages
        ? VERTICAL_POSITION_VALUES.tourAverageWithAverages
        : VERTICAL_POSITION_VALUES.tourAverageWithoutAverages
      : VERTICAL_POSITION_VALUES.playerAverage;

  // Calculate horizontal line position
  const horizontalTop =
    type === 'tourAverage'
      ? showAverages
        ? HORIZONTAL_POSITION_VALUES.tourAverageWithAverages
        : HORIZONTAL_POSITION_VALUES.tourAverageWithoutAverages
      : HORIZONTAL_POSITION_VALUES.playerAverage;

  return (
    <Box position="absolute" top="0" left="0" width="100%" height="100%" pointerEvents="none">
      {/* Group wrapper for the T-shape with pointer events enabled */}
      <Box position="absolute" top="0" left="0" width="100%" height="100%" pointerEvents="none">
        {/* Vertical line at the average position with tooltip */}
        <Tooltip
          label={`${displayScore(average, 'percentage')}`}
          bg={color}
          color={textColor}
          placement="top"
          isOpen={isLabelOpenVertical}
        >
          <Box
            position="absolute"
            left={`${average}%`}
            top={verticalTop}
            width={`${LINE_WIDTH}px`}
            height={VERTICAL_HEIGHT}
            bg={color}
            transform="translateX(-50%)"
            pointerEvents="auto"
            cursor="pointer"
            onMouseEnter={() => setIsLabelOpenVertical(true)}
            onMouseLeave={() => setIsLabelOpenVertical(false)}
            onClick={() => setIsLabelOpenVertical(!isLabelOpenVertical)}
          />
        </Tooltip>

        {/* Horizontal connector line with tooltip */}
        <Tooltip
          label={`${displayScore(average, 'percentage')}`}
          bg={color}
          color={textColor}
          placement="top"
          isOpen={isLabelOpenHorizontal}
        >
          <Box
            position="absolute"
            top={horizontalTop}
            left={`${Math.min(score, average)}%`}
            width={`${Math.abs(score - average)}%`}
            height={`${LINE_WIDTH}px`}
            bg={color}
            transform="translateY(-50%)"
            pointerEvents="auto"
            cursor="pointer"
            onMouseEnter={() => setIsLabelOpenHorizontal(true)}
            onMouseLeave={() => setIsLabelOpenHorizontal(false)}
            onClick={() => setIsLabelOpenHorizontal(!isLabelOpenHorizontal)}
          />
        </Tooltip>
      </Box>
    </Box>
  );
};

export default TShapeIndicator;
