import GraphicFullScreen from 'graphics/components/GraphicFullScreen';
import Graphics from 'graphics/components/Graphics';

const routes = {
  path: 'graphics',
  element: <Graphics />
};

export const graphicsSingleRoutes = {
  path: 'graphics-single',
  element: <GraphicFullScreen />
};

export default routes;
