import { Box, Skeleton, HStack, VStack, Flex } from '_shared/designSystem/components';

const PointsProfileLoadingSkeleton = () => {
  const rows = Array(11).fill(null);

  return (
    <Box p={4} maxW="800px" w="100%">
      {/* Header and Averages key */}
      <Flex justify="space-between" mb={6} align="center">
        <Skeleton height="24px" w="30%" maxW="150px" />
        <Skeleton height="16px" w="15%" maxW="80px" />
      </Flex>

      {/* Data Rows */}
      <VStack spacing={6} align="stretch">
        {rows.map((_, index) => (
          <Flex
            key={index}
            justify="space-between"
            align="center"
            bg={index % 5 === 0 ? 'gray.50' : 'transparent'}
            p={2}
          >
            {/* Label */}
            <Skeleton height="16px" w="40%" maxW="180px" />

            {/* Data point and percentage */}
            <HStack spacing={4} justify="flex-end">
              <Skeleton height="16px" w="15%" maxW="40px" />
              <Skeleton height="16px" w="20%" maxW="60px" />
            </HStack>
          </Flex>
        ))}
      </VStack>
    </Box>
  );
};

export default PointsProfileLoadingSkeleton;
