import { keyframes } from '@chakra-ui/react';

import { Box, Flex } from '_shared/designSystem/components';

export const PerformanceTrackerCustomLoadingGraphic = () => {
  // Define the pulse keyframe animation
  const pulse = keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  `;

  // Animation for loading placeholders
  const pulseAnimation = `${pulse} 2s infinite`;

  // Animation for graph lines
  const graphPulse = keyframes`
    0% {
      opacity: 0.7;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 0.7;
    }
  `;

  const graphPulseAnimation = `${graphPulse} 2s infinite`;

  return (
    <Box w="full" h="400px">
      {/* Header Section */}
      <Flex justify="space-between" align="center" px={4} mb={4}>
        <Box>
          <Box h="24px" w="200px" bg="gray.200" borderRadius="md" animation={pulseAnimation} />
        </Box>
        <Flex gap={4}>
          <Flex gap={2} align="center">
            <Box h="24px" w="80px" bg="gray.200" borderRadius="md" animation={pulseAnimation} />
            <Box h="32px" w="48px" bg="gray.200" borderRadius="full" animation={pulseAnimation} />
          </Flex>
        </Flex>
      </Flex>

      {/* Graph Section */}
      <Box w="full" h="400px" bg="white">
        <Box as="svg" w="full" h="full" viewBox="0 0 800 300" preserveAspectRatio="none">
          {/* Background horizontal grid lines */}
          {[...Array(8)].map((_, i) => (
            <line
              key={`grid-${i}`}
              x1="0"
              x2="100%"
              y1={37.5 * (i + 1)}
              y2={37.5 * (i + 1)}
              stroke="#f0f0f0"
              strokeWidth="1"
            />
          ))}

          {/* Loading line segments with different animation delays */}
          {[
            'M 0,200 Q 100,180 200,150',
            'M 200,150 Q 300,120 400,160',
            'M 400,160 Q 500,200 600,100',
            'M 600,100 Q 700,50 800,150'
          ].map((d, i) => (
            <path
              key={`segment-${i}`}
              d={d}
              fill="none"
              stroke="#e2e8f0"
              strokeWidth="2"
              style={{
                animation: graphPulseAnimation,
                animationDelay: `${i * 100}ms`
              }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
