import isEmpty from 'lodash/isEmpty';

import customSitesConfig from '_shared/config/customSitesConfig';
import { formFieldTypes } from '_shared/designSystem/components/form/formTypes';

function getOptionsAsObjectArray(options, labelName, idName) {
  const result = [];
  options?.forEach((item) =>
    result.push({
      label: labelName ? item[`${labelName}`] : item,
      id: idName ? item[`${idName}`] : item
    })
  );
  return result;
}

// Constants for static options
const SURFACE_OPTIONS = [
  { label: 'Indoor Hard', id: 'indoor_hard' },
  { label: 'Outdoor Hard', id: 'outdoor_hard' },
  { label: 'Clay', id: 'clay' },
  { label: 'Grass', id: 'grass' }
];

const RESULT_OPTIONS = [
  { label: 'Won', id: 'won' },
  { label: 'Lost', id: 'lost' }
];

const OPPONENT_RANK_OPTIONS = [
  { label: 'Top 5', id: '5' },
  { label: 'Top 10', id: '10' },
  { label: 'Top 20', id: '20' },
  { label: 'Top 50', id: '50' }
];

const OPPONENT_HAND_OPTIONS = [
  { label: 'Right', id: 'right' },
  { label: 'Left', id: 'left' }
];

export const createFormField = (type, label, fieldId, options = null, onChange = null, component = null) => {
  const field = {
    type,
    label,
    fieldId,
    ...(options && { options }),
    ...(component && { component })
  };
  if (onChange) {
    field.onChange = onChange;
  }
  return field;
};

export function getScoutMatchSelectorForm(tournaments, opponents, rounds) {
  const tournamentOptions = getOptionsAsObjectArray(tournaments, 'tournament_name', 'tournament_id');
  const opponentOptions = getOptionsAsObjectArray(opponents, 'opponent_name', 'player_id');
  const roundOptions = getOptionsAsObjectArray(rounds, 'round_id', 'round_name');

  // Base fields that are always included
  const baseFields = [createFormField(formFieldTypes.TEXT, 'Last No. Matches', 'lastNoMatches')];

  // Additional fields based on configuration
  const surfaceAndWeeksFields = customSitesConfig.showScoutSurfaceAndLast52WeeksFilter
    ? [createFormField(formFieldTypes.SELECT_MULTI, 'Surfaces', 'surfaces', SURFACE_OPTIONS)]
    : [];

  // Main selection fields
  const selectionFields = [
    createFormField(formFieldTypes.SELECT_MULTI, 'Tournaments', 'tournamentIds', tournamentOptions),
    createFormField(formFieldTypes.SELECT_MULTI, 'Opponents', 'opponentPlayerIds', opponentOptions),
    createFormField(formFieldTypes.SELECT_MULTI, 'Result', 'result', RESULT_OPTIONS),
    createFormField(formFieldTypes.SELECT_MULTI, 'Rounds', 'rounds', roundOptions),
    createFormField(formFieldTypes.SELECT_MULTI, 'Opponent Rank', 'opponentRank', OPPONENT_RANK_OPTIONS),
    createFormField(formFieldTypes.SELECT_MULTI, 'Opponent Hand', 'opponentHand', OPPONENT_HAND_OPTIONS)
  ];

  // Submit button
  const submitField = [createFormField(formFieldTypes.SUBMIT_BUTTON, 'Apply', 'submit')];

  // Combine all fields in the correct order
  return [...baseFields, ...surfaceAndWeeksFields, ...selectionFields, ...submitField];
}

export function getInitialMatchSelectorChoices(scoutFormValues) {
  if (isEmpty(scoutFormValues)) return {};
  return {
    seasons: isEmpty(scoutFormValues.seasons) ? ['last_52_weeks'] : scoutFormValues.seasons,
    surfaces: isEmpty(scoutFormValues.surfaces) ? [] : scoutFormValues.surfaces,
    lastNoMatches: !scoutFormValues.lastNoMatches ? '' : scoutFormValues.lastNoMatches,
    tournamentIds: isEmpty(scoutFormValues.tournamentIds) ? [] : scoutFormValues.tournamentIds,
    opponentPlayerIds: isEmpty(scoutFormValues.opponentPlayerIds) ? [] : scoutFormValues.opponentPlayerIds,
    result: isEmpty(scoutFormValues.result) ? [] : scoutFormValues.result,
    rounds: isEmpty(scoutFormValues.rounds) ? [] : scoutFormValues.rounds,
    opponentRank: isEmpty(scoutFormValues.opponentRank) ? [] : scoutFormValues.opponentRank,
    opponentHand: isEmpty(scoutFormValues.opponentHand) ? [] : scoutFormValues.opponentHand,
    matchIds: isEmpty(scoutFormValues.matchIds) ? [] : scoutFormValues.matchIds
  };
}

export function getVisibleMatchIds(matchSelectorMatches, matchIds) {
  if (isEmpty(matchSelectorMatches) && isEmpty(matchIds)) return [];
  let result = [];
  matchSelectorMatches.forEach((item) => {
    if (matchIds.includes(item.match_id.toString())) {
      result.push(item.match_id.toString());
    }
  });
  return result;
}
