const ENV = {
  DEV: 'dev',
  TEST: 'test',
  STAGING: 'staging',
  PROD: 'prod'
};

const SITE = {
  WIMB: 'wimb',
  ATP_TOUR: 'atptour',
  ATP_MEDIA: 'atpmedia',
  TV: 'tv'
};

export const getCurrentEnvironment = () => {
  if (!!import.meta.env.DEV) return ENV.DEV;
  if (import.meta.env.NODE_ENV === 'test') return ENV.TEST;
  if (!!import.meta.env.PROD) {
    if (import.meta.env.VITE_ENV === 'staging') return ENV.STAGING;
    if (import.meta.env.VITE_ENV === 'prod') return ENV.PROD;
    return ENV.STAGING;
  }
  return null;
};

export const isDevOrTest = () => [ENV.DEV, ENV.TEST].includes(getCurrentEnvironment());

export const isTvPortal = () => import.meta.env.VITE_SITE_NAME === SITE.TV;

export const isWimbPortal = () => import.meta.env.VITE_SITE_NAME === SITE.WIMB;

export const isAtpTourPortal = () => import.meta.env.VITE_SITE_NAME === SITE.ATP_TOUR;

export const isAtpMediaPortal = () => import.meta.env.VITE_SITE_NAME === SITE.ATP_MEDIA;
