import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-location';
import PropTypes from 'prop-types';

import { Icon, Tooltip } from '_shared/designSystem/components';
import { navigateTo } from '_shared/utils/navigationUtils';

const RecentForm = ({ recentForm }) => {
  const navigate = useNavigate();
  const reversedRecentForm = recentForm.slice().reverse();

  const getResultIcon = (result: string) => {
    switch (result) {
      case 'win':
        return (
          <Text color="white" fontWeight="bold">
            W
          </Text>
        );
      case 'loss':
        return (
          <Text color="white" fontWeight="bold">
            L
          </Text>
        );
      case 'tournament_won':
        return <Icon name="trophy" color="black" w={4} h={4} fallback={undefined} />;
      default:
        return null;
    }
  };

  const FormIcon = ({ index, match }) => (
    <Box
      w="20px"
      h="20px"
      key={index}
      data-testid={`form-icon-${index}`}
      bg={
        match.match_result === 'loss'
          ? 'secondary.800'
          : match.match_result === 'tournament_won'
          ? '#FFD700'
          : 'primary.500'
      }
      display="flex"
      alignItems="center"
      justifyContent="center"
      m="2px"
      borderRadius="md"
      cursor={match.is_tracked ? 'pointer' : 'not-allowed'}
      onClick={() => {
        if (match.is_tracked) {
          navigateTo({
            url: `/match/${match.match_id}/performance`,
            navigate
          });
        }
      }}
    >
      {getResultIcon(match.match_result)}
    </Box>
  );

  return (
    <Flex gap={2}>
      <Text fontWeight="semibold">Form</Text>
      <Flex alignItems="center" justifyContent="center">
        {reversedRecentForm.map(
          (
            match: {
              match_result: string;
              match_id: number;
              opponent: string;
              tournament: string;
              is_tracked: boolean;
            },
            index: React.Key
          ) => (
            <>
              <Tooltip
                tooltipText={`v ${match.opponent} - ${match.tournament}`}
                elementToHover={<FormIcon index={index} match={match} />}
                placement="bottom"
                bg={match.match_result === 'loss' ? 'secondary.800' : 'primary.500'}
              />
              {index === recentForm.length - 2 && <Box w="2px" h="20px" bg="gray.400" mx="5px" borderRadius="full" />}
            </>
          )
        )}
      </Flex>
    </Flex>
  );
};

RecentForm.propTypes = {
  recentForm: PropTypes.arrayOf(
    PropTypes.shape({
      match_id: PropTypes.number.isRequired,
      match_result: PropTypes.oneOf(['win', 'loss', 'tournament_won']).isRequired
    })
  ).isRequired
};

export default RecentForm;
