import { lazy, Suspense } from 'react';

import PropTypes from 'prop-types';

import { Box } from '_shared/designSystem/components';

const IconComponent = lazy(() => import('./_Icon'));

export default function Icon({ fallback, ...props }) {
  // temporary measure until we upgrade to Storybook 8
  return import.meta.env.STORYBOOK === 'on' ? (
    <Box height={props.height} width={props.width} bg="blue.400" />
  ) : (
    <Suspense fallback={fallback || null}>
      <IconComponent {...props} />
    </Suspense>
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  fallback: PropTypes.node,
  collection: PropTypes.oneOf(['standard'])
};
