import React from 'react';

import { Box, Text, Flex } from '_shared/designSystem/components';

interface WinLossTotalProps {
  totalMatches: number;
  wins: number;
  losses: number;
}

const WinLossTotal = ({ totalMatches, wins, losses }: WinLossTotalProps) => {
  return (
    <Box border="1px solid" borderColor="primary.500" borderRadius="md">
      <Flex justifyContent="center" alignItems="center" gap={2} px={2} bg="white" borderTopRadius="md">
        <Text py="7px" fontSize="2xl" color="primary.500" fontWeight="semibold" lineHeight="16px">
          {totalMatches}
        </Text>
        <Text fontSize="xs" color="primary.500">
          ({wins}-{losses} Win-Loss)
        </Text>
      </Flex>
      <Box bg="primary.500" borderBottomRadius="sm">
        <Text fontSize="xs" color="white" w="100%" textAlign="center">
          Selected Matches
        </Text>
      </Box>
    </Box>
  );
};

export default WinLossTotal;
