import { getAuthApiUrl } from '_shared/dataFetching/urlConfig';

import { getMyWAuthTokens, setMyWAuthTokens } from '../services/wimbLocalStorage';

var refreshInProgress = false;

// this is the refresh process used only for myWimbledon SSO. It's similar to our normal
// refresh, but using the myW refresh token instead
export async function refreshAccessTokenUsingMyWRefreshToken() {
  if (refreshInProgress) return 'refreshInProgress';
  refreshInProgress = true;
  const tokenData = getMyWAuthTokens();
  const formData = new URLSearchParams();
  formData.append('grant_type', 'refresh');
  formData.append('refresh_token', tokenData.wRefreshToken);
  formData.append('env', import.meta.env.VITE_WIMB_ENV);
  const url = `${getAuthApiUrl()}wimb/token`;
  const res = await fetch(url, {
    method: 'POST',
    body: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
  if (res.status === 200) {
    const data = await res.json();
    setMyWAuthTokens(data?.e_access_token, data?.w_refresh_token);
  }
  refreshInProgress = false;
  return res;
}
