import { useEffect } from 'react';

import { Outlet, useNavigate } from '@tanstack/react-location';
import AblyConnection from 'authentication/components/AblyConnection';
import { authenticationService } from 'authentication/services/authenticationService';
import { TERMS_AND_CONDITIONS_IN_PROGRESS } from 'authentication/util/authConstants';
import { useGetLeaderboardFormOptions } from 'leaderboard/services/leaderboardService';
import { useGetPlayers } from 'players/services/playersService';
import { useQuery } from 'react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import MobileHeader from '_shared/components/mainMenu/MobileHeader';
import TopMenu from '_shared/components/mainMenu/TopMenu';
import customSitesConfig from '_shared/config/customSitesConfig';
import { Box, useBreakpointValue, LoadingSpinner } from '_shared/designSystem/components';
import ErrorBoundary from '_shared/errors/ErrorBoundary';
import MainPageError from '_shared/errors/MainPageError';
import {
  isMatchReportState,
  showTopMenuState,
  userPermissionsState,
  isGraphicsSingleState
} from '_shared/globalState/atoms';
import { sendGoogleAnalyticsUserId } from '_shared/utils/analyticsUtil';
import { useDoesCurrentRootPathContain } from '_shared/utils/hooks/useCurrentPath';

import { mainAreaMarginLeft, maxSiteWidth, topMenuHeight } from './margins';

const MainLayout = () => {
  const setUserPermissionsState = useSetRecoilState(userPermissionsState);
  const isMatchReport = useRecoilValue(isMatchReportState);
  const isGraphicsSingle = useRecoilValue(isGraphicsSingleState);

  const showTopMenu = useRecoilValue(showTopMenuState);
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(['authenticationService_getUserPermissions'], () =>
    authenticationService.getUserPermissions()
  );

  useGetPlayers();
  useGetLeaderboardFormOptions();

  const windowSizeIsMd = useBreakpointValue({ md: true });

  const setIsGraphicsSingle = useSetRecoilState(isGraphicsSingleState);
  const isGraphicsSinglePath = useDoesCurrentRootPathContain('graphics-single');

  useEffect(() => {
    setIsGraphicsSingle(isGraphicsSinglePath);
  }, [isGraphicsSinglePath, setIsGraphicsSingle]);

  useEffect(() => {
    if (data) {
      setUserPermissionsState(data);
      sendGoogleAnalyticsUserId(data.obf_user_id);
    }
  }, [data, setUserPermissionsState]);

  useEffect(() => {
    if (customSitesConfig.hasTermsAndConditionsAcceptance) {
      const termsAndConditionsInProgress = localStorage.getItem(TERMS_AND_CONDITIONS_IN_PROGRESS);
      if (termsAndConditionsInProgress === 'yes') {
        navigate({ to: '/terms_and_conditions' });
      }
    }
  }, [navigate]);

  const isMatchCentre = useDoesCurrentRootPathContain('match');

  if (isLoading) return <LoadingSpinner />;

  if (data && (isMatchReport || isGraphicsSingle)) {
    return <Outlet />;
  }

  return (
    <AblyConnection>
      {windowSizeIsMd ? (
        <Box
          transition="margin-top 0.3s ease-in-out"
          position="fixed"
          top={0}
          left={0}
          mt={showTopMenu ? 0 : `-${topMenuHeight}px`}
          zIndex={2}
        >
          <TopMenu />
        </Box>
      ) : (
        <Box position="sticky" top={0} zIndex={3}>
          <MobileHeader />
        </Box>
      )}
      <Box
        px={{ base: 2, md: `${mainAreaMarginLeft}px` }}
        maxW={`${maxSiteWidth}px`}
        margin="auto"
        mt={{ base: 0, md: `${topMenuHeight}px` }}
        pt={isMatchCentre ? 0 : { base: 3, md: 4 }}
        pb={6}
      >
        <ErrorBoundary renderError={() => <MainPageError />}>
          <Outlet />
        </ErrorBoundary>
      </Box>
    </AblyConnection>
  );
};

export default MainLayout;
