export const TOP_KEYS = {
  ALL_POINTS: 'all_points',
  SERVE_AND_RETURN: 'serve_and_return'
} as const;

export const SUB_KEYS = {
  PLAYED: 'played',
  WON: 'won'
} as const;

export const VERTICAL_POSITION_VALUES = {
  tourAverageWithAverages: '5%',
  tourAverageWithoutAverages: '30%',
  playerAverage: '55%'
} as const;

export const HORIZONTAL_POSITION_VALUES = {
  tourAverageWithAverages: '25%',
  tourAverageWithoutAverages: '50%',
  playerAverage: '75%'
} as const;
