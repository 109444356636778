import React from 'react';

import { Flex, Text, Box } from '@chakra-ui/react';

interface RankingOrRaceMobileProps {
  label: string;
  value: number;
  points: number;
}

const RankingOrRaceMobile: React.FC<RankingOrRaceMobileProps> = ({ label, value, points }) => {
  return (
    <Flex
      justify="space-between"
      align="center"
      p={2}
      borderRadius="md"
      border="1px solid black"
      gap={3}
      maxW="220px"
      bg="white"
    >
      <Flex align="center" gap={1}>
        <Text fontSize="xs" color="gray.600" fontWeight="bold">
          {label}
        </Text>
        <Box
          bg="primary.500"
          px={3}
          py={1}
          borderRadius="md"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="xs"
          fontWeight="bold"
          color="white"
        >
          {value}
        </Box>
      </Flex>
      <Flex align="center" gap={1}>
        <Text fontSize="xs" color="gray.600" fontWeight="bold">
          Points
        </Text>
        <Box bg="primary.500" px={1} py={1} borderRadius="md" fontSize="xs" fontWeight="bold" color="white">
          {points?.toLocaleString()}
        </Box>
      </Flex>
    </Flex>
  );
};

export default RankingOrRaceMobile;
