import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';

import { useBreakpointValue } from '_shared/designSystem/components';
import themeColors from '_shared/designSystem/theme/colors';
import { fontSizes } from '_shared/designSystem/theme/typography';
export default function BasicLineGraph(props) {
  const { data } = props;
  const windowSizeIsSm = useBreakpointValue({ sm: true });

  let xaxisColours = [];
  for (let i = 0; i < 20; i++) {
    xaxisColours.push(themeColors.grey['500']);
  }

  const fontSmall = {
    fontSize: fontSizes.xs,
    fontWeight: 'normal',
    fontFamily: 'Inter, Arial, sans-serif'
  };

  const chartOptions = {
    id: 'performance-graph',
    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 10,
      dynamicAnimation: {
        enabled: true,
        speed: 450
      }
    },
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: false,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false
      }
    }
  };

  const gridOptions = {
    borderColor: themeColors.grey['200']
  };

  const legendOptions = {
    show: true,
    position: 'bottom',
    horizontalAlign: 'right',
    floating: true,
    offsetY: 3,
    offsetX: 0
  };

  const xaxisOptions = {
    categories: data?.xaxisLabels,
    labels: {
      show: true,
      style: {
        colors: xaxisColours,
        ...fontSmall
      }
    },
    title: {
      text: 'Score',
      offsetX: windowSizeIsSm ? 0 : -115,
      style: {
        color: themeColors.grey['500']
      }
    }
  };

  const yaxisOptions = {
    min: 4,
    max: 10,
    labels: {
      show: true,
      style: {
        colors: [themeColors.grey['500']],
        ...fontSmall
      }
    },
    title: {
      text: 'Average Quality',
      style: {
        color: themeColors.grey['500'],
        ...fontSmall
      }
    },
    logBase: 5
  };

  const colorsOptions = [themeColors.primary['400'], themeColors.grey['600']];

  const strokeOptions = {
    curve: 'smooth',
    width: 3
  };

  const fillOptions = { opacity: [0.1, 0.9] };

  const dataLabelOptions = {
    enabled: true,
    enabledOnSeries: undefined,
    formatter: function (value, { seriesIndex, dataPointIndex, w }) {
      if (
        data?.graphType === 'serveAndReturn' &&
        seriesIndex === 0 &&
        data?.player1ServiceGamesIndexes?.includes(dataPointIndex)
      )
        return 's';
      if (
        data?.graphType === 'serveAndReturn' &&
        seriesIndex === 1 &&
        data?.player2ServiceGamesIndexes?.includes(dataPointIndex)
      )
        return 's';
      if (
        data?.graphType === 'serveAndReturn' &&
        seriesIndex === 1 &&
        data?.player2ServiceGamesIndexes?.includes(dataPointIndex)
      )
        return 's';
    },
    textAnchor: 'middle',
    distributed: false,
    offsetX: 0,
    offsetY: 0,
    style: {
      ...fontSmall,
      colors: undefined
    },
    background: {
      enabled: true,
      foreColor: '#fff',
      padding: 4,
      borderRadius: 2,
      borderWidth: 1,
      borderColor: '#fff',
      opacity: 0.9
    }
  };

  const annotationsOptions = {
    xaxis: ['Set 1', 'Set 2', 'Set 3', 'Set 4', 'Set 5'].map((item) => {
      return {
        x: item,
        strokeDashArray: 3,
        borderColor: themeColors.grey['300']
      };
    }),
    points: data?.annotationPoints
  };

  const options = {
    chart: chartOptions,
    grid: gridOptions,
    legend: legendOptions,
    xaxis: xaxisOptions,
    yaxis: yaxisOptions,
    colors: colorsOptions,
    stroke: strokeOptions,
    fill: fillOptions,
    dataLabels: dataLabelOptions,
    annotations: annotationsOptions
  };

  const series = data.plotdata;

  return <Chart options={options} series={series} />;
}

BasicLineGraph.propTypes = {
  data: PropTypes.object
};
