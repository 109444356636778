import { useState } from 'react';

import { Box, SimpleGrid, Container, Text, useBreakpointValue } from '_shared/designSystem/components';

import { AccordionGroup } from './AccordionGroup';
import { AccordionSingle } from './AccordionSingle';
import {
  courtGraphics,
  courtGraphicsAdvanced,
  tables,
  tablesAdvanced,
  ServeAccordionGroup,
  ServeAccordionGroupTitle
} from './constants';
import { ServeAccordionGroupType } from './types';
import { toggleAccordionGroup } from './utils';

export const Serve = () => {
  // state management
  const [AccordionGroups, setAccordion] = useState({
    courtGraphics: [],
    advancedCourtGraphics: [],
    stats: [],
    advancedStats: []
  });

  // Responsive column configuration
  const columns = useBreakpointValue({
    base: 1,
    md: 3
  });

  // Accordion change handler
  const handleAccordionSingleChange = (AccordionGroupKey: ServeAccordionGroupType) => (index: number) => {
    setAccordion((prev) => toggleAccordionGroup(prev, AccordionGroupKey, index));
  };

  return (
    <Container maxW="100%" p={0} m={0}>
      <Box mb={6}>
        <Text color="grey.500">Filter controls will be added here</Text>
      </Box>

      <Box bg="grey.100" p={6} borderRadius="lg">
        <SimpleGrid columns={columns} spacing={4}>
          {/* Court Graphics and Advanced Court Graphics - 2 columns wide */}
          <Box gridColumn={{ base: 'span 1', md: 'span 2' }}>
            <AccordionGroup
              title={ServeAccordionGroupTitle.COURT_GRAPHICS}
              onToggle={(index) => toggleAccordionGroup(AccordionGroups, ServeAccordionGroup.COURT_GRAPHICS, index)}
              accordionGroup={ServeAccordionGroup.COURT_GRAPHICS}
            >
              <AccordionSingle
                data={courtGraphics}
                openIndices={AccordionGroups.courtGraphics}
                onChange={handleAccordionSingleChange(ServeAccordionGroup.COURT_GRAPHICS)}
              />
            </AccordionGroup>

            <AccordionGroup
              title={ServeAccordionGroupTitle.ADVANCED_COURT_GRAPHICS}
              onToggle={(index) =>
                toggleAccordionGroup(AccordionGroups, ServeAccordionGroup.ADVANCED_COURT_GRAPHICS, index)
              }
              accordionGroup={ServeAccordionGroup.ADVANCED_COURT_GRAPHICS}
            >
              <AccordionSingle
                data={courtGraphicsAdvanced}
                openIndices={AccordionGroups.advancedCourtGraphics}
                onChange={handleAccordionSingleChange(ServeAccordionGroup.ADVANCED_COURT_GRAPHICS)}
              />
            </AccordionGroup>
          </Box>

          {/* Stats and Advanced Stats - 1 column wide */}
          <Box gridColumn={{ base: 'span 1', md: 'span 1' }}>
            <AccordionGroup
              title={ServeAccordionGroupTitle.STATS}
              onToggle={(index) => toggleAccordionGroup(AccordionGroups, ServeAccordionGroup.STATS, index)}
              accordionGroup={ServeAccordionGroup.STATS}
            >
              <AccordionSingle
                data={tables}
                openIndices={AccordionGroups.stats}
                onChange={handleAccordionSingleChange(ServeAccordionGroup.STATS)}
              />
            </AccordionGroup>

            <AccordionGroup
              title={ServeAccordionGroupTitle.ADVANCED_STATS}
              onToggle={(index) => toggleAccordionGroup(AccordionGroups, ServeAccordionGroup.ADVANCED_STATS, index)}
              accordionGroup={ServeAccordionGroup.ADVANCED_STATS}
            >
              <AccordionSingle
                data={tablesAdvanced}
                openIndices={AccordionGroups.advancedStats}
                onChange={handleAccordionSingleChange(ServeAccordionGroup.ADVANCED_STATS)}
              />
            </AccordionGroup>
          </Box>
        </SimpleGrid>
      </Box>
    </Container>
  );
};

export default Serve;
