import isEmpty from 'lodash/isEmpty';
import toLower from 'lodash/toLower';

import themeColors from '_shared/designSystem/theme/colors';
import { capitaliseFirstLetterOfString, capitaliseString } from '_shared/utils/stringUtil';

export const getVolumeTypeButtons = () => {
  return [
    {
      label: 'Frequency',
      value: 'volume',
      position: 'left'
    },
    {
      label: 'Impact',
      value: 'effectiveness',
      position: 'right'
    }
  ];
};

export const getPlayTypesButtons = () => {
  return [
    {
      label: 'Winning',
      value: 'winning',
      position: 'left'
    },
    {
      label: 'Losing',
      value: 'losing',
      position: 'right'
    }
  ];
};

export function formatBarChartData(input) {
  if (isEmpty(input)) return null;
  let categories = [];
  let data = [];
  const colors = getDetailedViewColours(input);
  if (!input[0]?.outcomes) {
    let dataArrayAll = [];
    input.forEach((item) => {
      categories.push(capitaliseFirstLetterOfString(item.id));
      dataArrayAll.push(item.score);
    });
    data.push({
      name: 'All Plays',
      data: dataArrayAll
    });
  } else {
    input.forEach((item) => {
      categories.push(capitaliseFirstLetterOfString(item.id));
    });
    input[0].outcomes.forEach((item) => data.push({ id: item.id, data: [] }));
    data.forEach((item, index) => {
      input.forEach((element) => {
        element.outcomes.forEach((element1) => {
          if (element1.id === item.id) data[index].data.push(element1.score);
        });
      });
    });

    data.forEach((item, index) => {
      data[index].name = capitaliseString(item.id);
      delete data[index].id;
    });
  }
  return {
    categories,
    data,
    colors
  };
}

export function formatPieChartData(input) {
  const barChartData = formatBarChartData(input);
  const colors = getDetailedViewColours(input);
  if (!barChartData) return null;
  let categories = [];
  let data = [];
  barChartData.data.forEach((item) => {
    categories.push(item.name);
    const totalForOutcome = item.data.reduce((a, b) => a + b);
    data.push(totalForOutcome);
  });
  return {
    categories,
    data,
    colors
  };
}

export function getOutcomesDataInOrder(input, playType) {
  if (isEmpty(input) || isEmpty(input.categories)) return {};
  if (!['winning', 'losing'].includes(playType)) return {};
  const winningOrder = ['Winner', 'In Attack', 'Opponent Error'];
  const losingOrder = ['Error', 'In Defence', 'Opponent Winner'];
  let categoriesResult = [];
  let dataResult = [];
  let colorsResult = [];
  const orderData = playType === 'winning' ? winningOrder : losingOrder;
  orderData.forEach((item) => {
    const index = input.categories.indexOf(item);
    if (index >= 0) {
      categoriesResult.push(input.categories[index]);
      dataResult.push(input.data[index]);
      colorsResult.push(input.colors[index]);
    }
  });
  return {
    categories: categoriesResult,
    data: dataResult,
    colors: colorsResult
  };
}

export function getAllPlaysOutcomesDataInOrder(input) {
  const result = [];
  const length = input.categories.length < 3 ? input.categories.length : 3;
  for (let i = 0; i < length; i++) {
    result.push({
      category: input.categories[i],
      value: input.data[0].data[i]
    });
  }
  return result;
}

export const convertNeutralToOther = (shots) => {
  if (shots) {
    shots.forEach((item) => {
      if (item.shot_type === 'neutral') {
        item.shot_type = 'other';
      }
    });
  }
  return shots;
};

export function getCourtLegend(playType) {
  switch (playType) {
    case 'all':
      return [
        {
          label: 'Winning Play',
          color: 'success.300'
        },
        {
          label: 'Losing Play',
          color: 'error.300'
        },
        {
          label: 'Other Play',
          color: 'grey.500'
        },
        {
          label: "Opponents' Volleys",
          color: 'ring'
        }
      ];
    case 'winning':
      return [
        {
          label: 'Winning Play',
          color: 'success.300'
        },
        {
          label: "Opponents' Volleys",
          color: 'ring'
        }
      ];
    case 'losing':
      return [
        {
          label: 'Losing Play',
          color: 'error.400'
        },
        {
          label: "Opponents' Volleys",
          color: 'ring'
        }
      ];
    default:
      return [];
  }
}

export function getDetailedViewLegend(data) {
  const colorsForCharts = ['warning.200', 'blueLight.300', 'purple.300', 'blue.300', 'pink.300', 'primary.200'];

  if (isEmpty(data)) return [];
  if (!data[0]?.outcomes) {
    return [
      {
        label: 'All Shots',
        color: 'primary.200'
      }
    ];
  }
  let output = [];
  let counter = 0;
  data[0]?.outcomes?.forEach((item) => {
    let color = '';
    if (toLower(item.id).includes('win')) color = 'success.300';
    else if (toLower(item.id).includes('error')) color = 'error.300';
    else {
      color = colorsForCharts[counter];
      counter++;
    }
    output.push({
      label: capitaliseString(item.id),
      color
    });
  });
  return output;
}

export function getDetailedViewColours(data) {
  let colors = [];
  const legendData = getDetailedViewLegend(data);
  legendData.forEach((item) => {
    const separated = item.color.split('.');
    const colorParent = separated[0];
    const value = separated[1];
    colors.push(themeColors[colorParent][value]);
  });
  return colors;
}

export const determineColor = (item) => {
  switch (item.label) {
    case 'Winning':
      return 'success.300';
    case 'Losing':
      return 'maroon';
    case 'Effectiveness':
      return item.score > 0 ? 'green.600' : 'red.600';
    case 'Other':
      return 'grey.300';
    case 'Volume':
      return 'primary.500';
    case 'Player Avg':
      return 'playerAverage';
    case 'Tour Avg':
      return 'tourAverage';
    default:
      return 'grey.300';
  }
};

export const getFontWeight = (label, volumeType, selectedPlayType) => {
  // If either Winning or Losing is selected, Effectiveness should not be bold
  if (label === 'Effectiveness' && (selectedPlayType === 'winning' || selectedPlayType === 'losing')) {
    return 'normal';
  }
  if (
    (label === 'Effectiveness' && volumeType === 'effectiveness') ||
    (label === 'Winning' && selectedPlayType === 'winning') ||
    (label === 'Losing' && selectedPlayType === 'losing')
  ) {
    return '900';
  }
  return 'normal';
};

export function getItemFromPlays(data, id) {
  if (isEmpty(data) || !id) return null;
  const result = data.filter((item) => item.id === id);
  if (isEmpty(result)) return null;
  else return result[0];
}

export const transformDataToBarGraphic = ([key, value]) => {
  return {
    id: key,
    label: capitaliseFirstLetterOfString(key),
    frequency: value.frequency ?? null,
    score: value.score ?? null,
    score_type: value.score_type,
    frequency_total: value.frequency_total || null
  };
};

export const transformTotalDataToBarGraphic = (total) => {
  return {
    id: 'total',
    label: 'Frequency',
    frequency: null,
    score: total?.score,
    score_type: total?.score_type,
    frequency_total: total?.frequency_total || null
  };
};

export const transformAveragesTotalDataToBarGraphicForPlayerAverage = (total) => {
  return {
    id: 'total',
    label: 'Player Avg',
    frequency: null,
    score: total?.player_average,
    score_type: total?.score_type,
    frequency_total: null
  };
};

export const transformAveragesTotalDataToBarGraphicForTourAverage = (total) => {
  return {
    id: 'total',
    label: 'Tour Avg',
    frequency: null,
    score: total?.tour_average,
    score_type: total?.score_type,
    frequency_total: null
  };
};

export const transformAveragesDataToBarGraphicForPlayerAverage = ([key, value]) => {
  return {
    id: key,
    label: 'Player Avg',
    frequency: null,
    score: value?.player_average,
    score_type: value?.score_type,
    frequency_total: null
  };
};

export const transformAveragesDataToBarGraphicForTourAverage = ([key, value]) => {
  return {
    id: key,
    label: 'Tour Avg',
    frequency: null,
    score: value?.tour_average,
    score_type: value?.score_type,
    frequency_total: null
  };
};

export const transformVolumeDataNoAverages = (data = {}) => {
  return Object.entries(data).map((item) => transformDataToBarGraphic(item));
};

export const transformVolumeDataWithAveragesForPlayerAverage = (dataWithAverages) => {
  return Object.entries(dataWithAverages).map((item) => transformAveragesDataToBarGraphicForPlayerAverage(item));
};

export const transformVolumeDataWithAveragesForTourAverage = (dataWithAverages) => {
  return Object.entries(dataWithAverages).map((item) => transformAveragesDataToBarGraphicForTourAverage(item));
};

export const mergeVolumeDataWithAveragesAndTourData = (data, dataPlayerAverage, dataTourAverage) => {
  // Merge data with averages for player average and tour average by finding the matching id of each object in the array
  // Eventually this will create an array of arrays
  // Each inner array will have the volume data and the corresponding averages for player and tour
  // This allows us to display all data sets in the same chart
  return data.map((item) => {
    const itemWithoutFrequency = { ...item, frequency: null };
    const playerAverage = dataPlayerAverage.find((average) => average.id === item.id);
    const tourAverage = dataTourAverage.find((average) => average.id === item.id);
    return [itemWithoutFrequency, playerAverage, tourAverage];
  });
};

export const transformDataNoAveragesForEffectivenessBarGraphic = (transformedVolumeData, effectivenessItem) => {
  const winningItem = transformedVolumeData?.find((item) => item.id === 'winning');
  const losingItem = transformedVolumeData?.find((item) => item.id === 'losing');

  return [
    {
      id: 'effectiveness',
      label: 'Effectiveness',
      frequency: effectivenessItem?.frequency,
      score: effectivenessItem?.score,
      score_type: effectivenessItem?.score_type,
      frequency_total: effectivenessItem?.frequency_total || null
    },
    winningItem,
    losingItem
  ];
};

export const transformDataWithAveragesForEffectivenessBarGraphic = (
  effectivenessNoAverages,
  effectivenessWithAverages
) => {
  return [
    {
      id: 'effectiveness',
      label: 'Effectiveness',
      frequency: null,
      score: effectivenessNoAverages?.score,
      score_type: effectivenessNoAverages?.score_type,
      frequency_total: effectivenessNoAverages?.frequency_total || null
    },
    {
      id: 'effectiveness',
      label: 'Player Avg',
      frequency: null,
      score: effectivenessWithAverages?.player_average,
      score_type: effectivenessWithAverages?.score_type,
      frequency_total: effectivenessNoAverages?.frequency_total || null
    },
    {
      id: 'effectiveness',
      label: 'Tour Avg',
      frequency: null,
      score: effectivenessWithAverages?.tour_average,
      score_type: effectivenessWithAverages?.score_type,
      frequency_total: effectivenessNoAverages?.frequency_total || null
    }
  ];
};

export const transformWinningAndLosingPlaysData = (dataNoAverages = {}, dataWithAverages = {}) => {
  const transformedData = transformVolumeDataNoAverages(dataNoAverages.volume || {});
  const transformedTotalData = transformTotalDataToBarGraphic(dataNoAverages.total || {});
  const transformedTotalDataWithAveragesForPlayerAverage = transformAveragesTotalDataToBarGraphicForPlayerAverage(
    dataWithAverages.total || {}
  );
  const transformedTotalDataWithAveragesForTourAverage = transformAveragesTotalDataToBarGraphicForTourAverage(
    dataWithAverages.total || {}
  );
  const transformedDataWithAveragesForPlayerAverage = transformVolumeDataWithAveragesForPlayerAverage(
    dataWithAverages.volume || {}
  );
  const transformedDataWithAveragesForTourAverage = transformVolumeDataWithAveragesForTourAverage(
    dataWithAverages.volume || {}
  );

  return {
    transformedData,
    mergedTotalData: mergeVolumeDataWithAveragesAndTourData(
      [transformedTotalData],
      [transformedTotalDataWithAveragesForPlayerAverage],
      [transformedTotalDataWithAveragesForTourAverage]
    ),
    transformedVolumeDataWithPlayerAndTourAverages: mergeVolumeDataWithAveragesAndTourData(
      transformedData,
      transformedDataWithAveragesForPlayerAverage,
      transformedDataWithAveragesForTourAverage
    ),
    effectivenessDataNoAverages: transformDataNoAveragesForEffectivenessBarGraphic(
      transformedData,
      dataNoAverages.effectiveness || {}
    ),
    effectivenessDataWithAverages: transformDataWithAveragesForEffectivenessBarGraphic(
      dataNoAverages.effectiveness || {},
      dataWithAverages.effectiveness || {}
    )
  };
};

export const sortPlays = (plays, playType, volumeType) => {
  if (!Array.isArray(plays) || plays.length === 0) {
    return [];
  }

  const getSortedPlay = (play) => {
    switch (true) {
      case volumeType === 'volume' && playType === 'all':
        return play.total.score || 0;
      case volumeType === 'effectiveness' && playType === 'all':
        return play.effectiveness.score || 0;
      case volumeType === 'volume' && playType === 'winning':
        return play.volume.winning.frequency || 0;
      case volumeType === 'volume' && playType === 'losing':
        return play.volume.losing.frequency || 0;
      case volumeType === 'effectiveness' && playType === 'winning':
        return play.volume.winning.score || 0;
      case volumeType === 'effectiveness' && playType === 'losing':
        return play.volume.losing.score || 0;
      default:
    }
  };

  return plays.slice().sort((a, b) => getSortedPlay(b) - getSortedPlay(a));
};

export const createTransformedType = (id, label, frequency, score, scoreType, color) => ({
  id,
  label,
  frequency,
  score,
  score_type: scoreType,
  color
});

export function convertVolumeType(volumeType) {
  return volumeType === 'effectiveness' ? 'impact' : 'frequency';
}
