import React, { ReactNode } from 'react';

import { Switch, Text, Flex, HStack, Box } from '_shared/designSystem/components';

interface RallyLengthHeaderProps {
  showAverages: boolean;
  onToggleByServeReturn?: () => void;
  isToggledByServeReturn?: boolean;
  onToggleWinPercentage?: () => void;
  isToggledWinPercentage?: boolean;
  tooltipText?: string | ReactNode;
}

const RallyLengthHeader: React.FC<RallyLengthHeaderProps> = ({
  showAverages,
  onToggleByServeReturn,
  isToggledByServeReturn,
  onToggleWinPercentage,
  isToggledWinPercentage
}) => {
  return (
    <Flex justify="space-between" align="flex-start" direction="row" width="100%">
      {/* Title */}
      <Box>
        <Text fontSize="md" fontWeight="semibold">
          Rally Length
        </Text>
      </Box>

      {/* Averages and Toggles */}
      <Flex
        direction={{ base: 'column', md: 'row' }}
        align={{ base: 'flex-end', md: 'center' }}
        gap={{ base: 4, md: 8 }}
      >
        {/* Averages */}
        <Flex justify="flex-end" gap={4}>
          {showAverages && (
            <HStack>
              <Box position="relative" mr={2}>
                <Box w={1} h={3} bg="playerAverage" />
                <Box position="absolute" top={0} w={3} h={1} bg="playerAverage" />
                <Box position="absolute" top={0} left={-2} w={3} h={1} bg="playerAverage" />
              </Box>
              <Text fontSize="xs">Player Avg</Text>
            </HStack>
          )}
          <HStack>
            <Box position="relative" mr={2}>
              <Box w={1} h={3} bg="tourAverage" />
              <Box position="absolute" top={0} w={3} h={1} bg="tourAverage" />
              <Box position="absolute" top={0} left={-2} w={3} h={1} bg="tourAverage" />
            </Box>
            <Text fontSize="xs">Tour Avg</Text>
          </HStack>
        </Flex>

        {/* Toggles */}
        <Flex justify="flex-end" gap={4}>
          <Flex align="center">
            <Switch
              isChecked={isToggledByServeReturn}
              colorScheme="green"
              onChange={onToggleByServeReturn}
              data-testid="byServeReturnToggle"
            />
            <Text fontSize="xs" ml={2}>
              By Serve & Return
            </Text>
          </Flex>
          <Flex align="center">
            <Switch
              isChecked={isToggledWinPercentage}
              colorScheme="green"
              onChange={onToggleWinPercentage}
              data-testid="winPercentageToggle"
            />
            <Text fontSize="xs" ml={2}>
              Win %
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RallyLengthHeader;
