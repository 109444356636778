import { Box, Flex, Stack } from '_shared/designSystem/components';

const GraphicSkeleton = () => {
  const backgroundColor = 'gray.100';
  const borderColor = 'gray.300';
  const silhouetteColor = 'gray.400';

  return (
    <Box
      bg={backgroundColor}
      height="1350px"
      width="1080px"
      position="relative"
      borderRadius="md"
      overflow="hidden"
      data-testid="graphic-skeleton"
    >
      {/* Top Section Placeholder */}
      <Flex position="absolute" top="0" width="100%" height="992px">
        {/* Left Column Placeholder (Rotated Text) */}
        <Flex width="166px" height="992px" flexDirection="column" justifyContent="center" alignItems="center">
          <Box transform="rotate(-90deg)" lineHeight={0.9} textAlign="center">
            {/* Placeholder for Line 1 */}
            <Box height="40px" width="180px" mb={2} bg={borderColor} borderRadius="md" />
            {/* Placeholder for Line 2 */}
            <Box height="60px" width="260px" bg={borderColor} borderRadius="md" />
          </Box>
        </Flex>

        {/* Right Column Placeholder (Court Surface and Player Image) */}
        <Flex width="914px" height="992px" position="relative">
          {/* Court Surface Placeholder */}
          <Box height="100%" width="100%" bg={borderColor} />

          {/* Player Silhouette Placeholder */}
          <Box
            position="absolute"
            top="55%"
            left="50%"
            transform="translate(-50%, -50%)"
            width="90%"
            height="90%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            color={silhouetteColor}
          >
            {/* SVG Headshot Silhouette */}
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 64 64"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
            >
              <path
                d="M32 32c8.837 0 16-7.163 16-16S40.837 0 32 0 16 7.163 16 16s7.163 16 16 16zm0 4c-10.493 0-32 5.25-32 15.75V64h64V51.75C64 41.25 42.493 36 32 36z"
                fill="currentColor"
              />
            </svg>
          </Box>
        </Flex>
      </Flex>

      {/* Middle Section Placeholder */}
      <Flex position="absolute" bottom="163px" width="100%" height="155px" alignItems="center">
        {/* Left Side Placeholder (Round Number) */}
        <Flex width="166px" height="138px" justifyContent="center" alignItems="center">
          <Flex
            width="70px"
            height="70px"
            border={`solid ${borderColor}`}
            borderRadius="50px"
            borderWidth={2}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {/* Placeholder for Round Number */}
            <Box height="20px" width="40px" bg={borderColor} borderRadius="md" />
          </Flex>
        </Flex>

        {/* Right Side Placeholder (Opponent Name and Score) */}
        <Flex width="914px" position="relative">
          <Stack
            width="70%"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            paddingX={12}
            spacing={4}
          >
            {/* Placeholder for Opponent Name */}
            <Box height="40px" width="80%" bg={borderColor} borderRadius="md" />
            {/* Placeholder for Score */}
            <Box height="60px" width="50%" bg={borderColor} borderRadius="md" />
          </Stack>

          {/* Tournament Logo Placeholder */}
          <Box width="30%" position="absolute" right="0" top="5px" height="53px">
            <Box height="53px" width="220px" bg={borderColor} borderRadius="md" />
          </Box>
        </Flex>
      </Flex>

      {/* Bottom Section Placeholder */}
      <Flex position="absolute" bottom="0" width="100%" height="140px" alignItems="center">
        {/* Left Side Placeholder (Competition Flag and Name) */}
        <Flex width="75%" justifyContent="flex-start" alignItems="center" paddingX={14} gap={6}>
          {/* Placeholder for Competition Flag */}
          <Box height="50px" width="50px" borderRadius="full" bg={borderColor} />
          {/* Placeholder for Competition Name */}
          <Box height="40px" width="300px" bg={borderColor} borderRadius="md" />
        </Flex>

        {/* Right Side Placeholder (ATP Tour Logo) */}
        <Flex width="25%" justifyContent="center" alignItems="center">
          <Box height="49px" width="165px" bg={borderColor} borderRadius="md" />
        </Flex>
      </Flex>
    </Box>
  );
};

export default GraphicSkeleton;
