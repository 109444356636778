import { useEffect, useState, useMemo } from 'react';

import { useNavigate, useSearch } from '@tanstack/react-location';
import isEmpty from 'lodash/isEmpty';
import { useSetRecoilState } from 'recoil';
import TournamentsSearch from 'tournaments/components/TournamentsSearch';
import { useTournamentsQuery } from 'tournaments/hooks/tournamentsHooks';
import { getSeasonsData, getFilteredTournaments } from 'tournaments/util/tournamentsUtil';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Box, ErrorMessage, Text, Flex, LoadingSpinner } from '_shared/designSystem/components';
import ScrollToTopArrow from '_shared/designSystem/components/icon/ScrollToTopArrow';
import { mobileHeaderTextState } from '_shared/globalState/atoms';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';
import usePageTitle from '_shared/utils/hooks/usePageTitle';
import { navigateTo } from '_shared/utils/navigationUtils';

import CardsGrid from './CardsGrid';

const Tournaments = () => {
  const navigate = useNavigate();
  const urlParams = useSearch();
  const { data: tournaments = [], isLoading, error } = useTournamentsQuery();
  const setMobileHeaderText = useSetRecoilState(mobileHeaderTextState);
  usePageTitle('Tournaments');
  const [formState, setFormState] = useState(() => ({
    searchSeason: urlParams.searchSeason ?? (isWimbPortal() ? 'all' : ''),
    tournamentName: urlParams.tournamentName ?? ''
  }));

  // Effect for mobile header
  useEffect(() => {
    setMobileHeaderText('Tournaments');
  }, [setMobileHeaderText]);

  // Effect for initializing season data
  useEffect(() => {
    if (!customSitesConfig.showTournamentsFilters || isLoading || error) return;

    if (!urlParams.searchSeason && !urlParams.tournamentName && tournaments.length > 0) {
      const possibleSeasons = getSeasonsData(tournaments);
      if (!isEmpty(possibleSeasons)) {
        setFormState((prev) => ({
          ...prev,
          searchSeason: possibleSeasons[0]
        }));
        navigate({
          search: (old) => ({
            ...old,
            searchSeason: possibleSeasons[0]
          })
        });
      }
    }
  }, [tournaments, navigate, urlParams.searchSeason, urlParams.tournamentName, isLoading, error]);

  const handleButtonClick = (paramName, value) => {
    switch (paramName) {
      case 'searchSeason':
        setFormState((prev) => ({
          ...prev,
          searchSeason: value
        }));
        navigate({ search: (old) => ({ ...old, searchSeason: value }) });
        break;
      case 'tournamentName':
        setFormState((prev) => ({
          ...prev,
          tournamentName: value
        }));
        navigate({ search: (old) => ({ ...old, tournamentName: value }) });
        break;
      default:
        return null;
    }
  };

  const tournamentData = useMemo(
    () => getFilteredTournaments(tournaments, formState.searchSeason, formState.tournamentName),
    [formState.searchSeason, formState.tournamentName, tournaments]
  );

  if (error) {
    return (
      <>
        <TitleHeader />
        <ErrorMessage message="Unable to retrieve tournaments, please try refreshing the page." />
      </>
    );
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const openTournamentPage = (tournamentId) => {
    navigateTo({
      url: `/schedule/${tournamentId}`,
      navigate,
      storeScroll: true
    });
  };

  const renderCardsGrid = (sections, key) => (
    <CardsGrid key={key} sections={sections} openTournamentPage={openTournamentPage} />
  );

  // Early return if no data
  if (!tournaments.length) {
    return (
      <Flex direction="column" gap={2}>
        <TitleHeader />
        <Text>No tournaments available</Text>
      </Flex>
    );
  }

  const tournamentCards =
    formState.searchSeason === 'all'
      ? tournamentData.map((year, index) => renderCardsGrid(tournamentData?.[index]?.sections, index))
      : renderCardsGrid(tournamentData);

  return (
    <Flex direction="column" gap={2}>
      <TitleHeader />
      {customSitesConfig.showTournamentsExplainerText && (
        <Box p={{ base: 2, md: null }}>
          <Text fontSize="xs">{customSitesConfig.tournamentsExplainerText}</Text>
        </Box>
      )}
      {customSitesConfig.showTournamentsFilters && (
        <Box px={{ base: 2, md: null }}>
          <TournamentsSearch
            seasons={getSeasonsData(tournaments)}
            formState={formState}
            handleButtonClick={handleButtonClick}
          />
        </Box>
      )}
      {tournamentCards}
      <ScrollToTopArrow />
    </Flex>
  );
};

const TitleHeader = () => (
  <Box display={{ base: 'none', md: 'initial' }}>
    <Text fontSize="3xl" fontWeight="medium" color="secondary.800">
      Tournaments
    </Text>
  </Box>
);

export default Tournaments;
