import { useState } from 'react';

import { Tooltip } from '@chakra-ui/react';

import { Box } from '_shared/designSystem/components';
import { isAtpTourPortal, isWimbPortal } from '_shared/utils/environment/currentEnv';

import { RenderDataPointProps } from './types';
import { normalizeToPercentage } from './utils';

export const RenderDataPoint = ({ value, color, label, min, max, tourAverageValue }: RenderDataPointProps) => {
  const [isLabelOpen, setIsLabelOpen] = useState(false);
  const normalizedValue = normalizeToPercentage(value, min, max);

  // Early return null with an outer wrapper to maintain hook consistency
  if (normalizedValue === null) {
    return <Box />; // Empty box instead of null
  }

  // Check if this is a player average point that overlaps with tour average
  const isOverlappingPlayerAverage =
    label === 'Player Average' &&
    tourAverageValue !== undefined &&
    normalizeToPercentage(tourAverageValue, min, max) === normalizedValue;

  const DataPoint = (
    <Box
      position="absolute"
      left={`${normalizedValue}%`}
      top="50%"
      transform={isOverlappingPlayerAverage ? 'translate(25%, -50%)' : 'translateY(-50%)'}
      w={isOverlappingPlayerAverage ? 2 : 3}
      h={isOverlappingPlayerAverage ? 2 : 3}
      borderRadius="full"
      bg={color}
      cursor={label !== 'Selected Matches' && 'pointer'}
      zIndex={1}
      onMouseEnter={() => setIsLabelOpen(true)}
      onMouseLeave={() => setIsLabelOpen(false)}
      onClick={() => setIsLabelOpen(!isLabelOpen)}
    />
  );

  if (label === 'Selected Matches') {
    return DataPoint;
  }

  return (
    <Tooltip
      label={`${value}%`}
      bgColor={color}
      fontSize="xs"
      isOpen={isLabelOpen}
      textColor={(isAtpTourPortal() || isWimbPortal()) && label === 'Player Average' ? 'black' : 'white'}
    >
      {DataPoint}
    </Tooltip>
  );
};
