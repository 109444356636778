import { useQuery } from 'react-query';
import { ScoutFetchingValuesType } from 'scout/types/scoutTypes';

import { performanceOverviewService } from '../service/performanceOverviewService';

export function usePerformanceTrackerQuery(playerId: number) {
  return useQuery(
    ['performanceOverviewService_performanceTracker', playerId],
    () => performanceOverviewService.getPerformanceTracker({ playerId }),
    { staleTime: Infinity, cacheTime: Infinity }
  );
}

export function usePerformanceRatingNoAveragesQuery(playerId: number, scoutFetchingValues: ScoutFetchingValuesType) {
  return useQuery(['performanceOverviewService_performanceRatingNoAverages', playerId, scoutFetchingValues], () =>
    performanceOverviewService.getPerformanceRating({ playerId, scoutFetchingValues, averages: false })
  );
}

export function usePerformanceRatingWithAveragesQuery(playerId: number, scoutFetchingValues: ScoutFetchingValuesType) {
  return useQuery(['performanceOverviewService_performanceRatingWithAverages', playerId, scoutFetchingValues], () =>
    performanceOverviewService.getPerformanceRating({ playerId, scoutFetchingValues, averages: true })
  );
}

export function useRadarNoAveragesQuery(playerId: number, scoutFetchingValues: ScoutFetchingValuesType) {
  return useQuery(['performanceOverviewService_radarNoAverages', playerId, scoutFetchingValues], () =>
    performanceOverviewService.getRadar({ playerId, scoutFetchingValues, averages: false })
  );
}

export function useRadarWithAveragesQuery(playerId: number, scoutFetchingValues: ScoutFetchingValuesType) {
  return useQuery(['performanceOverviewService_radarWithAverages', playerId, scoutFetchingValues], () =>
    performanceOverviewService.getRadar({ playerId, scoutFetchingValues, averages: true })
  );
}

export function usePointsProfileNoAveragesQuery(playerId: number, scoutFetchingValues: ScoutFetchingValuesType) {
  return useQuery(['performanceOverviewService_pointsProfileNoAverages', playerId, scoutFetchingValues], () =>
    performanceOverviewService.getPointsProfile({ playerId, scoutFetchingValues, averages: false })
  );
}

export function usePointsProfileWithAveragesQuery(playerId: number, scoutFetchingValues: ScoutFetchingValuesType) {
  return useQuery(['performanceOverviewService_pointsProfileWithAverages', playerId, scoutFetchingValues], () =>
    performanceOverviewService.getPointsProfile({ playerId, scoutFetchingValues, averages: true })
  );
}

export function useRallyLengthNoAveragesQuery(playerId: number, scoutFetchingValues: ScoutFetchingValuesType) {
  return useQuery(['performanceOverviewService_rallyLengthNoAverages', playerId, scoutFetchingValues], () =>
    performanceOverviewService.getRallyLength({ playerId, scoutFetchingValues, averages: false })
  );
}

export function useRallyLengthWithAveragesQuery(playerId: number, scoutFetchingValues: ScoutFetchingValuesType) {
  return useQuery(['performanceOverviewService_rallyLengthWithAverages', playerId, scoutFetchingValues], () =>
    performanceOverviewService.getRallyLength({ playerId, scoutFetchingValues, averages: true })
  );
}

export function useServeEffectivenessNoAveragesQuery(playerId: number, scoutFetchingValues: ScoutFetchingValuesType) {
  return useQuery(['performanceOverviewService_serveEffectivenessNoAverages', playerId, scoutFetchingValues], () =>
    performanceOverviewService.getServeEffectiveness({ playerId, scoutFetchingValues, averages: false })
  );
}

export function useServeEffectivenessWithAveragesQuery(playerId: number, scoutFetchingValues: ScoutFetchingValuesType) {
  return useQuery(['performanceOverviewService_serveEffectivenessWithAverages', playerId, scoutFetchingValues], () =>
    performanceOverviewService.getServeEffectiveness({ playerId, scoutFetchingValues, averages: true })
  );
}

export function useShotVariationNoAveragesQuery(playerId: number, scoutFetchingValues: ScoutFetchingValuesType) {
  return useQuery(['performanceOverviewService_shotVariationNoAverages', playerId, scoutFetchingValues], () =>
    performanceOverviewService.getShotVariation({ playerId, scoutFetchingValues, averages: false })
  );
}

export function useShotVariationWithAveragesQuery(playerId: number, scoutFetchingValues: ScoutFetchingValuesType) {
  return useQuery(['performanceOverviewService_shotVariationWithAverages', playerId, scoutFetchingValues], () =>
    performanceOverviewService.getShotVariation({ playerId, scoutFetchingValues, averages: true })
  );
}

export function useSituationProfileNoAveragesQuery(playerId: number, scoutFetchingValues: ScoutFetchingValuesType) {
  return useQuery(['performanceOverviewService_situationProfileNoAverages', playerId, scoutFetchingValues], () =>
    performanceOverviewService.getSituationProfile({ playerId, scoutFetchingValues, averages: false })
  );
}

export function useSituationProfileWithAveragesQuery(playerId: number, scoutFetchingValues: ScoutFetchingValuesType) {
  return useQuery(['performanceOverviewService_situationProfileWithAverages', playerId, scoutFetchingValues], () =>
    performanceOverviewService.getSituationProfile({ playerId, scoutFetchingValues, averages: true })
  );
}
