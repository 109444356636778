import { PointData, SituationProfileChartData } from './types';

export const mergeDataWithAverages = (
  key: 'all_points' | 'won',
  dataNoAverages: SituationProfileChartData,
  dataWithAverages: SituationProfileChartData | undefined
): PointData[] => {
  if (!dataWithAverages) return dataNoAverages[key];
  const noAvg = dataNoAverages[key];
  const withAvg = dataWithAverages[key];

  return noAvg.map((item) => {
    const averageItem = withAvg.find((avg) => avg.id === item.id);
    return {
      ...item,
      score: item.score !== null ? item.score : averageItem ? averageItem.score : null,
      tour_average: item.tour_average !== null ? item.tour_average : averageItem ? averageItem.tour_average : null,
      player_average:
        averageItem && averageItem.player_average !== null ? averageItem.player_average : item.player_average
    };
  });
};
