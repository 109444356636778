export const formatVideoTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const secondsOnly = Math.floor(seconds % 60);
  return `${minutes}:${secondsOnly < 10 ? `0${secondsOnly}` : secondsOnly}`;
};

export function getSeekTime(duration: number, clickRatio: number) {
  return duration * clickRatio < 1 ? Math.round(duration * clickRatio) : duration * clickRatio;
}

export const getButtonWidth = (containerWidth: number, buttonShrink: number, maxButtonWidth: number): string => {
  const calculatedWidth = Math.round(containerWidth * buttonShrink);
  return `${calculatedWidth > maxButtonWidth ? maxButtonWidth : calculatedWidth}px`;
};
