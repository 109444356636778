import React from 'react';

import { GRAPHIC_COMPONENTS } from '../graphicComponents';

import GraphicSkeleton from './GraphicSkeleton';
import { GraphicContainerProps } from './types';

const GraphicContainer: React.FC<GraphicContainerProps> = ({ graphicType, aspectRatio, data }) => {
  const renderGraphic = () => {
    const GraphicComponent = GRAPHIC_COMPONENTS[graphicType]?.[aspectRatio];
    return GraphicComponent ? <GraphicComponent data={data} /> : <GraphicSkeleton />;
  };

  return renderGraphic();
};

export default GraphicContainer;
