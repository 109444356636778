import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { Text, Icon, Flex, Tooltip } from '_shared/designSystem/components';
import { isMatchReportState } from '_shared/globalState/atoms';


export default function CircularMetricsHeading(props) {
  const isMatchReport = useRecoilValue(isMatchReportState);
  const InfoIcon = <Icon name="info" color="grey.900" height={4} width={4} />;
  const { headingText, tooltipText } = props;
  return (
    <Flex>
      <Text fontSize="md" fontWeight="semibold" mr={1}>
        {headingText}
      </Text>
      {!isMatchReport && <Tooltip tooltipText={tooltipText} elementToHover={InfoIcon} />}
    </Flex>
  );
}

CircularMetricsHeading.propTypes = {
  headingText: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired
};
