import React, { useRef, useState } from 'react';

import { useMeasure } from 'react-use';

import { Box, Text, Flex, Icon } from '_shared/designSystem/components';
import { isIosTouchDevice } from '_shared/utils/touchDevice.util';

import {
  gapWidthFraction,
  largeControlsHeight,
  largeControlsHeightOffset,
  largeControlsIconSize,
  largeControlsPaddingTop,
  largeControlsPlayerWidth,
  maxPlayButtonWidth,
  maxSkipButtonWidth,
  playButtonShrink,
  skipButtonShrink,
  smallControlsHeight,
  smallControlsHeightOffset,
  smallControlsIconSize,
  smallControlsPaddingTop
} from './constants';
import PlaybackControl from './PlaybackControl';
import { ControlsComponentProps } from './types';
import { formatVideoTime, getButtonWidth } from './util';

const Controls = ({
  handleSeek,
  currentTime,
  lengthOfVideo,
  played,
  handlePlay,
  handleFullScreen,
  isFullScreen,
  isPlaying,
  handlePlayPrev,
  handlePlayNext,
  handleClickVideo,
  disablePrev,
  disableNext,
  isTouchDevice,
  setIsPlaying,
  hasPlaylist,
  handleVolume,
  isMuted,
  playbackRate,
  setPlaybackRate,
  showPlaybackRate,
  setShowPlaybackRate
}: ControlsComponentProps) => {
  const [containerRef, { width: containerWidth, height: containerHeight }] = useMeasure();
  const [progressBarRef, { width: progressBarWidth }] = useMeasure();
  const [isDragging, setIsDragging] = useState(false);
  const progressBarContainerRef = useRef(null);

  const eventPreventDefault = (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleProgressBarMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    eventPreventDefault(event);
    setIsDragging(true);
    setIsPlaying(false);
    handleSeekControls(event);
  };

  const handleProgressBarMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    eventPreventDefault(event);
    if (isDragging) {
      handleSeekControls(event);
    }
  };

  const handleProgressBarMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
    eventPreventDefault(event);
    setIsDragging(false);
  };

  const handleProgressBarTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    eventPreventDefault(event);
    setIsDragging(true);
    setIsPlaying(false);
    handleSeekControls(event.touches[0]);
  };

  const handleProgressBarTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    eventPreventDefault(event);
    if (isDragging) {
      handleSeekControls(event.touches[0]);
    }
  };

  const handleProgressBarTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
    eventPreventDefault(event);
    setIsDragging(false);
  };

  const handlePlaybackRateMenuClick = () => {
    setShowPlaybackRate(!showPlaybackRate);
  };

  const handlePlaybackRateOptionsClick = (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
    rate: number
  ) => {
    eventPreventDefault(event);
    setPlaybackRate(rate);
  };

  // this is clicking on anywhere in the video above the progress bar
  const handleClickOrTouchBackground = (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    if (!isTouchDevice) {
      // desktop
      handlePlay();
    } else {
      if (isIosTouchDevice()) {
        // iOS
        if (event.type === 'touchstart') handleClickVideo();
      } else {
        // android
        if (event.type === 'click') handleClickVideo();
      }
    }
    setShowPlaybackRate(false);
  };

  // handling both click and touch events to save code duplication
  const handleClickOrTouchEvent = (
    event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
    handlerFunction: Function
  ) => {
    if (event.type === 'touchstart') {
      eventPreventDefault(event);
      handlerFunction();
    } else if (event.type === 'click') {
      if (isTouchDevice && !isIosTouchDevice()) {
        // android and browser dev tools will trigger both click and touchstart, so we need to only do one,
        // the touchstart, so we can ignore the click event here
        return;
      } else {
        eventPreventDefault(event);
        handlerFunction();
      }
    }
  };

  const handleSeekControls = (event: React.MouseEvent<HTMLDivElement> | React.Touch) => {
    const rect = (event.target as HTMLDivElement).getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const clickRatio = clickX / progressBarWidth;
    handleSeek(clickRatio);
  };

  const ControlButton = ({
    onClickFunction,
    onTouchStartFunction,
    iconName,
    iconLabel,
    disabled,
    color,
    height,
    width
  }) => (
    <Flex alignItems="center" justifyContent="center" cursor={disabled ? null : 'pointer'} opacity={disabled ? 0.5 : 1}>
      <Box
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          console.log('clicked icon');
          handleClickOrTouchEvent(e, onClickFunction);
        }}
        onTouchStart={(e: React.TouchEvent<HTMLDivElement>) => handleClickOrTouchEvent(e, onTouchStartFunction)}
      >
        <Icon
          name={iconName}
          color={color}
          height={height}
          width={width}
          data-testid={`${iconLabel}-button`}
          fallback={null}
        />
      </Box>
    </Flex>
  );

  const TouchScreenControls = () => (
    <Flex
      zIndex={4}
      position="absolute"
      justifyContent="center"
      bottom="45%"
      width="100%"
      alignItems="center"
      gap={`${containerWidth * gapWidthFraction}px`}
    >
      {hasPlaylist && (
        <ControlButton
          onClickFunction={handlePlayPrev}
          onTouchStartFunction={handlePlayPrev}
          iconName="playPrevious"
          disabled={disablePrev}
          color="white"
          iconLabel="skip-previous"
          width={getButtonWidth(containerWidth, skipButtonShrink, maxSkipButtonWidth)}
          height={getButtonWidth(containerWidth, skipButtonShrink, maxSkipButtonWidth)}
          data-testid="skip-previous-button"
        />
      )}
      <ControlButton
        iconLabel="play"
        disabled={false}
        iconName={isPlaying ? 'pause' : 'play'}
        onClickFunction={handlePlay}
        onTouchStartFunction={handlePlay}
        color="white"
        width={getButtonWidth(containerWidth, playButtonShrink, maxPlayButtonWidth)}
        height={getButtonWidth(containerWidth, playButtonShrink, maxPlayButtonWidth)}
      />
      {hasPlaylist && (
        <ControlButton
          onClickFunction={handlePlayNext}
          onTouchStartFunction={handlePlayNext}
          iconName="playNext"
          disabled={disableNext}
          color="white"
          iconLabel="skip-next"
          width={getButtonWidth(containerWidth, skipButtonShrink, maxSkipButtonWidth)}
          height={getButtonWidth(containerWidth, skipButtonShrink, maxSkipButtonWidth)}
          data-testid="skip-next-button"
        />
      )}
    </Flex>
  );

  const smallControls = containerWidth < largeControlsPlayerWidth;

  return (
    <Box
      data-testid="controls-panel"
      ref={containerRef}
      w="100%"
      h="100%"
      position="absolute"
      top={0}
      left={0}
      zIndex={2}
    >
      {isTouchDevice && !isDragging && (
        <Box position="absolute" top={0} left={0} w="100%" h="100%" bg="black" opacity={0.4} />
      )}
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        alignItems="center"
        px={3}
        height={smallControls ? smallControlsHeight : largeControlsHeight}
        pt={smallControls ? smallControlsPaddingTop : largeControlsPaddingTop}
        bgGradient="linear(to-t, black, transparent)"
      >
        <Box
          flex="1"
          h="20px"
          position="relative"
          onClick={handleSeekControls}
          cursor="pointer"
          ref={progressBarRef}
          alignItems="center"
          mb="1"
        >
          <Box
            onMouseDown={handleProgressBarMouseDown}
            onMouseMove={handleProgressBarMouseMove}
            onMouseUp={handleProgressBarMouseUp}
            onTouchStart={handleProgressBarTouchStart}
            onTouchMove={handleProgressBarTouchMove}
            onTouchEnd={handleProgressBarTouchEnd}
            cursor="pointer"
            ref={progressBarContainerRef}
            h="20px"
            w="100%"
            position="relative"
            pt={1.5}
          >
            <Box flex="1" h="6px" bg="grey.300" position="relative">
              <Box w={`${played * 100}%`} h="100%" bg="primary.500" top="0" left="0" />
            </Box>
          </Box>
        </Box>
        <Flex justify="space-between" px={4}>
          <Flex gap={5}>
            {!isTouchDevice && (
              <Box cursor="pointer">
                <Icon
                  name={isPlaying ? 'pause' : 'play'}
                  color="white"
                  fallback={null}
                  height={smallControls ? smallControlsIconSize : largeControlsIconSize}
                  width={smallControls ? smallControlsIconSize : largeControlsIconSize}
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => handleClickOrTouchEvent(e, handlePlay)}
                  onTouchStart={(e: React.TouchEvent<HTMLDivElement>) => handleClickOrTouchEvent(e, handlePlay)}
                />
              </Box>
            )}
            <Text fontSize="sm" color="white">{`${formatVideoTime(currentTime)} / ${formatVideoTime(
              lengthOfVideo
            )}`}</Text>
          </Flex>
          <Flex>
            <Box
              w="65px"
              position="relative"
              cursor="pointer"
              pr={4}
              pb={3}
              onClick={(e: React.MouseEvent<HTMLDivElement>) => handleClickOrTouchEvent(e, handlePlaybackRateMenuClick)}
              onTouchStart={(e: React.TouchEvent<HTMLDivElement>) =>
                handleClickOrTouchEvent(e, handlePlaybackRateMenuClick)
              }
            >
              <Text
                fontSize={smallControls ? 'sm' : 'md'}
                lineHeight={smallControls ? '18px' : '22px'}
                color="white"
                textAlign="right"
                userSelect="none"
              >{`${playbackRate}x`}</Text>
              {showPlaybackRate && (
                <Box zIndex={5} position="absolute" bottom={10} right={3}>
                  <PlaybackControl
                    playbackRate={playbackRate}
                    handlePlaybackRateOptionsClick={handlePlaybackRateOptionsClick}
                  />
                </Box>
              )}
            </Box>
            <Box cursor="pointer" px={4} pb={3}>
              <Icon
                name={isMuted ? 'volumeX' : 'volume'}
                color="white"
                fallback={null}
                height={smallControls ? smallControlsIconSize : largeControlsIconSize}
                width={smallControls ? smallControlsIconSize : largeControlsIconSize}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => handleClickOrTouchEvent(e, handleVolume)}
                onTouchStart={(e: React.TouchEvent<HTMLDivElement>) => handleClickOrTouchEvent(e, handleVolume)}
              />
            </Box>
            <Box cursor="pointer" px={3} pb={3}>
              <Icon
                name={isFullScreen ? 'fullScreenExit' : 'fullScreen'}
                color="white"
                fallback={null}
                height={smallControls ? smallControlsIconSize : largeControlsIconSize}
                width={smallControls ? smallControlsIconSize : largeControlsIconSize}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => handleClickOrTouchEvent(e, handleFullScreen)}
                onTouchStart={(e: React.TouchEvent<HTMLDivElement>) => handleClickOrTouchEvent(e, handleFullScreen)}
              />
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Box
        position="absolute"
        top={0}
        left={0}
        w="100%"
        h={`${containerHeight - (smallControls ? smallControlsHeightOffset : largeControlsHeightOffset)}px`}
        onClick={handleClickOrTouchBackground}
        onTouchStart={handleClickOrTouchBackground}
      />
      {isTouchDevice && !isDragging && <TouchScreenControls />}
    </Box>
  );
};

export default Controls;
