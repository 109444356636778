import { mobileHeaderHeight, topMenuHeight } from '_layouts/margins';
import { BREAKDOWN_METRICS_TYPES } from 'match/_shared/matchConstants';
import BreakdownMetrics from 'match/components/performance/breakdownMetrics/BreakdownMetrics';
import { PlayerDetailsType, ScoutFetchingValuesType, WinLoss } from 'scout/types/scoutTypes';

import {
  Box,
  Flex,
  useBreakpointValue,
  Grid,
  GridItem,
  Image,
  Skeleton,
  Legend,
  Text,
  HStack,
  Switch
} from '_shared/designSystem/components';
import { isTvPortal, isWimbPortal } from '_shared/utils/environment/currentEnv';

import {
  usePerformanceRatingNoAveragesQuery,
  usePerformanceRatingWithAveragesQuery,
  usePerformanceTrackerQuery,
  usePointsProfileNoAveragesQuery,
  usePointsProfileWithAveragesQuery,
  useRadarNoAveragesQuery,
  useRadarWithAveragesQuery,
  useRallyLengthNoAveragesQuery,
  useRallyLengthWithAveragesQuery,
  useServeEffectivenessNoAveragesQuery,
  useServeEffectivenessWithAveragesQuery,
  useShotVariationNoAveragesQuery,
  useShotVariationWithAveragesQuery,
  useSituationProfileNoAveragesQuery,
  useSituationProfileWithAveragesQuery
} from '../hooks/dataFetchingHooks';

import PerformanceTracker from './performanceTracker/PerformanceTracker';
import { PerformanceTrackerCustomLoadingGraphic } from './performanceTracker/PerformanceTrackerCustomLoadingGraphic';
import PointsProfile from './pointsProfile/PointsProfile';
import PointsProfileLoadingSkeleton from './pointsProfile/PointsProfileLoadingSkeleton';
import Radar from './radar/Radar';
import RadarSkeleton from './radar/RadarSkeleton';
import RallyLengthChart from './rallyLength/RallyLengthChart';
import HorizontalBarsLoadingSpinner from './shared/HorizontalBarsLoadingSkeleton';
import VerticalBarsLoadingSpinner from './shared/VerticalBarsLoadingSkeleton';
import SituationProfileChart from './situationProfile/SituationProfileChart';
import { WinLossOverview } from './winLoss/WinLossOverview';

type PerformanceOverviewProps = {
  playerDetailsData: PlayerDetailsType;
  scoutFetchingValues: ScoutFetchingValuesType;
  showAverages: boolean;
  handleSetShowAverages: (showAverages: boolean) => void;
  winLoss: WinLoss;
  winLossIsLoading: boolean;
};

const PerformanceOverview = ({
  playerDetailsData,
  scoutFetchingValues,
  showAverages,
  handleSetShowAverages,
  winLoss,
  winLossIsLoading
}: PerformanceOverviewProps) => {
  const {
    player_id: playerId,
    player_last_name: name,
    win_loss_current_year: winLossCurrentYear,
    win_loss_last_52_weeks: winLossLast52Weeks,
    ranking,
    hero_image_url_atptour: imageUrlAtp,
    hero_image_url_wimb: imageUrlWimb
  } = playerDetailsData ?? {};

  const { data: performanceTrackerNoAverages, isLoading: performanceTrackerNoAveragesIsLoading } =
    usePerformanceTrackerQuery(playerId);
  const { data: performanceRatingNoAveragesData, isLoading: performanceRatingNoAveragesIsLoading } =
    usePerformanceRatingNoAveragesQuery(playerId, scoutFetchingValues);
  const { data: performanceRatingWithAveragesData } = usePerformanceRatingWithAveragesQuery(
    playerId,
    scoutFetchingValues
  );
  const { data: pointsProfileNoAveragesData, isLoading: pointsProfileNoAveragesIsLoading } =
    usePointsProfileNoAveragesQuery(playerId, scoutFetchingValues);
  const { data: pointsProfileWithAveragesData } = usePointsProfileWithAveragesQuery(playerId, scoutFetchingValues);
  const { data: radarNoAveragesData, isLoading: radarNoAveragesIsLoading } = useRadarNoAveragesQuery(
    playerId,
    scoutFetchingValues
  );
  const { data: radarWithAveragesData } = useRadarWithAveragesQuery(playerId, scoutFetchingValues);
  const { data: rallyLengthNoAveragesData, isLoading: rallyLengthNoAveragesIsLoading } = useRallyLengthNoAveragesQuery(
    playerId,
    scoutFetchingValues
  );
  const { data: rallyLengthWithAveragesData } = useRallyLengthWithAveragesQuery(playerId, scoutFetchingValues);
  const { data: serveEffectivenessNoAverages, isLoading: serveEffectivenessNoAveragesIsLoading } =
    useServeEffectivenessNoAveragesQuery(playerId, scoutFetchingValues);
  const { data: serveEffectivenessWithAverages } = useServeEffectivenessWithAveragesQuery(
    playerId,
    scoutFetchingValues
  );
  const { data: shotVariationNoAverages, isLoading: shotVariationNoAveragesIsLoading } =
    useShotVariationNoAveragesQuery(playerId, scoutFetchingValues);
  const { data: shotVariationWithAverages } = useShotVariationWithAveragesQuery(playerId, scoutFetchingValues);
  const { data: situationProfileNoAveragesData, isLoading: situationProfileNoAveragesIsLoading } =
    useSituationProfileNoAveragesQuery(playerId, scoutFetchingValues);
  const { data: situationProfileWithAveragesData } = useSituationProfileWithAveragesQuery(
    playerId,
    scoutFetchingValues
  );

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Flex width="100%" position="relative">
      {/* Main content container */}
      <Box bg={{ base: 'white', md: 'grey.100' }} position="relative" width={{ base: '100%', xl: '80%' }} zIndex={1}>
        <Flex justify={isMobile ? 'center' : 'flex-start'} mx={6} mt={4} mb={1}>
          {winLossIsLoading ? (
            <Skeleton
              startColor="grey.200"
              endColor="grey.300"
              isLoaded={!winLossIsLoading}
              height={{ base: '150px', sm: '100px', lg: '50px' }}
              width="100%"
            />
          ) : (
            <Box>
              <WinLossOverview
                winLoss={winLoss}
                winLossCurrentYear={winLossCurrentYear}
                winLossLast52Weeks={winLossLast52Weeks}
                ranking={ranking}
              />
            </Box>
          )}
        </Flex>
        <Box
          pl={6}
          py={3}
          mb={{ base: 2, md: 0 }}
          position="sticky"
          top={{
            base: `${mobileHeaderHeight}px`,
            md: `${topMenuHeight}px`
          }}
          zIndex={4}
          bg={{ base: 'white', md: 'grey.100' }}
        >
          <Flex gap={5} align="center" flexWrap="wrap">
            <Legend fields={[{ label: 'Selected Matches', color: 'primary.500' }]} />
            <Flex alignItems="center" gap={2}>
              <Switch
                isChecked={showAverages}
                colorScheme="green"
                onChange={() => {
                  handleSetShowAverages(!showAverages);
                }}
              />
              <Text fontSize="sm" fontWeight="medium" color="grey.700">
                Player Avg
              </Text>
            </Flex>
          </Flex>
        </Box>

        <Box>
          <Grid
            templateColumns={{ base: '1fr', lg: 'repeat(2, minmax(0, 1fr))' }}
            gap={{ base: 0, md: 3 }}
            p={{ base: 0, sm: 0, md: 3 }}
            pt={0}
            borderRadius="lg"
          >
            {/* Performance Rating */}
            <GridItem colSpan={{ base: 1, lg: 2 }} overflowX="hidden" width="100%">
              <GridItemWrapper isMobile={isMobile}>
                <Box
                  overflowX="hidden"
                  overflowY="hidden"
                  width="100%"
                  maxWidth="100vw"
                  sx={{
                    '&::-webkit-scrollbar': {
                      display: 'none'
                    },
                    scrollbarWidth: 'none'
                  }}
                >
                  <Box minWidth="100%">
                    {performanceTrackerNoAveragesIsLoading ? (
                      <GridItemWrapper isMobile={isMobile}>
                        <Box pt={{ base: 5, md: 0 }}>
                          <PerformanceTrackerCustomLoadingGraphic />
                        </Box>
                      </GridItemWrapper>
                    ) : (
                      <PerformanceTracker
                        tournamentsAndTrendLine={performanceTrackerNoAverages}
                        performanceRatingNoAverages={performanceRatingNoAveragesData}
                        isLoadingPerformanceRating={performanceRatingNoAveragesIsLoading}
                        performanceRatingWithAverages={performanceRatingWithAveragesData}
                        showAverages={showAverages}
                        playerName={name}
                      />
                    )}
                  </Box>
                </Box>
              </GridItemWrapper>
            </GridItem>

            {/* Radar */}
            <GridItem colSpan={1}>
              <GridItemWrapper isMobile={isMobile}>
                <Box width="100%" height="100%" display="flex" justifyContent="center">
                  {radarNoAveragesIsLoading ? (
                    <RadarSkeleton />
                  ) : (
                    <Radar
                      dataNoAverages={radarNoAveragesData}
                      dataWithAverages={radarWithAveragesData}
                      showAverages={showAverages}
                      isLoading={radarNoAveragesIsLoading}
                    />
                  )}
                </Box>
              </GridItemWrapper>
            </GridItem>

            {/* Points Profile */}
            <GridItem colSpan={1}>
              <GridItemWrapper isMobile={isMobile}>
                <Box width="100%">
                  {pointsProfileNoAveragesIsLoading ? (
                    <PointsProfileLoadingSkeleton />
                  ) : (
                    <Box mb={{ base: 5, md: 0 }}>
                      <PointsProfile
                        dataNoAverages={pointsProfileNoAveragesData}
                        dataWithAverages={pointsProfileWithAveragesData}
                        showAverages={showAverages}
                      />
                    </Box>
                  )}
                </Box>
              </GridItemWrapper>
            </GridItem>

            {/* Rally Length */}
            <GridItem colSpan={{ base: 1, lg: 2 }}>
              <GridItemWrapper isMobile={isMobile}>
                <Box width="100%">
                  {rallyLengthNoAveragesIsLoading ? (
                    <HorizontalBarsLoadingSpinner
                      numberOfBars={3}
                      showSubheader={false}
                      showWinToggle={true}
                      showServeReturnToggle={true}
                    />
                  ) : (
                    <Box py={{ base: 5, md: 0 }}>
                      <RallyLengthChart
                        dataNoAverages={rallyLengthNoAveragesData}
                        dataWithAverages={rallyLengthWithAveragesData}
                        showAverages={showAverages}
                      />
                    </Box>
                  )}
                </Box>
              </GridItemWrapper>
            </GridItem>

            {/* Situation Profile */}
            <GridItem colSpan={{ base: 1, lg: 2 }}>
              <GridItemWrapper isMobile={isMobile}>
                <Box>
                  {situationProfileNoAveragesIsLoading ? (
                    <HorizontalBarsLoadingSpinner
                      numberOfBars={3}
                      showSubheader={true}
                      showWinToggle={true}
                      showServeReturnToggle={false}
                    />
                  ) : (
                    <Box py={{ base: 5, md: 0 }}>
                      <SituationProfileChart
                        dataNoAverages={situationProfileNoAveragesData}
                        dataWithAverages={situationProfileWithAveragesData}
                        showAverages={showAverages}
                      />
                    </Box>
                  )}
                </Box>
              </GridItemWrapper>
            </GridItem>

            {/* Serve Effectiveness */}
            <GridItem colSpan={1}>
              <GridItemWrapper isMobile={isMobile}>
                {serveEffectivenessNoAveragesIsLoading ? (
                  <VerticalBarsLoadingSpinner
                    numberOfBars={2}
                    numberOfSegments={3}
                    showTopPercentages={true}
                    barHeight="400px"
                  />
                ) : (
                  <Box>
                    <BreakdownMetricsHeader heading="Serve Effectiveness" showAverages={showAverages} />
                    <Box py={8} maxW="360px" margin="auto">
                      <BreakdownMetrics
                        graphicType={BREAKDOWN_METRICS_TYPES.SERVE_EFFECTIVENESS}
                        dataNoAverages={serveEffectivenessNoAverages}
                        dataWithAverages={serveEffectivenessWithAverages}
                        players={null}
                        showAverages={showAverages}
                        isScout
                        isReport={false}
                        isPerformanceOverviewPage
                      />
                    </Box>
                  </Box>
                )}
              </GridItemWrapper>
            </GridItem>

            {/* Shot Variation */}
            <GridItem colSpan={1}>
              <GridItemWrapper isMobile={isMobile}>
                {shotVariationNoAveragesIsLoading ? (
                  <VerticalBarsLoadingSpinner
                    numberOfBars={1}
                    numberOfSegments={6}
                    showTopPercentages={true}
                    barHeight="400px"
                  />
                ) : (
                  <Box>
                    <BreakdownMetricsHeader heading="Shot Variation" showAverages={showAverages} />
                    <Box py={8}>
                      <BreakdownMetrics
                        graphicType={BREAKDOWN_METRICS_TYPES.SHOT_VARIATION}
                        dataNoAverages={shotVariationNoAverages}
                        dataWithAverages={shotVariationWithAverages}
                        players={null}
                        showAverages={showAverages}
                        isScout
                        isReport={false}
                        isPerformanceOverviewPage
                      />
                    </Box>
                  </Box>
                )}
              </GridItemWrapper>
            </GridItem>
          </Grid>
        </Box>
      </Box>

      {/* Hero Image container */}
      <Box width={{ base: '0%', xl: '20%' }} position="sticky" h="667px" top={topMenuHeight}>
        <Flex overflow="hidden" justify="center" px={isWimbPortal() ? 3 : 0}>
          <Image
            src={isWimbPortal() ? imageUrlWimb : imageUrlAtp ?? null}
            fallbackSrc={undefined}
            minW={isWimbPortal() ? null : '388px'}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export default PerformanceOverview;

const GridItemWrapper = ({ children, isMobile }) => (
  <Box bg="white" boxShadow={{ md: 'md' }} borderRadius="md" p={isMobile ? 0 : 2} height="100%" width="100%">
    <Box
      margin="auto"
      w="95%"
      borderBottom={{ base: '1px solid', md: 'none' }}
      borderColor={{ base: isTvPortal() ? 'primary.300' : 'primary.100', md: 'none' }}
    />
    {children}
  </Box>
);

const BreakdownMetricsHeader = ({ heading, showAverages }) => (
  <Flex justify="space-between" align="center" direction="row" px={4} pt={4} mb={2}>
    <Text fontSize="md" fontWeight="semibold" mb={{ base: 2, md: 0 }}>
      {heading}
    </Text>
    <Flex gap={4} direction="row" align="center">
      {showAverages && (
        <HStack>
          <Box w={3} h={3} borderRadius="full" bg="playerAverage" />
          <Text fontSize="xs">Player Avg</Text>
        </HStack>
      )}
      <HStack>
        <Box w={3} h={3} borderRadius="full" bg="tourAverage" />
        <Text fontSize="xs">Tour Avg</Text>
      </HStack>
    </Flex>
  </Flex>
);
