export const ServeAccordionGroup = {
  COURT_GRAPHICS: 'courtGraphics',
  ADVANCED_COURT_GRAPHICS: 'advancedCourtGraphics',
  STATS: 'stats',
  ADVANCED_STATS: 'advancedStats'
} as const;

export const ServeAccordionGroupTitle = {
  COURT_GRAPHICS: 'Court Graphics',
  ADVANCED_COURT_GRAPHICS: 'Advanced Court Graphics',
  STATS: 'Stats',
  ADVANCED_STATS: 'Advanced Stats'
};

export const courtGraphics = [
  {
    title: 'Serve Placement',
    content: ''
  },
  {
    title: 'Serve In',
    content: ''
  },
  {
    title: 'Serve Win %',
    content: ''
  },
  {
    title: 'Serve Unreturned',
    content: ''
  }
];

export const courtGraphicsAdvanced = [
  {
    title: 'Serve Speed',
    content: ''
  },
  {
    title: 'Serve Accuracy',
    content: ''
  },
  {
    title: 'Serve +1 Contact',
    content: ''
  },
  {
    title: 'Serve +1 Placement',
    content: ''
  },
  {
    title: 'Serve Direction',
    content: ''
  },
  {
    title: 'Serve Depth',
    content: ''
  }
];

export const tables = [
  {
    title: 'Summary',
    content: ''
  },
  {
    title: 'Ball Characteristics',
    content: ''
  }
];

export const tablesAdvanced = [
  {
    title: 'Serve Effectiveness',
    content: ''
  },
  {
    title: 'Rally Length',
    content: ''
  },
  {
    title: 'Serve +1',
    content: ''
  },
  {
    title: 'Serve & Volley',
    content: ''
  }
];
