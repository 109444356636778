import React from 'react';

import { useMeasure } from 'react-use';

import { Box, SkeletonCircle, useBreakpointValue } from '_shared/designSystem/components';
import colors from '_shared/designSystem/theme/colors';

import { createRadarPoints } from './radarUtils';

const RadarSkeleton: React.FC = () => {
  const [containerRef, { width: _containerWidth }] = useMeasure();

  const containerWidth = useBreakpointValue({
    base: 350,
    sm: 400,
    md: 450,
    lg: _containerWidth
  });

  return (
    <Box ref={containerRef} w="100%" h={{ base: '400px', sm: '450px', md: '500px', lg: '100%' }} position="relative">
      <Box position="absolute" zIndex={3} top="50%" left="50%" transform="translate(-50%, -50%)">
        <SkeletonCircle
          startColor="white"
          endColor="white"
          size={(containerWidth * 0.67).toString()}
          data-testid="radar-skeleton-loader"
        />
      </Box>
      <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" zIndex={2}>
        <svg width={containerWidth * 0.8} height={containerWidth * 0.8} viewBox="-200 -200 400 400">
          <polygon points={createRadarPoints(containerWidth)} fill={colors.grey[400]} />
        </svg>
      </Box>
      <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
        <SkeletonCircle
          startColor="grey.200"
          endColor="grey.300"
          size={(containerWidth * 0.95).toString()}
          data-testid="radar-skeleton-loader"
        />
      </Box>
    </Box>
  );
};

export default RadarSkeleton;
