import React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { useLocation, useNavigate } from '@tanstack/react-location';
import { BackToWimbledonLink, goBackToWimbledon } from '_customSites/wimb/components/BackToWimbledonLink';
import { isWimbMyWSession } from '_customSites/wimb/services/wimbLocalStorage';
import { mainAreaMarginLeft, maxSiteWidth, topMenuHeight } from '_layouts/margins';
import InsightsExplained from 'insightsExplained/components/InsightsExplained';
import { useInsightsExplained } from 'insightsExplained/hooks/useInsightsExplained';
import isEmpty from 'lodash/isEmpty';
import { useRecoilValue } from 'recoil';
import { useTournamentsQuery } from 'tournaments/hooks/tournamentsHooks';

import customSitesConfig from '_shared/config/customSitesConfig';
import { mainMenuItems, routesMapping } from '_shared/constants/mainMenu';
import { tournamentStatus } from '_shared/constants/matchTypes';
import { userPermissionTypes } from '_shared/constants/user';
import {
  Box,
  Center,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue
} from '_shared/designSystem/components';
import { userPermissionsState } from '_shared/globalState/atoms';
import { isAtpTourPortal, isTvPortal, isWimbPortal } from '_shared/utils/environment/currentEnv';
import useFeature from '_shared/utils/hooks/useFeature';
import { checkPermission } from '_shared/utils/permissions';

import Logo from '../logo/Logo';

import AdditionalOptions from './AdditionalOptions';
import TopMenuLogo from './TopMenuLogo';
import {
  InsightsExplainedButtonProps,
  LiveTournamentsData,
  TodayAtLinkRowProps,
  TopMenuItemProps,
  Tournament
} from './topMenuTypes';

const TopMenu = () => {
  const leaderboardEnabled = useFeature('leaderboard');
  const scoutEnabled = useFeature('scout');
  const headToHeadEnabled = useFeature('headToHead');
  const graphicsEnabled = useFeature('graphics_creator');
  const [activeMenuItem, setActiveMenuItem] = useState(mainMenuItems.TOURNAMENTS);
  const { permissions } = useRecoilValue(userPermissionsState);
  const navigate = useNavigate();
  const location = useLocation();
  const iconOnly = useBreakpointValue(
    {
      base: true,
      lg: false
    },
    { ssr: false }
  );
  const { data } = useTournamentsQuery();

  const liveTournaments = useGetLiveTournaments(data);

  useEffect(() => {
    const noMenuUpdate = sessionStorage.getItem('noMenuUpdate'); // Check the flag

    if (noMenuUpdate) {
      sessionStorage.removeItem('noMenuUpdate'); // Clear the flag for future navigations
      return; // Skip updating the active menu item
    }

    const currentRoute = routesMapping.find(({ routes }) => {
      const pathRoot = location.current.pathname?.split('/')[1];
      return routes.includes(pathRoot);
    });

    if (currentRoute) {
      setActiveMenuItem(currentRoute.id);
    }
  }, [location, location.current.pathname]);

  const selectMenuItem = (link: string) => {
    // Clear the specific session storage item
    sessionStorage.removeItem('prevPath');

    navigate({ to: link });
  };

  const TopMenuItem = ({ id, label, link }: TopMenuItemProps) => {
    const styles = isWimbPortal()
      ? {
          color: 'secondary.800',
          fontSize: 'mainMenuItem',
          fontWeight: 'bold'
        }
      : {
          color: 'white',
          fontSize: 'sm',
          fontWeight: 'normal'
        };
    const padding = isWimbPortal() ? 3 : { base: 2, lg: 3 };
    return (
      <Center onClick={() => selectMenuItem(link)} cursor="pointer">
        {activeMenuItem === id ? (
          <Center bg="primary.500" h="28px" borderRadius="md" p={padding}>
            <Text
              {...styles}
              fontWeight={isTvPortal() ? 'semibold' : 'normal'}
              color={isTvPortal() ? 'charcoal.400' : 'white'}
            >
              {label}
            </Text>
          </Center>
        ) : (
          <Box p={padding}>
            <Text {...styles}>{label}</Text>
          </Box>
        )}
      </Center>
    );
  };

  const menuBackgroundColour = isWimbPortal() ? 'grey.100' : 'secondary.800';
  const showBorder = isWimbPortal();

  return (
    <Box
      h={`${topMenuHeight}px`}
      bg={menuBackgroundColour}
      w="100vw"
      border={showBorder ? '1px solid' : null}
      borderColor={showBorder ? 'primary.800' : null}
    >
      <Flex justify="center">
        <Flex
          px={{ base: 0, md: `${mainAreaMarginLeft}px` }}
          justify="space-between"
          w={{ base: '100vw', '2xl': `${maxSiteWidth}px` }}
          h={`${topMenuHeight - 2}px`}
        >
          <Flex gap={isWimbPortal() || iconOnly ? 3 : 6} w="100%">
            <Box>
              <Center
                w="100%"
                mt={1}
                mr={2}
                onClick={() => (isWimbMyWSession() && iconOnly ? goBackToWimbledon() : window.location.replace('/'))}
                key="home"
                cursor="pointer"
              >
                {isWimbPortal() ? <TopWimbMenuLogo /> : <TopMenuLogo iconOnly={iconOnly} />}
              </Center>
            </Box>
            {isWimbMyWSession() && !iconOnly && (
              <Center>
                <BackToWimbledonLink />
              </Center>
            )}
            <Flex justify="space-between" bg={menuBackgroundColour} w="100%">
              <Flex>
                <TopMenuItem
                  id={mainMenuItems.TOURNAMENTS}
                  label="Tournaments"
                  link={customSitesConfig.tournamentsUrlPath}
                />
                {scoutEnabled && checkPermission(userPermissionTypes.SCOUT_AREA, permissions) && (
                  <TopMenuItem id={mainMenuItems.SCOUT_AREA} label="Scout Area" link="/players" />
                )}
                {leaderboardEnabled && checkPermission(userPermissionTypes.LEADERBOARD, permissions) && (
                  <TopMenuItem id={mainMenuItems.LEADERBOARD} label="Leaderboard" link="/leaderboard" />
                )}
                {headToHeadEnabled && checkPermission(userPermissionTypes.HEAD_TO_HEAD, permissions) && (
                  <TopMenuItem id={mainMenuItems.HEAD_TO_HEAD} label="Head to Head" link="/head-to-head" />
                )}
                {graphicsEnabled && checkPermission(userPermissionTypes.GRAPHICS_CREATOR, permissions) && (
                  <TopMenuItem id={mainMenuItems.GRAPHICS_CREATOR} label="Graphics" link="/graphics" />
                )}
                <Center ml={2}>
                  {customSitesConfig.showTodayAtInMenu && <TodayAtLinkRow liveTournaments={liveTournaments} />}
                </Center>
              </Flex>
            </Flex>
          </Flex>
          <InsightsExplainedButton iconOnly={iconOnly} />
          {!isWimbMyWSession() && (
            <Box w={isAtpTourPortal() ? (iconOnly ? '130px' : '200px') : iconOnly ? '60px' : '130px'}>
              <AdditionalOptions isTopMenu iconOnly={iconOnly} />
            </Box>
          )}
        </Flex>
      </Flex>
      <InsightsExplained />
    </Box>
  );
};

export const TopWimbMenuLogo = () => (
  <Center height="100%" width="100%">
    <Logo width="36px" height="36px" iconOnly={false} type={null} color={null} />
  </Center>
);

export const TodayAtLinkRow = ({ liveTournaments }: TodayAtLinkRowProps) => {
  const navigate = useNavigate();

  const clickOnTournament = (tournamentId: number) => {
    navigate({ to: `/schedule/${tournamentId}` });
  };

  const textColor = !isAtpTourPortal() ? 'primary.300' : 'teal';

  return (
    <Menu isLazy>
      <MenuButton>
        <Text fontSize="sm" color={textColor}>
          Today at..
        </Text>
      </MenuButton>
      <MenuList bg="secondary.800" fontSize="sm" borderColor="transparent">
        {liveTournaments.map((item) => (
          <MenuItem
            _hover={{ bg: 'grey.600' }}
            bg="secondary.800"
            color={textColor}
            key={item.tournament_id}
            onClick={() => clickOnTournament(item.tournament_id)}
          >
            {item.name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export const useGetLiveTournaments = (data: LiveTournamentsData): Tournament[] =>
  useMemo(() => {
    if (isEmpty(data)) return [];
    return data?.flatMap((season) =>
      season.sections?.flatMap((section) =>
        section.tournaments?.filter((tournament) => tournament.comp_status === tournamentStatus.CURRENT)
      )
    );
  }, [data]);

const iconSize = isWimbPortal() ? '18px' : '20px';
const textColor = isWimbPortal() ? 'secondary.800' : 'white';
const fontSize = isWimbPortal() ? '15px' : 'sm';

export const InsightsExplainedButton = ({ iconOnly }: InsightsExplainedButtonProps) => {
  const { openDrawerWithAccordion } = useInsightsExplained();

  return (
    <Flex
      gap={2}
      cursor="pointer"
      w={isWimbPortal() ? (iconOnly ? '35px' : '225px') : isAtpTourPortal() ? '175px' : iconOnly ? '20px' : '200px'}
      alignItems="center"
      onClick={() => openDrawerWithAccordion()}
    >
      {isAtpTourPortal() ? (
        <Text fontSize={fontSize} color={textColor}>
          Insights Explained
        </Text>
      ) : (
        <>
          <Icon width={iconSize} height={iconSize} name="info" color={textColor} fallback={null} />
          {!iconOnly && (
            <Text fontSize={fontSize} color={textColor}>
              Insights Explained
            </Text>
          )}
        </>
      )}
    </Flex>
  );
};

export default TopMenu;
