import { useEffect } from 'react';

import { Formik, Form, Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import {
  Flex,
  SelectMulti,
  Button,
  SelectSingle,
  ButtonForm,
  ButtonGroup,
  Text,
  InputStandard
} from '_shared/designSystem/components';

import Icon from '../icon/_Icon';

import { formFieldTypes } from './formTypes';
export default function FormStandard({
  formFieldsData,
  formInitialValues,
  handleFormUpdated,
  handleFormSubmit,
  handleFormReset,
  enableReinitialize
}) {
  return (
    <Formik initialValues={formInitialValues} onSubmit={handleFormSubmit} enableReinitialize={enableReinitialize}>
      {({ values, setFieldValue, handleChange }) => (
        <Form>
          <FormObserver handleFormUpdated={handleFormUpdated} />
          <Flex flexWrap="wrap" columnGap={2} rowGap={2}>
            {formFieldsData.map((item) => {
              switch (item.type) {
                case formFieldTypes.SELECT_MULTI:
                  return (
                    <Field name={item.fieldId} key={item.fieldId}>
                      {({ field }) => (
                        <Flex align="flex-end" style={item.style}>
                          <SelectMulti
                            label={item.label}
                            fieldId={item.fieldId}
                            options={item.options}
                            setFieldValue={setFieldValue}
                            value={values[item.fieldId]}
                            onSelect={item.onSelect}
                            onRemove={item.onRemove}
                            isSingleSelect={item.isSingleSelect}
                          />
                        </Flex>
                      )}
                    </Field>
                  );

                case formFieldTypes.SELECT_SINGLE:
                  return (
                    <Field name={item.fieldId} key={item.fieldId}>
                      {({ field }) => (
                        <Flex align="flex-end" style={item.style}>
                          <SelectSingle
                            label={item.label}
                            fieldId={item.fieldId}
                            field={field}
                            options={item.options}
                            setFieldValue={setFieldValue}
                            value={values[item.fieldId]}
                          />
                        </Flex>
                      )}
                    </Field>
                  );

                case formFieldTypes.BUTTON_GROUP:
                  return (
                    <Flex flexDirection="column" key={item.fieldId} style={item.style}>
                      <Text color="grey.600" fontWeight="medium" fontSize="xs" ml={1}>
                        {item.label}
                      </Text>
                      <ButtonGroup
                        paramName={item.fieldId}
                        data={item.data}
                        handleButtonClick={(paramName, value) => {
                          let currentValues = values[paramName] || [];
                          if (!Array.isArray(currentValues)) {
                            currentValues = [];
                          }
                          currentValues.includes(value)
                            ? (currentValues = currentValues.filter((item) => item !== value))
                            : currentValues.push(value);
                          setFieldValue(paramName, currentValues);
                        }}
                        selectedItemMultiple={values[item.fieldId]}
                      />
                    </Flex>
                  );

                case formFieldTypes.TEXT:
                  return (
                    <Field name={item.fieldId} key={item.fieldId}>
                      {({ field }) => (
                        <Flex align="flex-end" style={item.style}>
                          <InputStandard
                            label={item.label}
                            fieldId={item.fieldId}
                            field={field}
                            value={values[item.fieldId]}
                            onChange={handleChange}
                          />
                        </Flex>
                      )}
                    </Field>
                  );

                case formFieldTypes.BOOLEAN:
                  return (
                    <Field name={item.fieldId} key={item.fieldId}>
                      {({ field }) => (
                        <Flex align="flex-end" style={item.style}>
                          <ButtonForm
                            label={item.label}
                            onClick={() => setFieldValue(item.fieldId, !values[item.fieldId])}
                            isSelected={values[item.fieldId]}
                          />
                        </Flex>
                      )}
                    </Field>
                  );

                case formFieldTypes.SUBMIT_BUTTON:
                  return (
                    <Flex align="flex-end" key={item.fieldId} style={item.style}>
                      <Button size="xs" spinnerPlacement="start" type="submit" variant="form">
                        {item.label}
                      </Button>
                    </Flex>
                  );

                case formFieldTypes.CUSTOM_COMPONENT:
                  return (
                    <Flex align="flex-end" key={item.fieldId} style={item.style}>
                      {item.component}
                    </Flex>
                  );

                case formFieldTypes.RESET_BUTTON:
                  return (
                    <Flex align="flex-start" pt={7} cursor="pointer" key={item.fieldId} style={item.style}>
                      <Icon
                        name="reset"
                        width="14px"
                        height="14px"
                        color="primary.500"
                        onClick={() => handleFormReset()}
                      />
                    </Flex>
                  );
                default:
                  return null;
              }
            })}
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

FormStandard.propTypes = {
  formFieldsData: PropTypes.array.isRequired,
  formInitialValues: PropTypes.object.isRequired,
  handleFormUpdated: PropTypes.func,
  handleFormSubmit: PropTypes.func,
  handleFormReset: PropTypes.func,
  enableReinitialize: PropTypes.bool
};

const FormObserver = ({ handleFormUpdated }) => {
  const { values } = useFormikContext();
  useEffect(() => {
    handleFormUpdated && handleFormUpdated(values); // only update if this function has been set
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  return null;
};

FormObserver.propTypes = {
  handleFormUpdated: PropTypes.func
};
