export interface ColorPalette {
  playerAverage: string;
  tourAverage: string;
  white: string;
  black: string;
}

export const getCrossComponentD3Colors = (colorPalette: any): ColorPalette => {
  return {
    playerAverage: colorPalette.playerAverage,
    tourAverage: colorPalette.tourAverage,
    white: colorPalette.white,
    black: colorPalette.black
  };
};
