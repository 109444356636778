import React, { useEffect, useState } from 'react';

import { Formik, Form, FormikHelpers } from 'formik';
import { DEFAULT_MATCH_ID } from 'graphics/constants';
import { useQuery, useMutation } from 'react-query';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  VStack,
  Spinner,
  Input,
  Radio,
  RadioGroup,
  HStack,
  Text
} from '_shared/designSystem/components';

import { graphicsService } from '../services/graphicsService';

import { FormStage, GraphicType, AspectRatio, GraphicData } from './types';

interface DynamicField {
  field_name: string;
  field_value: string;
  type: string;
}

interface FormValues {
  graphicType: GraphicType | '';
  aspectRatio: AspectRatio;
  match_id: string;
  [key: string]: any;
}

interface GraphicFormProps {
  onGraphicGenerated: (data: GraphicData, graphicType: GraphicType, aspectRatio: AspectRatio, match_id: string) => void;
  errorMessage: string | null;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
}

const GraphicForm: React.FC<GraphicFormProps> = ({ onGraphicGenerated, errorMessage, setErrorMessage }) => {
  // Preselect Match Winner on load
  const [selectedGraphicType, setSelectedGraphicType] = useState<GraphicType | ''>(GraphicType.MATCH_WINNER);
  const [stage, setStage] = useState<FormStage>(FormStage.INITIAL);
  const [dynamicFields, setDynamicFields] = useState<DynamicField[]>([]);

  // React Query for fetching form fields
  const { isLoading: isFetchingFields, refetch: fetchFormOptions } = useQuery(
    ['fetchFormOptions', selectedGraphicType],
    () =>
      graphicsService.getFormOptions({
        graphicType: selectedGraphicType || ''
      }),
    {
      enabled: false,
      onSuccess: (data: DynamicField[]) => {
        setDynamicFields(data);
        setStage(FormStage.FORM_OPTIONS_GENERATED);
        setErrorMessage(null);
      },
      onError: () => {
        setDynamicFields([]);
        setStage(FormStage.INITIAL);
      }
    }
  );

  // React Query mutation for generating graphic
  const mutation = useMutation(
    (queryParams: { [key: string]: any }) => graphicsService.generateGraphicData({ queryParams }),
    {
      onError: (error: any) => {
        const status = error.response?.status;
        if (status === 400 || status === 403) {
          setErrorMessage(error.response?.data?.error || 'Invalid input. Please correct and try again.');
        } else {
          setErrorMessage('An unexpected error occurred. Please try again.');
        }
      }
    }
  );

  useEffect(() => {
    if (selectedGraphicType) {
      setStage(FormStage.INITIAL);
      setDynamicFields([]);
      fetchFormOptions();
    } else {
      setStage(FormStage.INITIAL);
      setDynamicFields([]);
    }
  }, [selectedGraphicType, fetchFormOptions]);

  return (
    <Box p={4} maxWidth="600px" mx="auto">
      <Formik
        enableReinitialize
        initialValues={{
          graphicType: GraphicType.MATCH_WINNER,
          aspectRatio: AspectRatio['4x5'],
          match_id: DEFAULT_MATCH_ID,
          ...dynamicFields.reduce((acc, field) => {
            if (field.field_value !== 'match_id') {
              acc[field.field_value] = '';
            }
            return acc;
          }, {} as { [key: string]: any })
        }}
        onSubmit={async (values: FormValues, actions: FormikHelpers<FormValues>) => {
          if (stage === FormStage.FORM_OPTIONS_GENERATED) {
            try {
              setErrorMessage(null);
              const data: GraphicData = await mutation.mutateAsync(values);
              onGraphicGenerated(data, selectedGraphicType as GraphicType, values.aspectRatio, values.match_id);
              actions.setSubmitting(false);
            } catch {
              actions.setSubmitting(false);
            }
          }
        }}
      >
        {({ values, isSubmitting, handleChange, handleBlur, setFieldValue, isValid }) => (
          <Form>
            <VStack spacing={4} align="stretch">
              {/* Graphic Type Select */}
              <FormControl id="graphic-type">
                <FormLabel fontSize="sm" color="grey.500" htmlFor="graphic-type">
                  Graphic Type
                </FormLabel>
                <Select
                  id="graphic-type"
                  name="graphicType"
                  placeholder="Select graphic type"
                  value={values.graphicType}
                  onChange={(e) => {
                    handleChange(e);
                    setSelectedGraphicType(e.target.value as GraphicType);
                  }}
                  onBlur={handleBlur}
                  data-testid="graphic-type"
                >
                  <option value={GraphicType.MATCH_WINNER}>Match Winner</option>
                </Select>
              </FormControl>

              {/* Display Spinner while fetching form options */}
              {isFetchingFields && (
                <VStack spacing={2} align="center">
                  <Spinner size="md" />
                  <Text>Loading form options...</Text>
                </VStack>
              )}

              {/* Aspect Ratio Radio Buttons */}
              {stage === FormStage.FORM_OPTIONS_GENERATED && (
                <FormControl id="aspectRatio">
                  <FormLabel fontSize="sm" color="grey.500">
                    Aspect Ratio
                  </FormLabel>
                  <RadioGroup
                    name="aspectRatio"
                    onChange={(value) => {
                      setFieldValue('aspectRatio', value);
                    }}
                    value={values.aspectRatio}
                    data-testid="aspectRatio"
                  >
                    <HStack spacing={4}>
                      <Radio value={AspectRatio['4x5']}>{AspectRatio['4x5']}</Radio>
                      <Radio value={AspectRatio['9x16']}>{AspectRatio['9x16']}</Radio>
                    </HStack>
                  </RadioGroup>
                </FormControl>
              )}

              {/* Conditionally Render Dynamic Fields */}
              {stage === FormStage.FORM_OPTIONS_GENERATED &&
                dynamicFields.map((field) => (
                  <FormControl id={field.field_value} key={field.field_value} isRequired>
                    <FormLabel fontSize="sm" color="grey.500" htmlFor={field.field_value}>
                      {field.field_name}
                    </FormLabel>
                    <Input
                      id={field.field_value}
                      name={field.field_value}
                      type={field.type}
                      placeholder={`Enter ${field.field_name}`}
                      value={values[field.field_value]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      data-testid={field.field_value}
                    />
                  </FormControl>
                ))}

              {/* Error Alert */}
              {errorMessage && (
                <Box bg="red.100" p={3} borderRadius="md" color="red.800">
                  {errorMessage}
                </Box>
              )}

              {/* Submit Button */}
              {stage === FormStage.FORM_OPTIONS_GENERATED && (
                <Button
                  type="submit"
                  isDisabled={!isValid || isSubmitting || isFetchingFields || mutation.isLoading}
                  data-testid="submit-graphic-btn"
                >
                  {isSubmitting || mutation.isLoading ? <Spinner size="sm" /> : 'Generate Graphic'}
                </Button>
              )}
            </VStack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default GraphicForm;
