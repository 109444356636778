import React from 'react';

import { Box, Flex, Text } from '_shared/designSystem/components';

interface WinLossComparisonProps {
  wins: number;
  losses: number;
  period?: string;
}

const WIN_LOSS = {
  WIN: 'Win',
  LOSS: 'Loss'
};

const WinLossComparison = ({ wins, losses, period }: WinLossComparisonProps) => {
  const WinLossText = ({ text }) => (
    <Flex w="40px" px={2} justifyContent="center" alignItems="center">
      <Text fontSize="xs" color="grey.800" fontWeight="semibold">
        {text}
      </Text>
    </Flex>
  );

  return (
    <Box
      border="1px solid"
      borderColor="secondary.500"
      borderRadius="md"
      textAlign="center"
      display="flex"
      flexDirection="column"
      bg="white"
    >
      <Flex alignItems="center">
        <WinLossText text={WIN_LOSS.WIN} />
        <Box px={2} py="3px" bg="primary.500" color="white" fontWeight="semibold" fontSize="md" flexGrow={1}>
          {wins}-{losses}
        </Box>
        <WinLossText text={WIN_LOSS.LOSS} />
      </Flex>
      <Box bg="secondary.800" borderBottomRadius="sm">
        <Text fontSize="xs" color="white">
          {period}
        </Text>
      </Box>
    </Box>
  );
};

export default WinLossComparison;
