import React from 'react';

import { Flex, Text, Box } from '@chakra-ui/react';

export interface WinLossMobileRowProps {
  label: string;
  selectedMatches?: number | null;
  win: number;
  loss: number;
}

export const WinLossMobileRow: React.FC<WinLossMobileRowProps> = ({ label, selectedMatches, win, loss }) => {
  return (
    <Flex w="full" justify="center" align="center" py={1}>
      <Flex align="center" gap={3} justify="center" width="full">
        <Flex align="center" gap={2} minW="140px" justify="flex-end">
          <Text fontSize="xs" color="gray.600" fontWeight="bold">
            {label}
          </Text>
          {selectedMatches && (
            <Box
              bg="secondary.800"
              color="white"
              w="26px"
              h="26px"
              borderRadius="md"
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontSize="xs"
              fontWeight="bold"
            >
              {selectedMatches}
            </Box>
          )}
        </Flex>

        <Box
          bg="primary.500"
          px={3}
          py={1}
          borderRadius="md"
          fontSize="xs"
          fontWeight="bold"
          color="white"
          minW={14}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Text>
            {win}-{loss}
          </Text>
        </Box>

        <Text fontSize="xs" color="gray.700">
          Win-Loss
        </Text>
      </Flex>
    </Flex>
  );
};

export default WinLossMobileRow;
