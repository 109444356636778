import { Box } from '_shared/designSystem/components';

import { GraphicData } from '../../types';

type MatchWinner9x16Props = {
  data: GraphicData;
};

const MatchWinner9x16 = ({ data }: MatchWinner9x16Props) => {
  return <Box></Box>;
};

export default MatchWinner9x16;
