import { useEffect, useState } from 'react';

import { useLocation } from '@tanstack/react-location';

type Options = {
  exact?: boolean;
};

/**
 * Custom hook to check if the current root path contains any of the specified paths.
 * @param {string|string[]} paths - A string or an array of strings representing root paths to check.
 * @param {Object} [options] - Optional configuration object.
 * @param {boolean} [options.exact=false] - If true, checks for exact path matching.
 * @returns {boolean} - Returns true if the current path matches based on the criteria.
 */
export function useDoesCurrentRootPathContain(paths: string | string[], options: Options = { exact: false }): boolean {
  const [pathContains, setPathContains] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const pathname = location.current.pathname;

    if (options.exact) {
      // Exact Path Matching
      if (typeof paths === 'string') {
        setPathContains(pathname === paths);
      } else if (Array.isArray(paths)) {
        setPathContains(paths.includes(pathname));
      }
    } else {
      // Partial Path Matching (First Path Segment)
      const pathRoot = pathname?.split('/')?.[1];
      if (typeof paths === 'string') {
        setPathContains(pathRoot === paths);
      } else if (Array.isArray(paths)) {
        setPathContains(paths.includes(pathRoot));
      }
    }
  }, [location.current.pathname, paths, options.exact, location]);

  return pathContains;
}
