import { Flex, useBreakpointValue } from '_shared/designSystem/components';

import { Ranking, WinLoss, WinLossCurrentYear, WinLossLast52Weeks } from '../../../types/scoutTypes';

import RankingOrRaceMobile from './RankingOrRaceMobile';
import RankingOrRaceWithPoints from './RankingOrRaceWithPoints';
import WinLossComparison from './WinLossComparison';
import WinLossMobileRow from './WinLossMobileRow';
import WinLossTotal from './WinLossTotal';

interface WinLossOverviewProps {
  winLoss: WinLoss;
  winLossCurrentYear: WinLossCurrentYear;
  winLossLast52Weeks: WinLossLast52Weeks;
  ranking: Ranking;
}

export const WinLossOverview = ({
  winLoss: { scout_matches_selected, scout_matches_won, scout_matches_lost },
  winLossCurrentYear,
  winLossLast52Weeks,
  ranking: { rank, ranking_points, race, race_points }
}: WinLossOverviewProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });

  const renderMobileView = () => (
    <Flex direction={isMobile ? 'column' : 'row'} gap={{ md: '60px' }}>
      <Flex direction="column" align="center">
        <WinLossMobileRow
          label="Selected Matches"
          selectedMatches={scout_matches_selected}
          win={scout_matches_won}
          loss={scout_matches_lost}
        />
        <WinLossMobileRow label="Last 52 Weeks" win={winLossLast52Weeks.win} loss={winLossLast52Weeks.loss} />
        <WinLossMobileRow
          label={new Date().getFullYear().toString()}
          win={winLossCurrentYear.win}
          loss={winLossCurrentYear.loss}
        />
      </Flex>
      <Flex gap={3} justifyContent="center" mt={isMobile ? 2 : 0} direction={isTablet ? 'column' : 'row'}>
        {ranking_points && <RankingOrRaceMobile value={rank} label="Ranking" points={ranking_points} />}
        {race_points && <RankingOrRaceMobile value={race} label="Race" points={race_points} />}
      </Flex>
    </Flex>
  );

  const renderDesktopView = () => (
    <Flex alignItems="flex-end" gap={6} wrap="wrap" justifyContent="center">
      <WinLossTotal totalMatches={scout_matches_selected} wins={scout_matches_won} losses={scout_matches_lost} />
      <WinLossComparison wins={winLossLast52Weeks.win} losses={winLossLast52Weeks.loss} period="Last 52 weeks" />
      {ranking_points && <RankingOrRaceWithPoints rankingOrRace={rank} label="Ranking" points={ranking_points} />}
      <WinLossComparison
        wins={winLossCurrentYear.win}
        losses={winLossCurrentYear.loss}
        period={new Date().getFullYear().toString()}
      />
      {race_points && <RankingOrRaceWithPoints rankingOrRace={race} label="Race" points={race_points} />}
    </Flex>
  );

  return <Flex>{isMobile || isTablet ? renderMobileView() : renderDesktopView()}</Flex>;
};
