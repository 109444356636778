import PropTypes from 'prop-types';
import {
  getScoutHeaderForm,
  getScoutSeasonsOnlyForm,
  handleSeasonSelect,
  handleSeasonRemove
} from 'scout/util/scoutHeaderUtil';
import { getInitialMatchSelectorChoices, getScoutMatchSelectorForm } from 'scout/util/scoutMatchSelectorUtil';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Box, FormStandard } from '_shared/designSystem/components';
import { formFieldTypes } from '_shared/designSystem/components/form/formTypes';

export default function ScoutMatchSelectorForm(props) {
  const {
    playerDetailsData,
    formPopulationData,
    scoutFormValues,
    handleMatchSelectorFormSubmit,
    handleMatchSelectorFormUpdate
  } = props;

  let formFieldsData;

  if (customSitesConfig.scoutFiltersOnlySeason) {
    formFieldsData = getScoutSeasonsOnlyForm(playerDetailsData?.seasons_filter);
  } else {
    const basicFields = getScoutHeaderForm(playerDetailsData?.seasons_filter, false);
    const detailedFields = getScoutMatchSelectorForm(
      formPopulationData?.tournaments,
      formPopulationData?.opponents,
      formPopulationData?.rounds
    );
    formFieldsData = basicFields.concat(detailedFields);
  }

  const enhancedFormFieldsData = formFieldsData.map((field) => {
    if (field.type === formFieldTypes.SELECT_MULTI && field.fieldId === 'seasons') {
      return {
        ...field,
        onSelect: handleSeasonSelect,
        onRemove: handleSeasonRemove
      };
    }
    return field;
  });

  const handleFormUpdated = (values) => {
    handleMatchSelectorFormUpdate(values);
  };

  const handleFormSubmitInternal = (values) => {
    handleMatchSelectorFormSubmit(values);
  };

  return (
    <Box>
      <FormStandard
        formFieldsData={enhancedFormFieldsData}
        formInitialValues={getInitialMatchSelectorChoices(scoutFormValues)}
        handleFormUpdated={handleFormUpdated}
        handleFormSubmit={handleFormSubmitInternal}
        enableReinitialize={false}
      />
    </Box>
  );
}

ScoutMatchSelectorForm.propTypes = {
  playerDetailsData: PropTypes.object,
  formPopulationData: PropTypes.object,
  scoutFormValues: PropTypes.object,
  handleMatchSelectorFormSubmit: PropTypes.func,
  handleMatchSelectorFormUpdate: PropTypes.func
};
