import { BARS_OPACITY } from 'match/constants/winningAndLosingPlaysConstants';
import { determineColor, createTransformedType } from 'match/utils/winningAndLosingPlaysUtil';
import PropTypes from 'prop-types';

import { Box, Flex, Text, Icon, LoadingSpinner } from '_shared/designSystem/components';
import BarGraphic from '_shared/designSystem/components/charts/barGraphic/BarGraphic';
import { isArrayOfArrays } from '_shared/utils/dataTypeUtils';
import { capitaliseString } from '_shared/utils/stringUtil';

import SinglePlay from './SinglePlay';
import { dataNoAveragesObjectType, dataWithAveragesObjectType } from './SinglePlayTypes';

export const determineDetailedViewBarColor = (label) => {
  const colorMap = {
    winner: 'success.300',
    opponent_winner: 'success.300',
    error: 'maroon',
    opponent_error: 'maroon',
    in_attack: 'warning.200',
    in_defence: 'warning.200'
  };

  return colorMap[label] || 'primary.300';
};

export const mapPlayData = (plays) =>
  plays?.map((play) => ({
    label: capitaliseString(play?.id),
    frequency: isNaN(play?.frequency) ? 0 : play?.frequency,
    score: isNaN(play?.score) ? 0 : play?.score,
    score_type: play?.score_type,
    color: determineDetailedViewBarColor(play?.id)
  }));

const BarSection = ({ title, data, showAverages, score, frequency }) => {
  const isDataArrayOfArrays = isArrayOfArrays(data);

  return (
    <Box
      height="100%"
      maxW="400px"
      minW={{ base: '350px', sm: '400px' }}
      borderRadius={{ base: null, md: 'md' }}
      boxShadow={{ base: null, md: 'md' }}
      p={{ base: 4, sm: 8 }}
      pb={{ base: 10, md: 8 }}
      borderBottom={{ base: '1px solid', md: 'none' }}
      borderColor={{ base: 'grey.200', md: 'none' }}
    >
      <Flex direction="column" alignItems="center" height="100%">
        <Flex gap={4}>
          <Text fontSize="lg" fontWeight="semibold" mb={6}>
            {title}
          </Text>
          {!!score && (
            <Text fontSize="sm" textColor="grey.500" mt={1}>
              {score}% ({frequency})
            </Text>
          )}
        </Flex>
        {isDataArrayOfArrays ? (
          data.map((item, index) => (
            <Box key={index} width="100%" mt={4}>
              <BarGraphic
                data={item}
                showLabels
                barHeight={showAverages ? 'md' : 'xl'}
                aria-label="single-bar"
                opacity={BARS_OPACITY}
                hideFrequency
              />
            </Box>
          ))
        ) : (
          <BarGraphic data={data} showLabels={true} barHeight="xl" aria-label="single-bar" opacity={BARS_OPACITY} />
        )}
      </Flex>
    </Box>
  );
};

const processPlaysWithAverages = (playsNoAverages, playsWithAverages) =>
  playsNoAverages.map((type) => {
    const matchingType = playsWithAverages.find((outcome) => capitaliseString(outcome.id) === type.label);

    if (!matchingType) return [type];

    const { frequency, player_average, tour_average, score_type } = matchingType;
    const playerAvgColor = determineColor({ label: 'Player Avg' });
    const tourAvgColor = determineColor({ label: 'Tour Avg' });

    return [
      type,
      createTransformedType('Player Avg', 'Player Avg', frequency, player_average, score_type, playerAvgColor),
      createTransformedType('Tour Avg', 'Tour Avg', frequency, tour_average, score_type, tourAvgColor)
    ];
  });

const SinglePlayDetailedView = ({
  surface,
  dataNoAverages,
  dataWithAverages,
  isLoading,
  isLoadingAverages,
  showAverages,
  volumeType,
  isMatchPage,
  handleClose,
  player
}) => {
  const {
    tactics = [],
    outcomes: { winning = [], losing = [] } = {},
    volume: { winning: totalWinningNoAverages = {}, losing: totalLosingNoAverages = {} } = {}
  } = dataNoAverages;
  const { outcomes: { winning: winningWithAverages = [], losing: losingWithAverages = [] } = {} } = dataWithAverages;
  const totalWinningPlays = {
    score: !showAverages ? totalWinningNoAverages?.score : null,
    frequency: !showAverages ? totalWinningNoAverages?.frequency : null
  };
  const totalLosingPlays = {
    score: totalLosingNoAverages?.score,
    frequency: totalLosingNoAverages?.frequency
  };

  const playDirectionNoAverages = mapPlayData(tactics);
  const winningPlaysNoAverages = mapPlayData(winning);
  const losingPlaysNoAverages = mapPlayData(losing);

  const winningPlaysWithAverages = processPlaysWithAverages(winningPlaysNoAverages, winningWithAverages);
  const losingPlaysWithAverages = processPlaysWithAverages(losingPlaysNoAverages, losingWithAverages);

  if (isLoadingAverages || isLoading) return <LoadingSpinner />;

  return (
    <Box position="relative">
      <Flex direction={{ base: 'column', md: 'row' }} gap={8}>
        <Box w={{ base: '90%', md: '47%', lg: '35%', '2xl': '30%' }} maxW="450px" mx="auto">
          <SinglePlay
            surface={surface}
            dataNoAverages={dataNoAverages}
            dataWithAverages={dataWithAverages}
            showAverages={showAverages}
            isLoading={isLoading}
            isLoadingAverages={isLoadingAverages}
            volumeType={volumeType}
            isMatchPage={isMatchPage}
            isDetailed={true}
            player={player}
          />
        </Box>
        <Box w={{ base: '100%', md: '53%', lg: '55%', '2xl': '70%' }} pt={{ md: '50px', lg: 0 }} pr={{ '2xl': '60px' }}>
          <Flex
            justifyContent={{ base: 'center', md: 'space-between' }}
            flexWrap="wrap"
            rowGap={{ base: 2, md: 10 }}
            gap={{ base: 2, md: 10 }}
            mb={{ base: 2, md: 10 }}
          >
            <Box>
              <BarSection
                title="Winning Plays"
                score={totalWinningPlays.score}
                frequency={totalWinningPlays.frequency}
                data={showAverages ? winningPlaysWithAverages : winningPlaysNoAverages}
                showAverages={showAverages}
              />
            </Box>
            <Box>
              <BarSection
                title="Losing Plays"
                score={totalLosingPlays.score}
                frequency={totalLosingPlays.frequency}
                data={showAverages ? losingPlaysWithAverages : losingPlaysNoAverages}
                showAverages={showAverages}
              />
            </Box>
          </Flex>
          {tactics.length > 0 && (
            <Flex mb={12} justifyContent={{ base: 'center', md: 'flex-start' }}>
              <BarSection title="Play Direction" data={playDirectionNoAverages} showAverages={showAverages} />
            </Flex>
          )}
        </Box>
      </Flex>
      <Box onClick={handleClose} cursor="pointer" position="absolute" top="0px" right="0px">
        <Icon
          name="cross"
          width={{ base: '34px', sm: '48px' }}
          height={{ base: '34px', sm: '48px' }}
          color="grey.400"
        />
      </Box>
    </Box>
  );
};

SinglePlayDetailedView.propTypes = {
  surface: PropTypes.string,
  dataNoAverages: dataNoAveragesObjectType,
  dataWithAverages: dataWithAveragesObjectType,
  isLoading: PropTypes.bool,
  isLoadingAverages: PropTypes.bool,
  showAverages: PropTypes.bool,
  volumeType: PropTypes.oneOf(['volume', 'effectiveness']),
  isMatchPage: PropTypes.bool.isRequired,
  handleClose: PropTypes.func
};

SinglePlayDetailedView.defaultProps = {
  surface: '',
  dataNoAverages: {},
  dataWithAverages: {},
  isLoading: false,
  isLoadingAverages: false,
  showAverages: false,
  volumeType: 'volume',
  isMatchPage: true,
  handleClose: () => {},
  player: ''
};

export default SinglePlayDetailedView;
