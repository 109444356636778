import React from 'react';

import { useNavigate } from '@tanstack/react-location';

import { Box, Flex } from '_shared/designSystem/components';

import { NavBarButton } from './NavBarButton';

interface HorizontalNavBarProps {
  items: Array<{ id: string; label: string }>;
  activeItem?: string;
  overrideClickHandler?: (id: string) => void;
}

export default function HorizontalNavBar({ items, activeItem, overrideClickHandler }: HorizontalNavBarProps) {
  const navigate = useNavigate();

  const handleLongBarClick = (id: string) => {
    if (overrideClickHandler) overrideClickHandler(id);
    else navigate({ to: id });
  };

  return (
    <Box
      overflowX="auto"
      maxW="100vw"
      whiteSpace="nowrap" // Remove horizontal padding to prevent white space
      px={0}
      // Use negative margin to counteract potential scrollbar space
      mx="-4px"
      // Ensure full width is utilized
      width="calc(100% + 8px)"
    >
      <Flex
        gap="8px"
        h="38px"
        alignItems="center"
        borderRadius="lg"
        px="4px"
        minWidth="max-content" // Ensures the container only grows to fit content
      >
        {items?.map((item) => (
          <Box key={item.id} onClick={() => handleLongBarClick(item.id)}>
            <NavBarButton label={item.label} isSelected={activeItem === item.id} />
          </Box>
        ))}
      </Flex>
    </Box>
  );
}
