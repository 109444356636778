export const getCountryName = (countryCode: string): string | null => {
  // Return null if countryCode is invalid
  if (!countryCode || typeof countryCode !== 'string' || countryCode.length !== 2) {
    return null;
  }

  // Convert to uppercase
  const code = countryCode.toUpperCase();

  const specialCases = ['RU', 'BY', 'TW'];
  if (specialCases.includes(code)) {
    return null;
  }

  try {
    const displayNames = new Intl.DisplayNames(['en'], { type: 'region' });
    return displayNames.of(code) || code;
  } catch (error) {
    return null;
  }
};
