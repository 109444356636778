import { CourtType, DisplayData, TournamentsAndTrendLine } from './types';

export const processTournamentData = (tournamentsAndTrendLine: TournamentsAndTrendLine): DisplayData[] => {
  return tournamentsAndTrendLine?.tournaments?.map((item) => ({
    date: item.date,
    tournament_name: item.tournament_name,
    performance_rating: item.performance_rating,
    matches_played: item.matches_played,
    round_reached: item.round_reached,
    court_type: item.court_type
  }));
};

export const getDotColor = (
  courtType: CourtType,
  colors: {
    grass: string;
    clay: string;
    hard: string;
    indoor: string;
  }
): string => {
  const colorMap: { [key in CourtType]?: string } = {
    grass: colors.grass,
    clay: colors.clay,
    outdoor_hard: colors.hard,
    indoor_hard: colors.indoor
  };

  return colorMap[courtType];
};

export function getMinYRating(data: DisplayData[]): number {
  const lowest = Math.floor(Math.min(...data.map((item) => item.performance_rating)));
  return lowest > 6 ? 6 : lowest;
}
