import React from 'react';

import { Text, Flex, Box } from '_shared/designSystem/components';

import { PlaybackControlComponentProps } from './types';

const PlaybackControl = ({ playbackRate, handlePlaybackRateOptionsClick }: PlaybackControlComponentProps) => (
  <Flex w="60px" p={2} direction="column" bg="grey.700" gap={2} borderRadius="md">
    {[0.25, 0.5, 0.75, 1, 2].map((rate) => (
      <Box
        key={rate}
        cursor="pointer"
        onClick={(e) => handlePlaybackRateOptionsClick(e, rate)}
        onTouchStart={(e) => handlePlaybackRateOptionsClick(e, rate)}
        _hover={{ bg: 'grey.500' }}
        borderBottom={rate === playbackRate ? '1px solid white' : 'none'}
      >
        <Text textAlign="center" fontSize="sm" color="white">{`${rate}x`}</Text>
      </Box>
    ))}
    <Box
      position="absolute"
      bottom="-6px"
      left="50%"
      transform="translateX(-50%)"
      width="0"
      height="0"
      borderLeft="6px solid transparent"
      borderRight="6px solid transparent"
      borderTop="6px solid grey.400"
    />
  </Flex>
);

export default PlaybackControl;
