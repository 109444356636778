import React from 'react';

import { Box, Skeleton, HStack, VStack, Flex, Center, BoxProps } from '_shared/designSystem/components';

interface VerticalBarsLoadingSpinnerProps extends BoxProps {
  numberOfBars?: 1 | 2;
  showInfoIcon?: boolean;
  barHeight?: string;
  numberOfSegments?: number;
  showTopPercentages?: boolean;
}

const VerticalBarsLoadingSpinner: React.FC<VerticalBarsLoadingSpinnerProps> = ({
  numberOfBars = 1,
  showInfoIcon = true,
  barHeight = '400px',
  numberOfSegments = 4,
  showTopPercentages = true,
  ...boxProps
}) => {
  const segments = Array(numberOfSegments).fill(null);

  const SingleBar = ({ isDouble = false }) => (
    <VStack spacing={2} flex={1}>
      {showTopPercentages && (
        <VStack spacing={1} width="100%" mb={4}>
          <Skeleton height="20px" width="30%" maxW="60px" />
          <Skeleton height="20px" width="30%" maxW="60px" />
          <Skeleton height="20px" width="30%" maxW="60px" />
        </VStack>
      )}

      <Box width="100%" height={barHeight} position="relative">
        {segments.map((_, index) => (
          <Skeleton
            key={index}
            height={`${100 / numberOfSegments}%`}
            width={isDouble ? '100%' : '50%'}
            position="absolute"
            top={`${(index * 100) / numberOfSegments}%`}
            left={isDouble ? '0' : '25%'}
          />
        ))}
      </Box>
    </VStack>
  );

  return (
    <Box p={4} maxW="800px" w="100%" {...boxProps}>
      {/* Header */}
      <VStack spacing={6} mb={8}>
        {/* Title and Info Icon */}
        <Center w="100%">
          <HStack spacing={2}>
            <Skeleton height="24px" width="40%" maxW="160px" />
            {showInfoIcon && <Skeleton height="20px" width="5%" maxW="20px" rounded="full" />}
          </HStack>
        </Center>

        {/* Legend */}
        <HStack spacing={6} justify="center">
          <Skeleton height="16px" width="20%" maxW="80px" />
          <Skeleton height="16px" width="20%" maxW="80px" />
        </HStack>
      </VStack>

      {/* Title for sections if needed */}
      <Center mb={4}>
        <Skeleton height="20px" width="30%" maxW="120px" />
      </Center>

      {/* Bars Container */}
      <Flex gap={8} justify="center">
        {numberOfBars === 2 ? (
          <>
            <Box flex={1} maxW="300px">
              <Center mb={4}>
                <Skeleton height="20px" width="40%" maxW="80px" />
              </Center>
              <SingleBar isDouble={true} />
            </Box>
            <Box flex={1} maxW="300px">
              <Center mb={4}>
                <Skeleton height="20px" width="40%" maxW="80px" />
              </Center>
              <SingleBar isDouble={true} />
            </Box>
          </>
        ) : (
          <Box width="100%" maxW="300px">
            <SingleBar isDouble={false} />
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default VerticalBarsLoadingSpinner;
