import { Text as ChakraText } from '@chakra-ui/react';
import { convertFontSizeToWimbTextGuidelines } from '_customSites/wimb/theme/fonts/fontsUtil';

import { isWimbPortal } from '_shared/utils/environment/currentEnv';

export default function Text(props) {
  let customProps = {};
  if (isWimbPortal()) {
    customProps = convertFontSizeToWimbTextGuidelines(props);
  }

  return <ChakraText {...props} {...customProps} />;
}
