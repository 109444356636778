import React from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import TagManager from '@sooro-io/react-gtm-module';
import { Outlet, ReactLocation, Router } from '@tanstack/react-location';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';
import { getRoutes } from 'routes';

import theme from '_shared/designSystem/theme';
import AppErrorBoundary from '_shared/errors/AppErrorBoundary';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  }
});

// Initialise Google Tag Manager (GTM)
const GTM_CONTAINER_ID = import.meta.env.VITE_GTM_CONTAINER_ID;
if (GTM_CONTAINER_ID) {
  const tagManagerArgs = {
    gtmId: GTM_CONTAINER_ID
  };
  TagManager.initialize(tagManagerArgs);
}

const location = new ReactLocation();

// Wrap your existing App component
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppErrorBoundary>
        <ChakraProvider theme={theme}>
          <RecoilRoot>
            <Router location={location} routes={getRoutes()}>
              <Outlet />
            </Router>
          </RecoilRoot>
        </ChakraProvider>
      </AppErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
