import { CircleFlag } from 'react-circle-flags';

import TwFlag from '_shared/designSystem/components/icon/nationalityFlag/customFlagSvgs/TwFlag.svg?react';

export function getCountryFlagComponent(countryCode?: string) {
  if (!countryCode) {
    return null;
  }

  switch (countryCode) {
    case 'RU':
    case 'BY':
      return null;
    case 'TW':
      return <TwFlag height="80px" width="80px" data-testid="tw-flag" />;
    default:
      return <CircleFlag countryCode={countryCode.toLowerCase()} height="45px" width="45px" />;
  }
}
