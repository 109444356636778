import { getSearchParamUrl } from 'scout/util/scoutUtil';

import { apiClient } from '_shared/dataFetching/apiConfig';
import { getSiteParam } from '_shared/dataFetching/urlConfig';
import { convertBooleanToYesNo } from '_shared/utils/stringUtil';

export const performanceOverviewService = {
  async getPerformanceTracker({ playerId }) {
    const url = `scout/${playerId}/performance_overview/performance_tracker?${getSiteParam()}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getPerformanceRating({ playerId, scoutFetchingValues, averages }) {
    const url = `scout/${playerId}/insights/performance_rating?${getSiteParam()}&averages=${convertBooleanToYesNo(
      averages
    )}&${getSearchParamUrl(scoutFetchingValues)}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getRadar({ playerId, scoutFetchingValues, averages }) {
    const url = `scout/${playerId}/performance_overview/radar?${getSiteParam()}&averages=${convertBooleanToYesNo(
      averages
    )}&${getSearchParamUrl(scoutFetchingValues)}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getPointsProfile({ playerId, scoutFetchingValues, averages }) {
    const url = `scout/${playerId}/performance_overview/points_profile?${getSiteParam()}&averages=${convertBooleanToYesNo(
      averages
    )}&${getSearchParamUrl(scoutFetchingValues)}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getRallyLength({ playerId, scoutFetchingValues, averages }) {
    const url = `scout/${playerId}/performance_overview/rally_length?${getSiteParam()}&averages=${convertBooleanToYesNo(
      averages
    )}&${getSearchParamUrl(scoutFetchingValues)}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getServeEffectiveness({ playerId, scoutFetchingValues, averages }) {
    const url = `scout/${playerId}/performance_overview/serve_effectiveness?${getSiteParam()}&averages=${convertBooleanToYesNo(
      averages
    )}&${getSearchParamUrl(scoutFetchingValues)}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getShotVariation({ playerId, scoutFetchingValues, averages }) {
    const url = `scout/${playerId}/performance_overview/shot_variation?${getSiteParam()}&averages=${convertBooleanToYesNo(
      averages
    )}&${getSearchParamUrl(scoutFetchingValues)}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  },
  async getSituationProfile({ playerId, scoutFetchingValues, averages }) {
    const url = `scout/${playerId}/performance_overview/situation_profile?${getSiteParam()}&averages=${convertBooleanToYesNo(
      averages
    )}&${getSearchParamUrl(scoutFetchingValues)}`;
    const res = await apiClient.get(url);
    const data = res.data;
    return data;
  }
};
