import React, { ReactNode } from 'react';

import { Switch, Text, Flex, Box, HStack } from '_shared/designSystem/components';

interface SituationProfileHeaderProps {
  showAverages: boolean;
  onToggle?: () => void;
  isToggled?: boolean;
  tooltipText?: string | ReactNode;
}

const SituationProfileHeader: React.FC<SituationProfileHeaderProps> = ({ showAverages, onToggle, isToggled }) => {
  return (
    <Flex justify="space-between" align="flex-start" direction="row" width="100%">
      {/* Title and Tooltip */}
      <Flex minW="150px" align="center" gap={2}>
        <Text fontSize="md" fontWeight="semibold">
          Situation Profile
        </Text>
      </Flex>

      {/* Right Section: Averages and Toggles */}
      <Flex
        direction={{ base: 'column', md: 'row' }}
        align={{ base: 'flex-end', md: 'center' }}
        gap={{ base: 3, md: 8 }}
        width={{ base: '100%', md: 'auto' }}
      >
        {/* Averages */}
        <Flex gap={4} justify="flex-end" direction="row">
          {showAverages && (
            <HStack>
              <Box position="relative" mr={2}>
                <Box w={1} h={3} bg="playerAverage" />
                <Box position="absolute" top={0} w={3} h={1} bg="playerAverage" />
                <Box position="absolute" top={0} left={-2} w={3} h={1} bg="playerAverage" />
              </Box>
              <Text fontSize="xs">Player Avg</Text>
            </HStack>
          )}
          <HStack>
            <Box position="relative" mr={2}>
              <Box w={1} h={3} bg="tourAverage" />
              <Box position="absolute" top={0} w={3} h={1} bg="tourAverage" />
              <Box position="absolute" top={0} left={-2} w={3} h={1} bg="tourAverage" />
            </Box>
            <Text fontSize="xs">Tour Avg</Text>
          </HStack>
        </Flex>

        {/* Toggle */}
        <Flex align="center" justify="flex-end" gap={2}>
          <Switch isChecked={isToggled} colorScheme="green" onChange={onToggle} />
          <Text fontSize="xs">Win %</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SituationProfileHeader;
