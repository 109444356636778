export const mainMenuItems = {
  TOURNAMENTS: 'tournaments',
  LIVE_MATCHES: 'live_matches',
  LEADERBOARD: 'leaderboard',
  SCOUT_AREA: 'scoutArea',
  HEAD_TO_HEAD: 'head_to_head',
  INSIGHTS_EXPLAINED: 'insights_explained',
  GRAPHICS_CREATOR: 'graphics_creator'
};

export const routesMapping = [
  {
    id: mainMenuItems.TOURNAMENTS,
    routes: ['tournaments', 'tournament', 'schedule', 'match']
  },
  {
    id: mainMenuItems.LIVE_MATCHES,
    routes: ['match-list']
  },
  {
    id: mainMenuItems.LEADERBOARD,
    routes: ['leaderboard']
  },
  {
    id: mainMenuItems.SCOUT_AREA,
    routes: ['players', 'scout']
  },
  {
    id: mainMenuItems.HEAD_TO_HEAD,
    routes: ['head-to-head']
  },
  {
    id: mainMenuItems.GRAPHICS_CREATOR,
    routes: ['graphics', 'single-graphic']
  }
];
