import MatchWinner4x5 from './components/graphicTemplates/matchWinner/MatchWinner4x5';
import MatchWinner9x16 from './components/graphicTemplates/matchWinner/MatchWinner9x16';
import { AspectRatio, GraphicType } from './components/types';

export const GRAPHIC_COMPONENTS = {
  [GraphicType.MATCH_WINNER]: {
    [AspectRatio['4x5']]: MatchWinner4x5,
    [AspectRatio['9x16']]: MatchWinner9x16
  }
};
