import { useMemo, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { displayScore } from 'match/utils/transformations';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Box, Flex, Progress, Text, VStack } from '_shared/designSystem/components';
import { tvD3ComponentColors } from '_shared/designSystem/theme/chartColors';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';

import TShapeIndicator from '../rallyLength/TShapeIndicator';

import SituationProfileHeader from './SituationProfileHeader';
import { mergeDataWithAverages } from './situationProfileUtils';
import { SituationProfileChartProps } from './types';

const SituationProfileChart = ({ dataNoAverages, dataWithAverages, showAverages }: SituationProfileChartProps) => {
  const [showWinPercentage, setShowWinPercentage] = useState(false);

  const colorPalette = customSitesConfig?.d3ComponentColors || tvD3ComponentColors;
  const { shared, situationProfile } = colorPalette;

  const getBarColor = (index: number) => {
    return situationProfile.bars[index % situationProfile.bars.length];
  };

  const activeKey = showWinPercentage ? 'won' : 'all_points';

  const mergedData = useMemo(() => {
    if (isEmpty(dataNoAverages)) return [];
    if (showAverages) {
      return mergeDataWithAverages(activeKey, dataNoAverages, dataWithAverages);
    }
    return dataNoAverages[activeKey];
  }, [activeKey, showAverages, dataNoAverages, dataWithAverages]);

  // Single bar with label component
  const BarWithLabel = ({ data, index }) => {
    // This is to align the score text to the left when either the tour average or player average is less than 60% of the score
    const alignTextLeft =
      data.tour_average <= data.score * 0.6 || (showAverages && data.player_average <= data.score * 0.6);

    return (
      <Flex width="100%" mb={4} position="relative">
        {/* Left-aligned label */}
        <Box width="70px">
          <VStack spacing={0} alignItems="flex-start" justifyContent="center" height="40px">
            <Text fontSize="xs">{data.label}</Text>
          </VStack>
        </Box>

        {/* Bar and indicators container */}
        <Box flex="1" position="relative">
          <Progress
            value={data.score}
            h="40px"
            bg={situationProfile.grey}
            sx={{
              '& > div': {
                background: getBarColor(index),
                transition: 'width 0.2s ease-in-out'
              }
            }}
          />

          {/* Centered or left-aligned score text */}
          <Flex
            position="absolute"
            top="0"
            left="0"
            width={`${data.score}%`}
            height="40px"
            alignItems="center"
            justifyContent={alignTextLeft ? 'flex-start' : 'center'}
            pl={alignTextLeft ? 2 : 0}
          >
            <Text color={situationProfile.score} fontSize="xs" fontWeight="semibold">
              {displayScore(data.score, data.score_type)}
            </Text>
          </Flex>

          {/* T-shape indicators */}
          {data.tour_average !== null && (
            <TShapeIndicator
              score={data.score}
              average={data.tour_average}
              color={shared.tourAverage}
              textColor={isWimbPortal() ? situationProfile.black : shared.white}
              type="tourAverage"
              showAverages={showAverages}
            />
          )}

          {showAverages && data.player_average !== null && (
            <TShapeIndicator
              score={data.score}
              average={data.player_average}
              color={shared.playerAverage}
              textColor={isWimbPortal() ? situationProfile.black : shared.white}
              type="playerAverage"
              showAverages={showAverages}
            />
          )}
        </Box>
      </Flex>
    );
  };

  if (isEmpty(mergedData)) return null;

  return (
    <Box p={{ base: 2, sm: 4, md: 6 }} boxSizing="border-box">
      <SituationProfileHeader
        onToggle={() => setShowWinPercentage((prev) => !prev)}
        isToggled={showWinPercentage}
        showAverages={showAverages}
      />
      <Text fontSize="xs" textAlign="left" mt={2}>
        {showWinPercentage
          ? 'Points Won at Rally End Situation'
          : '% of Rally End Situation excluding Serve and Return'}
      </Text>
      <Box w="100%" mt={4}>
        {mergedData.map((data, index) => (
          <BarWithLabel key={data.id} data={data} index={index} />
        ))}
      </Box>
    </Box>
  );
};

export default SituationProfileChart;
