import React from 'react';

import { Box, Skeleton, HStack, VStack, Flex, BoxProps } from '_shared/designSystem/components';

interface HorizontalBarsLoadingSpinnerProps extends BoxProps {
  numberOfBars?: number;
  showSubheader?: boolean;
  showWinToggle?: boolean;
  showServeReturnToggle?: boolean;
  barHeight?: string;
  labelWidth?: string;
}

const HorizontalBarsLoadingSpinner: React.FC<HorizontalBarsLoadingSpinnerProps> = ({
  numberOfBars = 3,
  showSubheader = false,
  showWinToggle = true,
  showServeReturnToggle = true,
  barHeight = '40px',
  labelWidth = '20%' // Changed to percentage
}) => {
  const bars = Array(numberOfBars).fill(null);

  return (
    <Box p={4} w="100%">
      {/* Header Section */}
      <VStack align="stretch" spacing={2} mb={6}>
        {/* Title and Toggles */}
        <Flex justify="space-between" align="center" flexWrap="wrap" gap={4}>
          {/* Title */}
          <Skeleton height="24px" width="25%" maxW="180px" />

          {/* Legend and Toggles */}
          <HStack spacing={4} align="center" flexWrap="wrap">
            {/* Player/Tour Avg Legend */}
            <HStack spacing={3} flexWrap="wrap">
              <Skeleton height="16px" width="15%" minW="80px" />
              <Skeleton height="16px" width="15%" minW="80px" />
            </HStack>

            {/* Toggles */}
            <HStack spacing={3} flexWrap="wrap">
              {showServeReturnToggle && <Skeleton height="24px" width="20%" minW="120px" rounded="full" />}
              {showWinToggle && <Skeleton height="24px" width="15%" minW="80px" rounded="full" />}
            </HStack>
          </HStack>
        </Flex>

        {/* Subheader if needed */}
        {showSubheader && <Skeleton height="16px" width="100%" />}
      </VStack>

      {/* Bars */}
      <VStack spacing={4} align="stretch">
        {bars.map((_, index) => (
          <Flex key={index} align="center" gap={4} width="100%">
            {/* Label */}
            <Skeleton height="16px" width={labelWidth} />

            {/* Bar Container */}
            <Box flex={1} position="relative">
              <Skeleton height={barHeight} width="100%" />
            </Box>
          </Flex>
        ))}
      </VStack>
    </Box>
  );
};

export default HorizontalBarsLoadingSpinner;
