import { checkNationality } from 'match/utils/transformations';
import PropTypes from 'prop-types';
import Flag from 'react-world-flags';

import { Box } from '_shared/designSystem/components';

import TwFlag from './customFlagSvgs/TwFlag.svg?react';
export default function NationalityFlag(props) {
  const { nationality, width } = props;

  const height = Math.round(width / 1.5);

  const checkedNationality = checkNationality(nationality);

  if (nationality === 'TW') {
    return (
      <Box border="1px solid" borderColor="grey.300" h={`${height + 4}px`}>
        <TwFlag width={`${width}px`} height={`${height}px`} />
      </Box>
    );
  }

  return (
    <Box data-testid="nationalityFlag">
      {checkedNationality ? (
        <Box border="1px solid" borderColor="grey.400">
          <Flag code={checkedNationality} width={`${width}px`} />
        </Box>
      ) : (
        <Box w={`${width}px`} h={`${height}px`} />
      )}
    </Box>
  );
}

NationalityFlag.propTypes = {
  nationality: PropTypes.string,
  width: PropTypes.number
};
