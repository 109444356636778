import { Key } from 'react';

import { displayScore } from 'match/utils/transformations';

import customSitesConfig from '_shared/config/customSitesConfig';
import { Box, Text, Flex, Progress } from '_shared/designSystem/components';
import { tvD3ComponentColors } from '_shared/designSystem/theme/chartColors';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';
import { splitText } from '_shared/utils/stringUtil';

import TShapeIndicator from './TShapeIndicator';

const RallyLengthServeAndReturnView = ({ data, type, showAverages }) => {
  const colorPalette = customSitesConfig?.d3ComponentColors || tvD3ComponentColors;
  const { shared, rallyLength } = colorPalette;

  const getBarColor = (index: number) => {
    return rallyLength.bars[index % rallyLength.bars.length];
  };

  return (
    <Box width="100%" height="100%" position="relative">
      {data.map((d: { serve: any; return: any; id: Key; label: string }, index: number) => {
        const subData = type === 'serve' ? d.serve : d.return;
        if (!subData) return null;

        // This is to align the score text to the left when either the tour average or player average is less than 60% of the score
        const alignTextLeft =
          subData.tour_average <= subData.score * 0.6 ||
          (showAverages && subData.player_average <= subData.score * 0.6);

        return (
          <Flex key={d.id} alignItems="center" justifyContent="space-between" position="relative" mt="4">
            {/* Label */}
            <Box w="70px" textAlign="left">
              {splitText(d.label, 10).lines.map((line, i) => (
                <Text key={i} fontSize="xs" color={shared.black}>
                  {line}
                </Text>
              ))}
            </Box>

            {/* Bar and indicators container */}
            <Box flex="1" position="relative">
              <Progress
                value={subData.score}
                h="40px"
                bg={rallyLength.grey}
                sx={{
                  '& > div': {
                    background: getBarColor(index),
                    transition: 'width 0.2s ease-in-out'
                  }
                }}
              />

              {/* Centered or left-aligned score text */}
              <Flex
                position="absolute"
                top="0"
                left="0"
                width={`${subData.score}%`}
                height="40px"
                alignItems="center"
                justifyContent={alignTextLeft ? 'flex-start' : 'center'}
                pl={alignTextLeft ? 2 : 0}
              >
                <Text color={rallyLength.score} fontSize="12px" fontWeight="800">
                  {displayScore(subData.score, subData.score_type)}
                </Text>
              </Flex>

              {/* TShapes */}
              <Box>
                {subData.tour_average !== null && (
                  <TShapeIndicator
                    score={subData.score}
                    average={subData.tour_average}
                    color={shared.tourAverage}
                    textColor={isWimbPortal() ? rallyLength.black : shared.white}
                    type="tourAverage"
                    showAverages={showAverages}
                  />
                )}

                {subData.player_average !== null && showAverages && (
                  <TShapeIndicator
                    score={subData.score}
                    average={subData.player_average}
                    color={shared.playerAverage}
                    textColor={rallyLength.black}
                    type="playerAverage"
                    showAverages={showAverages}
                  />
                )}
              </Box>
            </Box>
          </Flex>
        );
      })}
    </Box>
  );
};

export default RallyLengthServeAndReturnView;
