import React from 'react';

import { createRoot } from 'react-dom/client';

import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

const loadCustomCSS = async () => {
  try {
    await import('./_shared/designSystem/theme/siteFonts.css');
    await import('./_shared/designSystem/theme/graphicsFonts.css');
  } catch (error) {
    console.error('Error loading custom CSS:', error);
  }
};

const initializeApp = async () => {
  try {
    await loadCustomCSS();

    if (import.meta.env.DEV && import.meta.env.VITE_MSW === 'on') {
      const { worker } = await import('./_mockData/browser');
      await worker.start({ onUnhandledRequest: 'bypass' });
    }

    const domNode = document.getElementById('root');
    if (!domNode) {
      throw new Error('Root element not found');
    }

    const root = createRoot(domNode);

    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  } catch (error) {
    console.error('Error initializing app:', error);
  }
};

initializeApp();

reportWebVitals();
