import isEmpty from 'lodash/isEmpty';
import MatchSelectorModalButton from 'scout/components/matchSelector/MatchSelectorModalButton';

import customSitesConfig from '_shared/config/customSitesConfig';
import { formFieldTypes } from '_shared/designSystem/components/form/formTypes';
import { isWimbPortal, isTvPortal } from '_shared/utils/environment/currentEnv';

import { createFormField } from './scoutMatchSelectorUtil';

// Utility function to transform seasonsActive into options with 'Last 52 Weeks' as the first option
function getSeasonsOptions(seasonsActive) {
  const seasonsOptions = [
    ...(isWimbPortal() ? [] : [{ label: 'Last 52 Weeks', id: 'last_52_weeks' }]),
    ...seasonsActive.map((item) => ({
      label: item.season_name || item.toString(),
      id: item.season_id || item.toString()
    }))
  ];
  return seasonsOptions;
}

export function getScoutSeasonsOnlyForm(seasonsActive) {
  if (isEmpty(seasonsActive)) return [];

  const seasonsOptions = seasonsActive.map((item) => ({
    label: item.toString(),
    id: item.toString()
  }));

  return [
    createFormField(
      formFieldTypes.SELECT_MULTI,
      isWimbPortal() ? 'Championships' : 'Seasons',
      'seasons',
      seasonsOptions,
      null
    ),
    createFormField(formFieldTypes.SUBMIT_BUTTON, 'Apply', 'submitButton')
  ];
}

export function getScoutHeaderForm(seasonsActive, includeSubmit, openMatchSelector, noOfSelectedMatches) {
  if (isEmpty(seasonsActive)) return [];

  const seasonsOptions = getSeasonsOptions(seasonsActive);

  if (customSitesConfig.scoutFiltersOnlySeason) {
    return [
      createFormField(
        formFieldTypes.SELECT_MULTI,
        isWimbPortal() ? 'Championships' : 'Seasons',
        'seasons',
        seasonsOptions,
        null
      ),
      createFormField(
        formFieldTypes.CUSTOM_COMPONENT,
        'Match Selector',
        'matchSelectorButton',
        null,
        null,
        // Pass the component directly
        <MatchSelectorModalButton openMatchSelector={openMatchSelector} noOfSelectedMatches={noOfSelectedMatches} />
      ),
      createFormField(formFieldTypes.SUBMIT_BUTTON, 'Apply', 'submitButton')
    ];
  }

  const result = [createFormField(formFieldTypes.SELECT_MULTI, 'Seasons', 'seasons', seasonsOptions, null)];

  if (includeSubmit) {
    result.push(
      createFormField(
        formFieldTypes.CUSTOM_COMPONENT,
        'Match Selector',
        'matchSelectorButton',
        null,
        null,
        // Pass the component directly
        <MatchSelectorModalButton openMatchSelector={openMatchSelector} noOfSelectedMatches={noOfSelectedMatches} />
      )
    );

    result.push(createFormField(formFieldTypes.SUBMIT_BUTTON, 'Apply', 'submitButton'));
  }

  return result;
}

export function getInitialMatchHeaderForm() {
  if (isWimbPortal()) {
    return {
      seasons: [], // No default "Last 52 Weeks" for Wimb Portal
      surfaces: [],
      lastNoMatches: ''
    };
  }
  if (customSitesConfig.showScoutSurfaceAndLast52WeeksFilter) {
    return {
      seasons: ['last_52_weeks'], // Default "Last 52 Weeks" for other portals
      surfaces: [],
      lastNoMatches: ''
    };
  } else {
    return {
      seasons: ['last_52_weeks'],
      lastNoMatches: ''
    };
  }
}

export function getQueryParams(location) {
  const href = location.current.href;
  return href.replace('/scout/', '');
}

export function getNumberSelectedMatches(scoutFormValues) {
  if (isEmpty(scoutFormValues)) return 0;
  if (isEmpty(scoutFormValues.matchIds)) return 0;
  return scoutFormValues.matchIds.length;
}

export function getPlayerHeadshotImage(data, isMatchReport) {
  if (isEmpty(data)) return null;
  if (isWimbPortal()) return data?.image_url_wimb;
  if (isTvPortal() && isMatchReport) return data?.image_url_tv;
  return data?.image_url_atptour;
}

export const handleSeasonSelect = (selectedList, selectedItem, setFieldValue) => {
  const selectedOptions = selectedList.map((item) => item.id);

  if (selectedItem.id === 'last_52_weeks') {
    // If 'Last 52 Weeks' is selected, deselect all other options
    setFieldValue('seasons', ['last_52_weeks']);
  } else {
    // If any other option is selected, remove 'last_52_weeks' if it's selected
    if (selectedOptions.includes('last_52_weeks')) {
      const updatedOptions = selectedOptions.filter((option) => option !== 'last_52_weeks');
      setFieldValue('seasons', updatedOptions);
    } else {
      setFieldValue('seasons', selectedOptions);
    }
  }
};

export const handleSeasonRemove = (selectedList, removedItem, setFieldValue) => {
  const selectedOptions = selectedList.map((item) => item.id);

  if (removedItem.id === 'last_52_weeks') {
    if (selectedOptions.length === 0) {
      // If 'Last 52 Weeks' is deselected and no other selection, reselect it
      setFieldValue('seasons', ['last_52_weeks']);
    }
    // If other options remain, keep them as is
  } else {
    if (selectedOptions.length === 0) {
      // If no seasons are selected, default to 'last_52_weeks'
      setFieldValue('seasons', ['last_52_weeks']);
    } else {
      setFieldValue('seasons', selectedOptions);
    }
  }
};
