export const normalizeToPercentage = (value: number | null, min: number, max: number): number | null => {
  if (value === null || value === undefined) return null;

  // Clamp the value to the min/max range
  const clampedValue = Math.min(Math.max(value, min), max);

  // Ensure we don't divide by zero
  if (max === min) return 50; // Center point if range is zero
  return ((clampedValue - min) / (max - min)) * 100;
};
