import colors from '_shared/designSystem/theme/colors';
import { getCrossComponentD3Colors } from '_shared/designSystem/theme/helpers';

export const tvPieChartColors = [
  colors.error['300'],
  colors.warning['300'],
  colors.success['300'],
  colors.blue['300'],
  colors.purple['300']
];

export const tvPieChartLegend = [
  {
    label: 'Serve',
    color: 'error.300'
  },
  {
    label: 'Return',
    color: 'warning.300'
  },
  {
    label: 'Forehand',
    color: 'success.300'
  },
  {
    label: 'Backhand',
    color: 'blue.300'
  },
  {
    label: 'Volley',
    color: 'purple.300'
  }
];

export const tvPerformanceTrackerColors = {
  player1Line: colors.primary[700],
  player2Line: colors.grey[800],
  player1Fill: colors.primary[200],
  player2Fill: colors.grey[300],
  tourAverageLine: colors.tourAverage
};

export const tvD3ComponentColors = {
  shared: {
    ...getCrossComponentD3Colors(colors)
  },
  radar: {
    score: colors.primary[400],
    lightGrey: colors.grey[300],
    darkGrey: colors.grey[400],
    tourAvgOpacity: 0.6,
    playerAvgOpacity: 0.6,
    scoreOpacity: 0.7
  },
  performanceTracker: {
    tooltipBackground: colors.secondary[800],
    gridLines: colors.grey[200],
    yAxisLabels: colors.grey[500],
    tournamentName: colors.grey[700],
    lightGrey: colors.grey[200],
    graphLine: colors.primary[400],
    trendLine: colors.primary[400],
    grass: colors.court.surface.grass.hex,
    clay: colors.court.surface.clay.hex,
    hard: colors.blue[700],
    indoor: colors.blue[400]
  },
  rallyLength: {
    score: colors.grey[800],
    bars: [colors.primary[300], colors.primary[300], colors.primary[300]],
    grey: colors.grey[100],
    black: colors.grey[800]
  },
  situationProfile: {
    score: colors.grey[800],
    bars: [colors.primary[600], colors.primary[600], colors.primary[600]],
    grey: colors.grey[100]
  }
};

export const tvStackedBarColors = {
  serveEffectiveness: [
    { bg: 'primary.600', text: 'secondary.800' },
    { bg: 'primary.400', text: 'secondary.800' },
    { bg: 'primary.200', text: 'secondary.800' },
    { bg: 'grey.200', text: 'secondary.800' },
    { bg: 'rose.50', text: 'secondary.800' },
    { bg: 'pink.100', text: 'secondary.800' },
    { bg: 'error.200', text: 'secondary.800' }
  ],
  shotVariation: [
    { bg: 'grey.200', text: 'secondary.800' },
    { bg: 'primary.100', text: 'secondary.800' },
    { bg: 'primary.200', text: 'secondary.800' },
    { bg: 'primary.300', text: 'secondary.800' },
    { bg: 'primary.400', text: 'secondary.800' },
    { bg: 'primary.500', text: 'secondary.800' },
    { bg: 'primary.700', text: 'secondary.800' }
  ]
};
