import React, { useMemo } from 'react';

import { Box, VStack, HStack, Text, Flex } from '_shared/designSystem/components';
import { isWimbPortal } from '_shared/utils/environment/currentEnv';

import { RenderDataPoint } from './PointsProfileDataPoint';
import { PointsProfileProps } from './types';
import { normalizeToPercentage } from './utils';

const PointsProfile: React.FC<PointsProfileProps> = ({ showAverages, dataNoAverages, dataWithAverages }) => {
  const mergedData = useMemo(() => {
    return dataNoAverages?.map((item, id) => ({
      ...item,
      player_average: dataWithAverages?.[id]?.player_average ?? null
    }));
  }, [dataNoAverages, dataWithAverages]);

  return (
    <Box bg={'white'} width="100%">
      <Flex justify="space-between" direction="row" align="center" py={4} px={4}>
        <HStack>
          <Text fontSize="md" fontWeight="semibold">
            Points Profile
          </Text>
        </HStack>
        <Flex gap={4} direction="row" align="center">
          {showAverages && (
            <HStack>
              <Box w={3} h={3} borderRadius="full" bg="playerAverage" />
              <Text fontSize="xs">Player Avg</Text>
            </HStack>
          )}
          <HStack>
            <Box w={3} h={3} borderRadius="full" bg="tourAverage" />
            <Text fontSize="xs">Tour Avg</Text>
          </HStack>
        </Flex>
      </Flex>

      <VStack align="stretch" spacing={0}>
        {mergedData?.map(({ display_max, display_min, tour_average, score, player_average, type, label }, index) => {
          const min = display_min;
          const max = display_max;

          const normalizedTourAvg = normalizeToPercentage(tour_average, min, max);
          const normalizedScore = normalizeToPercentage(score, min, max);
          const normalizedPlayerAvg = normalizeToPercentage(player_average, min, max);

          // Determine which points to consider based on showAverages
          const visiblePoints = [
            { value: normalizedScore, type: 'score' },
            { value: normalizedTourAvg, type: 'tourAverage' },
            ...(showAverages && normalizedPlayerAvg !== null
              ? [{ value: normalizedPlayerAvg, type: 'playerAverage' }]
              : [])
          ].filter((point) => point.value !== null);

          // Find the leftmost and rightmost points among visible points
          const leftPoint = Math.min(...visiblePoints.map((p) => p.value));
          const rightPoint = Math.max(...visiblePoints.map((p) => p.value));

          return (
            <HStack
              key={index}
              justifyContent="space-between"
              bg={type === 'serve' ? 'white' : isWimbPortal() ? 'grey.100' : 'primary.25'}
              py={3}
              px={3}
            >
              <Text fontSize="xs" width="30%" position="relative" borderRight="1px dotted">
                {label}
              </Text>
              <Box width="70%" position="relative" h={5}>
                <Box
                  position="absolute"
                  left={`${leftPoint}%`}
                  right={`${100 - rightPoint}%`}
                  top="50%"
                  height="1px"
                  bg="secondary.400"
                  transform="translateY(-50%)"
                />
                <RenderDataPoint value={score} color="primary.500" label="Selected Matches" min={min} max={max} />
                <RenderDataPoint value={tour_average} color="tourAverage" label="Tour Average" min={min} max={max} />
                {showAverages && (
                  <RenderDataPoint
                    value={player_average}
                    color="playerAverage"
                    label="Player Average"
                    min={min}
                    max={max}
                    tourAverageValue={tour_average}
                  />
                )}
                {score !== null && normalizedScore !== null && (
                  <Text
                    position="absolute"
                    left={`${normalizedScore + 2}%`}
                    top="-3px"
                    transform={`translateY(-50%) ${'translateX(-50%)'}`}
                    fontSize="xs"
                    fontWeight="bold"
                    textColor="primary.500"
                  >
                    {score}%
                  </Text>
                )}
              </Box>
            </HStack>
          );
        })}
      </VStack>
    </Box>
  );
};

export default PointsProfile;
