export const playButtonShrink = 0.08;
export const skipButtonShrink = 0.06;
export const gapWidthFraction = 0.08;

export const maxPlayButtonWidth = 80;
export const maxSkipButtonWidth = 70;

export const largeControlsPlayerWidth = 500;

export const smallControlsIconSize = '18px';
export const largeControlsIconSize = '22px';

export const smallControlsHeightOffset = 56;
export const largeControlsHeightOffset = 75;

export const smallControlsHeight = '100px';
export const largeControlsHeight = '155px';

export const smallControlsPaddingTop = '48px';
export const largeControlsPaddingTop = '94px';
