import { useEffect, useRef, useState } from 'react';

import { Box, Skeleton } from '@ellipsedata/tennis';
import ReactPlayer from 'react-player';
import { useMeasure, useToggle } from 'react-use';

import { logBasicMessage } from '_shared/errors/log';
import { isTouchDevice } from '_shared/utils/touchDevice.util';

import Controls from './Controls';
import { HandleKeyDownEvent, ReactPlayerProgressState, VideoPlayerProps } from './types';
import { getSeekTime } from './util';

const VideoPlayer = ({
  url,
  handleVideoEnd,
  hasPlaylist,
  handlePlayPrev,
  handlePlayNext,
  disablePrev,
  disableNext,
  isPlaying,
  setIsPlaying
}: VideoPlayerProps) => {
  const playerAndControlsRef = useRef(null);
  const playerRefPlayer = useRef(null);
  const [playerContainerRef, { width: playerWidth, height: playerHeight }] = useMeasure();
  const [played, setPlayed] = useState(0);
  const [showControls, setShowControls] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showFullScreenWithBrowserBar, setShowFullScreenWithBrowserBar] = useState(false);
  const hideControlsTimeout = useRef(null);
  const showControlsTimeout = useRef(null);
  const [playerIsLoading, setPlayerIsLoading] = useState(true);
  const [isFirstVideo, setIsFirstVideo] = useState(true);
  const [isMuted, toggleMute] = useToggle(false);
  const [showPlaybackRate, setShowPlaybackRate] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);

  const handlePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const handleOnReady = () => {
    setPlayerIsLoading(false);
    if (isTouchDevice() && isFirstVideo) {
      setIsPlaying(false);
      setIsFirstVideo(false);
    }
  };

  // time progress bar
  const handleProgress = (state: ReactPlayerProgressState) => {
    setPlayed(state.played);
    setCurrentTime(state.playedSeconds);
  };

  const handleDuration = (duration: number) => {
    setDuration(duration);
  };

  // we need this separate to the one in Controls to get a more accurate width on the progress bar
  const handleSeek = (clickRatio: number) => {
    const seekTime = getSeekTime(duration, clickRatio);
    playerRefPlayer.current.seekTo(seekTime);
  };

  // this is clicking on anywhere in the video above the progress bar
  const handleClickVideo = () => {
    if (isTouchDevice()) {
      if (!showControls) setShowControls(true);
      else setShowControls(false);
      return false;
    } else {
      handlePlay();
    }
    setShowPlaybackRate(false);
  };

  // on desktop we want to show the controls when we hover over the video player, but we don't
  // want to do it straight away
  const handleMouseEventsWithDelay = (shouldShow: boolean) => {
    if (isFullScreen || isTouchDevice() || showPlaybackRate) return;
    const timeoutRef = shouldShow ? showControlsTimeout : hideControlsTimeout;
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setShowControls(shouldShow);
    }, 150);
  };
  const handleMouseEnter = () => handleMouseEventsWithDelay(true);
  const handleMouseLeave = () => handleMouseEventsWithDelay(false);

  // in full screen desktop we want to show the controls when we move the mouse
  const handleMouseMove = () => {
    if (!isFullScreen || isTouchDevice()) return;
    setShowControls(true);
  };

  // so we don't get the controls showing at the start of the next video
  const hideControlsAndPlayPrev = () => {
    setShowControls(false);
    handlePlayPrev();
  };

  const hideControlsAndPlayNext = () => {
    setShowControls(false);
    handlePlayNext();
  };

  // handle full screen mode. Occasionally this might error for iPad due to the requestFullscreen function
  // not completing. Best is to do nothing and log it so we can monitor, and let user try again. Should work
  // the second time
  const playerElement = playerAndControlsRef.current;

  const enterFullScreen = async () => {
    try {
      if (playerElement.webkitEnterFullscreen) await playerElement.webkitEnterFullscreen();
      else if (playerElement.requestFullscreen) await playerElement.requestFullscreen();
      else setShowFullScreenWithBrowserBar(true); // iPhone
      setIsFullScreen(true);
    } catch (error) {
      logBasicMessage('WARN', `Unable to make video full screen. Error: ${error}.`);
    }
  };

  const exitFullScreen = async () => {
    try {
      if (document.exitFullscreen) await document.exitFullscreen();
      else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
      else setShowFullScreenWithBrowserBar(false); // iPhone
      setIsFullScreen(false);
    } catch (error) {
      logBasicMessage('WARN', `Unable to exit full screen mode. Error: ${error}.`);
    }
  };

  const handleFullScreen = () => {
    if (!playerElement) return;
    if (isFullScreen) exitFullScreen();
    else enterFullScreen();
  };

  const handleVolume = () => {
    playerRefPlayer.current.getInternalPlayer().muted = !playerRefPlayer.current.getInternalPlayer().muted;
    toggleMute();
  };

  const _setPlaybackRate = (rate: number) => {
    setPlaybackRate(rate);
    setShowPlaybackRate(false);
  };

  // so we can detect if you pressed escape to exit fullscreen
  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement && !document.webkitFullscreenElement) setIsFullScreen(false);
    };
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    return () => clearTimeout(hideControlsTimeout.current);
  }, []);

  // hide the controls if we are playing for 3 seconds
  useEffect(() => {
    if (isPlaying && !showPlaybackRate) {
      hideControlsTimeout.current = setTimeout(() => {
        setShowControls(false);
      }, 3000);
    }
    return () => clearTimeout(hideControlsTimeout.current);
  }, [isFullScreen, isPlaying, showControls, showPlaybackRate]);

  // keybooard for moving left/right
  useEffect(() => {
    const handleKeyDown = (event: HandleKeyDownEvent) => {
      if (!isPlaying) {
        if (event.key === 'ArrowLeft') goToNewTime(Math.max(currentTime - 0.1, 0));
        else if (event.key === 'ArrowRight') goToNewTime(Math.min(currentTime + 0.1, duration));
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isPlaying, currentTime, duration]);

  const goToNewTime = (newTime: number) => {
    playerRefPlayer.current.seekTo(newTime);
    setCurrentTime(newTime);
  };

  // styles for the black background when full screen on mobile, as it's not the complete full screen
  const fullScreenWithBrowserBarStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 3,
    display: 'flex',
    align: 'center',
    background: 'black'
  };

  return (
    <Box>
      <Box
        position="relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      >
        <Box
          ref={playerAndControlsRef}
          display={isFullScreen ? 'flex' : 'initial'}
          alignItems={isFullScreen ? 'center' : 'initial'}
          style={showFullScreenWithBrowserBar ? { ...fullScreenWithBrowserBarStyle } : null}
        >
          {playerIsLoading && !isFullScreen && (
            <Skeleton position="absolute" top={0} left={0} width={`${playerWidth}px`} height={`${playerHeight}`} />
          )}
          <Box ref={playerContainerRef}>
            <ReactPlayer
              onProgress={handleProgress}
              onDuration={handleDuration}
              progressInterval={100}
              ref={playerRefPlayer}
              url={url}
              width="100%"
              height="100%"
              onEnded={handleVideoEnd}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              playing={isPlaying}
              data-testid="react-player"
              controls={false}
              onReady={handleOnReady}
              onBuffer={() => setPlayerIsLoading(true)}
              playsinline
              style={{ visibility: playerIsLoading ? 'hidden' : 'visible' }}
              playbackRate={playbackRate}
            />
          </Box>
          {!showControls && <Box position="absolute" top={0} left={0} h="83%" w="100%" onClick={handleClickVideo} />}
          {showControls && (
            <Controls
              handleSeek={handleSeek}
              currentTime={currentTime}
              lengthOfVideo={duration}
              played={played}
              handlePlay={handlePlay}
              handleFullScreen={handleFullScreen}
              isFullScreen={isFullScreen}
              isPlaying={isPlaying}
              handlePlayPrev={hideControlsAndPlayPrev}
              handlePlayNext={hideControlsAndPlayNext}
              disablePrev={disablePrev}
              disableNext={disableNext}
              handleClickVideo={handleClickVideo}
              hasPlaylist={hasPlaylist}
              isTouchDevice={isTouchDevice()}
              setIsPlaying={setIsPlaying}
              handleVolume={handleVolume}
              isMuted={isMuted}
              playbackRate={playbackRate}
              setPlaybackRate={_setPlaybackRate}
              showPlaybackRate={showPlaybackRate}
              setShowPlaybackRate={setShowPlaybackRate}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default VideoPlayer;
