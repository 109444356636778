/// <reference types="vite-plugin-svgr/client" />

import { Box } from '@chakra-ui/layout';
import { default as capitalise } from 'lodash/capitalize';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import ArrowDown from './icons/Standard/ArrowDown.svg?react';
import ArrowLeft from './icons/Standard/ArrowLeft.svg?react';
import ArrowRight from './icons/Standard/ArrowRight.svg?react';
import ArrowUp from './icons/Standard/ArrowUp.svg?react';
import BarChart from './icons/Standard/BarChart.svg?react';
import CheckSquare from './icons/Standard/CheckSquare.svg?react';
import ChevronDown from './icons/Standard/ChevronDown.svg?react';
import ChevronLeft from './icons/Standard/ChevronLeft.svg?react';
import ChevronRight from './icons/Standard/ChevronRight.svg?react';
import ChevronsRight from './icons/Standard/ChevronsRight.svg?react';
import ChevronUp from './icons/Standard/ChevronUp.svg?react';
import Columns from './icons/Standard/Columns.svg?react';
import Cross from './icons/Standard/Cross.svg?react';
import CrossCircle from './icons/Standard/CrossCircle.svg?react';
import CsvFile from './icons/Standard/CsvFile.svg?react';
import Download from './icons/Standard/Download.svg?react';
import Expand from './icons/Standard/Expand.svg?react';
import ExternalLink from './icons/Standard/ExternalLink.svg?react';
import FileText from './icons/Standard/FileText.svg?react';
import Filter from './icons/Standard/Filter.svg?react';
import Flag from './icons/Standard/Flag.svg?react';
import FullScreen from './icons/Standard/FullScreen.svg?react';
import FullScreenExit from './icons/Standard/FullScreenExit.svg?react';
import Home from './icons/Standard/Home.svg?react';
import Info from './icons/Standard/Info.svg?react';
import LifeBuoy from './icons/Standard/LifeBuoy.svg?react';
import LogOut from './icons/Standard/LogOut.svg?react';
import Menu from './icons/Standard/Menu.svg?react';
import New from './icons/Standard/New.svg?react';
import Pause from './icons/Standard/Pause.svg?react';
import Play from './icons/Standard/Play.svg?react';
import PlayNext from './icons/Standard/PlayNext.svg?react';
import PlayPrevious from './icons/Standard/PlayPrevious.svg?react';
import ProfileImagePlaceholder from './icons/Standard/ProfileImagePlaceholder.svg?react';
import Reset from './icons/Standard/Reset.svg?react';
import Search from './icons/Standard/Search.svg?react';
import Settings from './icons/Standard/Settings.svg?react';
import TennisBall from './icons/Standard/TennisBall.svg?react';
import ThreeLayers from './icons/Standard/ThreeLayers.svg?react';
import Tick from './icons/Standard/Tick.svg?react';
import Trophy from './icons/Standard/Trophy.svg?react';
import User from './icons/Standard/User.svg?react';
import Users from './icons/Standard/Users.svg?react';
import Volume from './icons/Standard/Volume.svg?react';
import VolumeX from './icons/Standard/VolumeX.svg?react';

const processIcons = (iconMap) => {
  return Object.keys(iconMap).reduce((processed, name) => {
    processed[name] = (props) => <Box as={iconMap[name]} {...props} />;
    processed[name].displayName = capitalise(name);
    return processed;
  }, {});
};

const standard = processIcons({
  home: Home,
  checkSquare: CheckSquare,
  flag: Flag,
  threeLayers: ThreeLayers,
  barChart: BarChart,
  columns: Columns,
  user: User,
  users: Users,
  search: Search,
  externalLink: ExternalLink,
  lifeBuoy: LifeBuoy,
  settings: Settings,
  logOut: LogOut,
  tick: Tick,
  info: Info,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowUp: ArrowUp,
  arrowDown: ArrowDown,
  tennisBall: TennisBall,
  chevronDown: ChevronDown,
  chevronUp: ChevronUp,
  chevronRight: ChevronRight,
  chevronLeft: ChevronLeft,
  chevronsRight: ChevronsRight,
  crossCircle: CrossCircle,
  cross: Cross,
  profileImagePlaceholder: ProfileImagePlaceholder,
  fileText: FileText,
  download: Download,
  reset: Reset,
  menu: Menu,
  new: New,
  trophy: Trophy,
  filter: Filter,
  playNext: PlayNext,
  playPrevious: PlayPrevious,
  play: Play,
  csvFile: CsvFile,
  expand: Expand,
  fullScreen: FullScreen,
  fullScreenExit: FullScreenExit,
  pause: Pause,
  volume: Volume,
  volumeX: VolumeX
});

const icons = { standard };

export default function Icon({ name, collection = 'standard', ...props }) {
  const IconComponent = get(icons, [collection, name]);
  if (!IconComponent) return null;
  return <IconComponent {...props} />;
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  collection: PropTypes.oneOf(['standard'])
};
