import { standardColors } from '_shared/designSystem/theme/colors';

const atptourColors = {
  electricBlue: '#121FFF',
  darkNavy: '#051224',
  teal: '#4DD0E1',
  coral: '#FF7E67'
};

const siteSpecific = {
  primary: {
    25: '#e7e9ff',
    50: '#d0d2ff',
    100: '#d0d2ff',
    200: '#a0a5ff',
    300: '#5962ff',
    400: '#5962ff',
    500: atptourColors.electricBlue,
    600: atptourColors.electricBlue,
    700: atptourColors.electricBlue,
    800: atptourColors.electricBlue,
    900: atptourColors.electricBlue
  },
  secondary: {
    25: '#c3cded',
    50: '#b1bbda',
    100: '#424D71',
    200: '#343B5B',
    300: '#292F4A',
    400: '#21263A',
    500: '#1A1E2D',
    600: '#151724',
    700: '#10111C',
    800: atptourColors.darkNavy, // darkNavy
    900: '#030C1A'
  },
  grey: {
    25: '#FCFCFD',
    50: '#F9FAFB',
    100: '#F2F4F7',
    200: '#E4E7EC',
    300: '#D0D5DD',
    400: '#6A717B',
    500: '#39414F',
    600: '#39414F',
    700: atptourColors.darkNavy,
    800: atptourColors.darkNavy,
    900: atptourColors.darkNavy
  }
};

const featureSpecificColors = {
  black: '#000000',
  white: '#FFFFFF',
  court: {
    background: {
      hard: '#AACCA3',
      clay: '#F8A88F',
      grass: '#CFB175',
      indoor: '#81CAE9'
    },
    surface: {
      hard: {
        hex: '#637CB2',
        opacityStart: 1,
        opacityEnd: 0.9
      },
      clay: {
        hex: '#CC4400',
        opacityStart: 0.8,
        opacityEnd: 0.7
      },
      grass: {
        hex: '#8EB25E',
        opacityStart: 1,
        opacityEnd: 0.9
      },
      indoor: {
        hex: '#637CB2',
        opacityStart: 1,
        opacityEnd: 0.9
      }
    }
  },
  brightYellow: '#E4F53B'
};

const colors = {
  ...standardColors,
  ...atptourColors,
  ...siteSpecific,
  ...featureSpecificColors,
  illustration: {
    greenLight: '#0CB590',
    greenDark: '#248074',
    greenGrey: '#C7D1CD',
    orangeLight: '#E9634E',
    orangeDark: '#FF3842',
    orangeGrey: '#D2CDC7',
    blueLight: '#56AAC9',
    blueDark: '#0680AA',
    blueGrey: '#C6D3D5'
  }
};

colors.playerAverage = atptourColors.teal;
colors.tourAverage = atptourColors.coral;
colors.matchAverage = atptourColors.electricBlue;

export default colors;
