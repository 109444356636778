import isEmpty from 'lodash/isEmpty';

import { PointData, ServeReturnData, RallyLengthChartData, RallyLengthSubKey, RallyLengthTopKey } from './types';

export const mergeDataWithAverages = (
  topKey: RallyLengthTopKey,
  subKey: RallyLengthSubKey,
  dataNoAverages: RallyLengthChartData,
  dataWithAverages: RallyLengthChartData
): Array<PointData | ServeReturnData> => {
  if (isEmpty(dataNoAverages)) return null;
  if (isEmpty(dataWithAverages)) return dataNoAverages[topKey][subKey];
  const noAvgData = dataNoAverages[topKey][subKey];
  const withAvgData = dataWithAverages[topKey][subKey];

  // If the data structure is PointData
  if (topKey === 'all_points') {
    return (noAvgData as PointData[]).map((item) => {
      const avgItem = (withAvgData as PointData[]).find((avg) => avg.id === item.id);

      return {
        ...item,
        score: item.score !== null ? item.score : avgItem ? avgItem.score : null,
        tour_average: item.tour_average !== null ? item.tour_average : avgItem ? avgItem.tour_average : null,
        player_average: item.player_average !== null ? item.player_average : avgItem ? avgItem.player_average : null
      };
    });
  }

  // If the data structure is ServeReturnData
  if (topKey === 'serve_and_return') {
    return (noAvgData as ServeReturnData[]).map((item) => {
      const avgItem = (withAvgData as ServeReturnData[]).find((avg) => avg.id === item.id);

      return {
        ...item,
        serve: {
          score: item.serve.score !== null ? item.serve.score : avgItem ? avgItem.serve.score : null,
          tour_average:
            item.serve.tour_average !== null ? item.serve.tour_average : avgItem ? avgItem.serve.tour_average : null,
          player_average:
            item.serve.player_average !== null
              ? item.serve.player_average
              : avgItem
              ? avgItem.serve.player_average
              : null,
          score_type: item.serve.score_type,
          display_min: item.serve.display_min,
          display_max: item.serve.display_max
        },
        return: {
          score: item.return.score !== null ? item.return.score : avgItem ? avgItem.return.score : null,
          tour_average:
            item.return.tour_average !== null ? item.return.tour_average : avgItem ? avgItem.return.tour_average : null,
          player_average:
            item.return.player_average !== null
              ? item.return.player_average
              : avgItem
              ? avgItem.return.player_average
              : null,
          score_type: item.return.score_type,
          display_min: item.return.display_min,
          display_max: item.return.display_max
        }
      };
    });
  }

  return [];
};
