import { useCallback } from 'react';

import { useLocation, useNavigate } from '@tanstack/react-location';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import {
  getInitialMatchHeaderForm,
  getNumberSelectedMatches,
  getPlayerHeadshotImage,
  getQueryParams,
  getScoutHeaderForm,
  handleSeasonSelect,
  handleSeasonRemove
} from 'scout/util/scoutHeaderUtil';
import ScoutReportModal from 'scoutReport/components/ScoutReportModal';

import customSitesConfig from '_shared/config/customSitesConfig';
import { userPermissionTypes } from '_shared/constants/user';
import {
  Text,
  Flex,
  NationalityFlag,
  FormStandard,
  Box,
  Image,
  Center,
  Icon,
  useDisclosure,
  HorizontalNavBar,
  useBreakpointValue
} from '_shared/designSystem/components';
import { formFieldTypes } from '_shared/designSystem/components/form/formTypes';
import { userPermissionsState } from '_shared/globalState/atoms';
import useFeature from '_shared/utils/hooks/useFeature';
import { navigateBack } from '_shared/utils/navigationUtils';
import { checkPermission } from '_shared/utils/permissions';

import RecentForm from './RecentForm';

export default function ScoutHeader({
  playerDetailsData,
  openMatchSelector,
  handleHeaderFormSubmit,
  handleHeaderFormUpdate,
  scoutFormValues,
  items,
  currentTab,
  handleNavBarClick
}) {
  const {
    player_id: playerId,
    player_first_name,
    player_last_name,
    nationality,
    recent_form
  } = playerDetailsData || {};

  const playerName = `${player_first_name || ''} ${player_last_name || ''}`;

  const headShotUrl = getPlayerHeadshotImage(playerDetailsData);

  const scoutReportEnabled = useFeature('scoutReport');
  const { permissions, company } = useRecoilValue(userPermissionsState);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const navigate = useNavigate();
  const location = useLocation();

  const noOfSelectedMatches = getNumberSelectedMatches(scoutFormValues);

  const isMobile = useBreakpointValue({ base: true, md: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });

  // Handle form updates to enforce mutual exclusivity
  const handleHeaderFormUpdateInternal = useCallback(
    (updatedValues) => {
      const { seasons, ...rest } = updatedValues;

      // Default to 'Last 52 Weeks' if no seasons are selected
      const normalizedSeasons = seasons?.length ? seasons : ['last_52_weeks'];

      // Call the parent handler with the updated values
      handleHeaderFormUpdate({
        ...rest,
        seasons: normalizedSeasons
      });
    },
    [handleHeaderFormUpdate]
  );

  const formFieldsData = getScoutHeaderForm(
    playerDetailsData?.seasons_filter,
    true,
    openMatchSelector,
    noOfSelectedMatches
  ).map((field) => {
    if (field.type === formFieldTypes.SELECT_MULTI && field.fieldId === 'seasons') {
      return {
        ...field,
        onSelect: handleSeasonSelect,
        onRemove: handleSeasonRemove
      };
    }
    return field;
  });

  return (
    <Flex pt={{ base: 2, md: 0 }} justify="space-between">
      <Flex direction="column" gap={2}>
        <Flex justify="space-between" align="center">
          <Flex w={{ base: '100%', md: 'initial' }} justify="space-between" align="center" pr={{ base: 3, md: 0 }}>
            <Box
              cursor="pointer"
              pt={{ base: 0 }}
              pr={2}
              onClick={() =>
                navigateBack({
                  navigate
                })
              }
            >
              <Icon name="arrowLeft" color="primary.500" height={5} width={5} />
            </Box>
            <Flex mr={10} display={{ base: 'none', md: 'flex' }}>
              <Flex align="center" mr={4}>
                <NationalityFlag nationality={nationality} width={30} />
              </Flex>
              <Text fontSize="3xl" fontWeight="medium" color="secondary.800">
                {playerName}
              </Text>
            </Flex>
            <Box mr={10}>
              <RecentForm recentForm={recent_form ?? []} />
            </Box>
            {customSitesConfig.showScoutReport &&
            scoutReportEnabled &&
            checkPermission(userPermissionTypes.SCOUT_REPORT, permissions) ? (
              <Flex>
                <Box cursor="pointer" onClick={onOpen}>
                  <Icon name="fileText" color="primary.500" height={5} width={5} />
                </Box>
                <ScoutReportModal
                  playerId={playerId}
                  heading={playerName}
                  isOpen={isOpen}
                  onClose={onClose}
                  queryParams={getQueryParams(location)}
                  playerName={`${playerDetailsData?.player_first_name}_${playerDetailsData?.player_last_name}`}
                  company={company}
                />
              </Flex>
            ) : (
              <Box></Box>
            )}
          </Flex>
        </Flex>
        <Flex gap={4} align="flex-start">
          <FormStandard
            formFieldsData={formFieldsData}
            formInitialValues={scoutFormValues || getInitialMatchHeaderForm()}
            handleFormSubmit={handleHeaderFormSubmit}
            handleFormUpdated={handleHeaderFormUpdateInternal}
            enableReinitialize={true}
          />
        </Flex>
        <Flex
          overflowX="auto"
          overflowY="hidden"
          width="100%"
          maxWidth="100vw"
          css={{
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            scrollbarWidth: 'none'
          }}
        >
          <HorizontalNavBar items={items} activeItem={currentTab} overrideClickHandler={handleNavBarClick} />
        </Flex>
      </Flex>
      {customSitesConfig.showScoutPlayerHeadshot && !isMobile && !isTablet && (
        <Flex px={5} display={{ base: 'none', md: 'none', lg: 'flex' }} justify="center" align="flex-start">
          <Image
            borderRadius="full"
            border="1px solid"
            borderColor="grey.300"
            h="92px"
            w="92px"
            minW="92px"
            minH="92px"
            src={headShotUrl}
            alt={playerName}
            fallback={
              <Center
                mt={4}
                overflow="hidden"
                h="92px"
                w="92px"
                border="1px solid"
                borderColor="grey.300"
                borderRadius="full"
              >
                <Icon mt={2} color="grey.300" w="90px" h="90px" name="profileImagePlaceholder" />
              </Center>
            }
          />
        </Flex>
      )}
    </Flex>
  );
}

ScoutHeader.propTypes = {
  playerDetailsData: PropTypes.object.isRequired,
  winLoss: PropTypes.object,
  openMatchSelector: PropTypes.func,
  handleHeaderFormSubmit: PropTypes.func.isRequired,
  handleHeaderFormUpdate: PropTypes.func.isRequired,
  scoutFormValues: PropTypes.object,
  winLossLoading: PropTypes.bool,
  items: PropTypes.array.isRequired,
  currentTab: PropTypes.string,
  handleNavBarClick: PropTypes.func
};
