import { ScaleLinear } from 'd3-scale';
import { isEmpty } from 'lodash';

import { RadarData } from './types';

// Merge data and averages based on 'id'
export const mergeDataWithAverages = (
  dataNoAverages: RadarData[] = [],
  dataWithAverages: RadarData[] = []
): RadarData[] => {
  if (isEmpty(dataWithAverages)) return dataNoAverages;
  return dataNoAverages.map((item) => {
    const averageItem = dataWithAverages.find((avg) => avg.id === item.id);

    return {
      ...item,
      // Use score from dataNoAverages; if null, fallback to averageItem.score
      score: item.score !== null ? item.score : averageItem ? averageItem.score : null,
      // Use tour_average from dataWithAverages if present; otherwise, retain original
      tour_average: averageItem && averageItem.tour_average !== null ? averageItem.tour_average : item.tour_average,
      // Use player_average from dataWithAverages if present; otherwise, retain original
      player_average:
        averageItem && averageItem.player_average !== null ? averageItem.player_average : item.player_average
    };
  });
};

// Function to clamp value within [min, max]
const clamp = (value: number, min: number, max: number): number => {
  return Math.max(min, Math.min(value, max));
};

// Function to normalize value based on score_type and return a normalized value between 0 and 1
export const getNormalizedValue = (
  value: number,
  displayMin: number,
  displayMax: number,
  scoreType: string
): number => {
  if (value == null) return 0;

  let clampedValue: number;
  let normalizedValue: number;

  if (scoreType === 'out_of_ten') {
    // Convert to percentage
    const valuePercent = (value / 10) * 100;
    const minPercent = (displayMin / 10) * 100;
    const maxPercent = (displayMax / 10) * 100;

    clampedValue = clamp(valuePercent, minPercent, maxPercent);
    normalizedValue = (clampedValue - minPercent) / (maxPercent - minPercent);
  } else if (scoreType === 'percentage') {
    clampedValue = clamp(value, displayMin, displayMax);
    normalizedValue = (clampedValue - displayMin) / (displayMax - displayMin);
  } else {
    // Default case
    clampedValue = clamp(value, displayMin, displayMax);
    normalizedValue = (clampedValue - displayMin) / (displayMax - displayMin);
  }

  return normalizedValue;
};

// Function to calculate the coordinates of a data point
export const getCoordinates = (
  data: RadarData,
  mergedData: RadarData[],
  scales: ScaleLinear<number, number>[],
  type: string,
  angleSlice: number,
  minScale: number
): { x: number; y: number } => {
  const index = mergedData.findIndex((item) => item.id === data.id);
  const angle = angleSlice * index - Math.PI / 2;

  let normalizedValue: number;

  if (type === 'score') {
    normalizedValue = getNormalizedValue(data.score, data.display_min, data.display_max, data.score_type);
  } else if (type === 'tour_average') {
    normalizedValue = getNormalizedValue(data.tour_average, data.display_min, data.display_max, data.score_type);
  } else if (type === 'player_average') {
    normalizedValue = getNormalizedValue(data.player_average, data.display_min, data.display_max, data.score_type);
  } else {
    normalizedValue = 0;
  }

  // Calculate scaled value with minScale
  const scaledValue = minScale + normalizedValue * (scales[index].range()[1] - minScale);

  return {
    x: scaledValue * Math.cos(angle),
    y: scaledValue * Math.sin(angle)
  };
};

// Function to calculate label positions
export const calculatePosition = (
  i: number,
  totalItems: number,
  radius: number,
  xOffset: number = 45,
  yOffset: number = 60,
  leftSideOffset: number = -25,
  rightSideOffset: number = 25
): { labelX: number; labelY: number; textAlign: string; leftOffset: number } => {
  const angle = ((Math.PI * 2) / totalItems) * i - Math.PI / 2;
  const labelX = (radius + xOffset) * Math.cos(angle);
  const labelY = (radius + yOffset) * Math.sin(angle);
  let textAlign = 'center';
  let leftOffset = 0;

  if (angle > -Math.PI / 2 && angle < Math.PI / 2) {
    // Right side
    leftOffset = rightSideOffset;
  } else if (Math.abs(angle) === Math.PI / 2) {
    // Top/Bottom
    textAlign = 'center';
  } else {
    // Left side
    leftOffset = leftSideOffset;
  }

  return { labelX, labelY, textAlign, leftOffset };
};

export const createRadarPoints = (containerWidth: number) => {
  const points = [];
  const sides = 8;
  const baseRadius = containerWidth * 0.3;
  const variations = [0.81, 0.79, 0.89, 0.82, 0.55, 0.56, 0.71, 0.34];

  for (let i = 0; i < sides; i++) {
    const angle = (i * 2 * Math.PI) / sides - Math.PI / 2;
    const radius = baseRadius * variations[i];
    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);
    points.push(`${x},${y}`);
  }
  return points.join(' ');
};
