import atptourColors from '_customSites/atptour/theme/atptourColors';

import { getCrossComponentD3Colors } from '_shared/designSystem/theme/helpers';

export const atptourPieChartColors = [
  atptourColors.illustration.greenLight,
  atptourColors.illustration.orangeLight,
  atptourColors.illustration.blueLight,
  atptourColors.illustration.greenGrey,
  atptourColors.teal
];

export const atptourPieChartLegend = [
  {
    label: 'Serve',
    color: 'illustration.greenLight'
  },
  {
    label: 'Return',
    color: 'illustration.orangeLight'
  },
  {
    label: 'Forehand',
    color: 'illustration.blueLight'
  },
  {
    label: 'Backhand',
    color: 'illustration.greenGrey'
  },
  {
    label: 'Volley',
    color: 'teal'
  }
];

export const atptourPerformanceTrackerColors = {
  player1Line: atptourColors.primary[500],
  player2Line: atptourColors.grey[800],
  player1Fill: atptourColors.primary[200],
  player2Fill: atptourColors.grey[200],
  tourAverageLine: atptourColors.tourAverage
};

export const atptourD3ComponentColors = {
  shared: {
    ...getCrossComponentD3Colors(atptourColors)
  },
  radar: {
    score: atptourColors.electricBlue,
    lightGrey: atptourColors.white,
    darkGrey: atptourColors.grey[600],
    tourAvgOpacity: 0.6,
    playerAvgOpacity: 0.6,
    scoreOpacity: 0.6
  },
  performanceTracker: {
    tooltipBackground: atptourColors.darkNavy,
    gridLines: atptourColors.grey[100],
    yAxisLabels: atptourColors.grey[400],
    tournamentName: atptourColors.grey[700],
    lightGrey: atptourColors.grey[400],
    graphLine: atptourColors.primary[400],
    trendLine: atptourColors.primary[400],
    grass: atptourColors.court.surface.grass.hex,
    clay: atptourColors.court.surface.clay.hex,
    hard: atptourColors.blue[700],
    indoor: atptourColors.blue[400]
  },
  rallyLength: {
    score: atptourColors.white,
    black: atptourColors.grey[800],
    grey: atptourColors.grey[100],
    bars: [atptourColors.purple[400], atptourColors.purple[400], atptourColors.purple[400]]
  },
  situationProfile: {
    score: atptourColors.white,
    bars: [atptourColors.primary[400], atptourColors.primary[400], atptourColors.primary[400]],
    grey: atptourColors.grey[100]
  }
};

export const atptourStackedBarColors = {
  serveEffectiveness: [
    { bg: 'navy', text: 'white' },
    { bg: 'primary.500', text: 'white' },
    { bg: 'primary.300', text: 'white' },
    { bg: 'grey.200', text: 'secondary.800' },
    { bg: 'rose.50', text: 'secondary.800' },
    { bg: 'pink.100', text: 'secondary.800' },
    { bg: 'error.200', text: 'secondary.800' }
  ],
  shotVariation: [
    { bg: 'grey.200', text: 'secondary.800' },
    { bg: 'grey.100', text: 'secondary.800' },
    { bg: 'primary.25', text: 'secondary.800' },
    { bg: 'primary.100', text: 'secondary.800' },
    { bg: 'primary.200', text: 'grey.500' },
    { bg: 'primary.300', text: 'grey.300' },
    { bg: 'primary.500', text: 'grey.300' },
    { bg: 'primary.900', text: 'grey.300' }
  ]
};
